import { Box, Container, IconButton, Tooltip, Typography, useMediaQuery } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { Theme, createStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import React, { Dispatch, ReactNode, SetStateAction } from 'react';
import { EnhancedTableHead } from '../EnhancedTableHead';
import { EnhancedTableToolbar } from '../EnhancedTableToolbar';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import SendIcon from '@material-ui/icons/Send';
import { Skeleton } from '@material-ui/lab';
import { customtheme } from '../../../app/theme/theme';

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (a: { [key in Key]: string | number }, b: { [key in Key]: string | number }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      maxWidth: '100%',
    },
    paper: {
      width: '100%',
      // marginBottom: theme.spacing(2),
    },
    tableContainer: {
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
      minHeight: 500,
    },
    table: {
      minWidth: 200,
    },
    fullWidthCell: {
      width: '100%',
    },
    selectedRow: {
      '& td, & th': {
        background: customtheme.blue[100], // Cor do texto na linha selecionada
        color: 'white',
      },
    },
    situation: {
      width: '32px',
      height: '32px',
      borderRadius: '50%',
    }
  }),
);

export interface HeadCell {
  disablePadding: boolean;
  id: string;
  label: string;
  numeric: boolean;
}

interface EnhancedTableProps {
  orderBy: string;
  setOrderBy: Dispatch<SetStateAction<string>>;
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  rowsPerPageOptions: Array<number>;
  isClickable?: boolean;
  data: any[];
  headCells: HeadCell[]; 
  isLoading: boolean;
  title: string;
  selected: Array<string>;
  count: number;
  setSelected: Dispatch<SetStateAction<Array<string>>>;
  rowsPerPage: number;
  setRowsPerPage: Dispatch<SetStateAction<number>>;
  buttonsSelected: Array<ReactNode>;
  buttonsActions: Array<string>;
  handleEdit?: (id: string) => void;
  handleRemove?: (id: string) => void;
  handleErro?: (id: string) => void;
  handleApproved?: (id: string) => void;
  handleDisapproved?: (id: string) => void;
  handleSend?: (id: string) => void;
  handleInfo?: (id: string) => void;
  isBoldHeader?: boolean;
}

export default function EnhancedTable(props: EnhancedTableProps) {
  const classes = useStyles();
  const [order, setOrder] = React.useState<Order>('asc');
  const { 
    data, headCells, rowsPerPageOptions, isLoading, title, buttonsSelected,
    isClickable, orderBy, selected, count, page, rowsPerPage, buttonsActions,
    isBoldHeader,
    setOrderBy, setSelected, setPage, setRowsPerPage, handleEdit, handleSend,
    handleErro, handleRemove, handleApproved, handleDisapproved, handleInfo,
  } = props;

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSelecteds = data.map((n:any) => n.id)
    if (event.target.checked) {
      let newSelected: string[] = selected.concat(newSelecteds.filter((id:string) => !selected.includes(id)));
      setSelected(newSelected);
      return;
    } else {
      setSelected(selected.filter((id:string) => !newSelecteds.includes(id)));
    }
  };

  const handleClearAll = () => {
    setSelected([])
  }

  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRemoveButton = (id: string) => {
    if(handleRemove) handleRemove(id)
  }
  
  const handleErroButton = (id: string) => {
    if(handleErro) handleErro(id)
  }

  const handleEditButton = (id: string) => {
    if(handleEdit) handleEdit(id)
  }

  const handleApprovedButton = (id: string) => {
    if(handleApproved) handleApproved(id)
  }

  const handleDisapprovedButton = (id: string) => {
    if(handleDisapproved) handleDisapproved(id)
  }

  const handleSendButton = (id: string) => {
    if(handleSend) handleSend(id)
  }

  const handleInfoButton = (id: string) => {
    if(handleInfo) handleInfo(id)
  }

  const handleButtonsActions = (row:any) => {
    const useCaseBtn = {
      edit: <Tooltip 
          title="Editar"
          onClick={(event) => {
            if(selected.length > 0)
              event.stopPropagation();
            handleEditButton(`${row.id}`);
          }}
          key='edit'
        >
          <IconButton aria-label="edit">
            <EditIcon />
          </IconButton>
        </Tooltip>,
      remove: <Tooltip
          title="Deletar"
          onClick={(event) => {
            if(selected.length > 0)
              event.stopPropagation();
            handleRemoveButton(`${row.id}`);
          }} 
          key='delete'
        >
          <IconButton aria-label="delete">
            <DeleteIcon/>
          </IconButton>
        </Tooltip>,
      erro: <Tooltip
          title="Erro"
          onClick={(event) => {
            if(selected.length > 0)
              event.stopPropagation();
            handleErroButton(`${row.id}`);
          }}
          key='erro'
        >
          <IconButton aria-label="erro">
            <ErrorIcon />
          </IconButton>
        </Tooltip>,
      info: <Tooltip
          title="Info"
          onClick={(event) => {
            if(selected.length > 0)
              event.stopPropagation();
            handleInfoButton(`${row.id}`);
          }}
          key='info'
        >
          <IconButton aria-label="info">
            <InfoIcon />
          </IconButton>
        </Tooltip>,
      approved: <Tooltip 
          title="Aprovar" 
          onClick={(event) => {
            if(selected.length > 0)
              event.stopPropagation();
            handleApprovedButton(`${row.id}`);
          }} 
          key='approved'
        >
          <IconButton aria-label="aprovar">
            <DoneIcon/>
          </IconButton>
        </Tooltip>,
      disapproved: <Tooltip
          title="Reprovar"
          onClick={(event) => {
            if(selected.length > 0)
              event.stopPropagation();
            handleDisapprovedButton(`${row.id}`);
          }} 
          key='disapproved'
        >
          <IconButton aria-label="reprovar">
            <CloseIcon/>
          </IconButton>
        </Tooltip>,
      send: <Tooltip 
          title="Abrir"
          onClick={(event) => {
            if(selected.length > 0)
              event.stopPropagation();
            handleSendButton(`${row.id}`);
          }}
          key='send'
        >
          <IconButton aria-label="send">
            <SendIcon style={{color:'white'}} />
          </IconButton>
        </Tooltip>,
    } as any

    return buttonsActions.filter((button) => {
        if(button === 'erro') {
          const erro =  Object.entries(row).find(([key, val]) => key.includes(button))
          if(erro && erro[1] !== null)
            return true
          else
            return false
        } else if (button === 'info') {
          const item =  Object.entries(row).find(([key, val]:any) => key === 'item' && val.length > 0)
          if(item && item[1] !== null)
            return true
          else
            return false
        }
        return true
      }).map((button) => useCaseBtn[button])
  }

  const handleValMobile = (key: string, val: any) => {
    if (key === 'sit') {
      return <Box display={'flex'} alignItems={'center'} justifyContent={'end'}>
        <Box className={classes.situation} bgcolor={val}/>
      </Box>
    } else {
      if (
        key.includes('cod')
        || key.includes('num') 
        || key.includes('seq')
      ) {
        return val
      } else {
       return `${typeof val === 'number' ? val.toFixed(2) : val}`.replace('.',',')
      }
    }
  }

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  const emptyRows = rowsPerPage - data.length// Math.min(rowsPerPage, data.length - page * rowsPerPage);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Container className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          title={title}
          buttonsSelected={buttonsSelected}
          handleClearAll={handleClearAll}
          isBoldHeader={isBoldHeader}
        />
        <TableContainer  className={`${ isLoading ? classes.tableContainer : ''} `}>
          {
            isLoading ? (
              <div style={{ display: 'flex', height: '100%', flexDirection: 'column', minHeight: 500 }}>
                {
                  Array.from({length: 3}).map((_, index) => (
                    <div key={index} style={{display:'flex'}}>
                      {
                        Array.from({length: 5}).map((_, index) => {
                          return <Skeleton key={index} animation="wave" height={30} width={180} style={{ marginBottom: 8, marginLeft: 20 }} /> 
                          })
                      }
                    </div>
                  ))
                }
              </div>
            ) : (
              <>
                {
                  data.length > 0 ? (
                    <Table
                      className={classes.table}
                      aria-labelledby="tableTitle"
                      size={'medium'}
                      aria-label="enhanced table"
                    >
                      {
                        !isSmallScreen &&
                          <EnhancedTableHead
                            isClickable={isClickable || false}
                            numSelected={selected.filter((id:string) => data.find((d) => d.id === id)).length}
                            rowCount={data.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            headCells={headCells}
                            isBoldHeader={isBoldHeader}
                          />
                      }
                      <TableBody>
                        <>
                          {
                            stableSort(data, getComparator(order, orderBy))
                              //.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                              .map((row, index) => {
                                const isItemSelected = isSelected(`${row.id}`);
                                const labelId = `enhanced-table-checkbox-${index}`;
                                return (
                                  <TableRow
                                    hover
                                    onClick={(event) => (isClickable ? handleClick(event, `${row.id}`) : () => {})}
                                    role="checkbox"
                                    aria-checked={isItemSelected}
                                    tabIndex={-1}
                                    key={row.id}
                                    selected={isItemSelected}
                                    className={isItemSelected ? classes.selectedRow : ''}
                                    style={{height: 80}}
                                  >
                                    <TableCell padding="checkbox">
                                      {
                                        isClickable && 
                                          <Checkbox
                                            checked={isItemSelected}
                                            inputProps={{ 'aria-labelledby': labelId }}
                                            style={{color: isItemSelected ? customtheme.blue[500]: ''}}
                                          />
                                        }
                                      </TableCell>
                                    {
                                      isSmallScreen ? (
                                        <>
                                          {
                                            Object.entries(row)
                                              .filter(([key, val]) => headCells.flatMap((header) => header.id).includes(key))
                                              .map(([key, val]) => {
                                                return (
                                                  <TableRow>
                                                    <TableCell
                                                      component="th"
                                                      id={labelId}
                                                      scope="row"
                                                      padding="none"
                                                      className={classes.fullWidthCell} // Aplica a nova classe aqui
                                                      style={{paddingLeft: isClickable ? 0 : 20}}
                                                    >
                                                      {headCells.find((header) => header.id === key)?.label}
                                                    </TableCell>
                                                    <TableCell align="right" className={classes.fullWidthCell}>
                                                      { 
                                                        handleValMobile(key, val)
                                                      }
                                                    </TableCell>
                                                  </TableRow>
                                                );
                                              })
                                          }
                                          <TableRow>
                                            <TableCell
                                              component="th"
                                              id={labelId}
                                              scope="row"
                                              padding="none"
                                              className={classes.fullWidthCell} // Aplica a nova classe aqui
                                            >
                                              {headCells.find((header) => header.id === 'actions')?.label}
                                            </TableCell>
                                            <TableCell align="right" className={classes.fullWidthCell}>
                                              <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                                                {handleButtonsActions(row)}
                                              </div>
                                            </TableCell>
                                          </TableRow>
                                        </>
                                      ) : (
                                        <>
                                          {
                                            Object.entries(row)
                                              .filter(([key, val]) => headCells.flatMap((header) => header.id).includes(key))
                                              .map(([key, val]) => {
                                                if (typeof val === 'number') {
                                                  return (
                                                    <TableCell key={key} align="right">
                                                      { 
                                                        key.includes('cod')
                                                        || key.includes('num') 
                                                        || key.includes('seq') 
                                                        ? 
                                                          val
                                                        :
                                                         `${typeof val === 'number' ? val.toFixed(2) : val}`.replace('.',',')
                                                      }
                                                    </TableCell>
                                                  );
                                                } else {
                                                  return (
                                                    <TableCell
                                                      key={key}
                                                      component="th"
                                                      id={labelId}
                                                      scope="row"
                                                      padding="none"
                                                    >
                                                      {
                                                        key === 'sit' ?
                                                          <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                                            <Box className={classes.situation} bgcolor={val}/>
                                                          </Box>
                                                        : val
                                                      }
                                                    </TableCell>
                                                  );
                                                }
                                              })
                                          }
                                          <TableCell>
                                            <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                                              {handleButtonsActions(row)}
                                            </div>
                                          </TableCell>
                                        </>
                                      )
                                    }
                                  </TableRow>
                                );
                              }
                            )
                          }
                          {
                            emptyRows > 0 && (
                              <TableRow style={{ height: (80) * emptyRows }}>
                                <TableCell colSpan={2} />
                              </TableRow>
                            )
                          }
                        </>             
                      </TableBody> 
                    </Table>
                  ) : (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: 500 }}>
                      <Typography variant="body1">
                        Tabela vazia
                      </Typography>
                    </div>
                  )
                }
              </>
            )
          }
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={rowsPerPageOptions}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Container>
  );
}