import {
  Container,
  IconButton,
  Tooltip
} from '@material-ui/core';

import Snackbar from '@material-ui/core/Snackbar';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { getErp } from '../../app/services/erp/get-erp.api';
import { createRequisitionApproved, createRequisitionDisapproved } from '../../app/services/requisition-appoved/create-requisition-appoved.api';
import EnhancedTable, { HeadCell } from '../../common/components/EnhancedTable';
import RequisitionApprovedDialog from './RequisitionApprovedDialog';
import RequisitionApprovedHeader from './RequisitionApprovedHeader';
import { customtheme } from '../../app/theme/theme';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: customtheme.gray[200],
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  tableSection: {
    padding: theme.spacing(4),
    flex: 1,
    display: 'flex',
  },
  tableSectionContainer: {
    padding: 0, 
    flex: 1,
    display: 'flex', 
    flexDirection: 'column',
    maxWidth: '100%',
  },
  appbar: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    background: customtheme.white,
    color: customtheme.black,
    padding: theme.spacing(2),
    margin: 1,
  },  
  scrollableTabs: {
    overflowX: 'auto',
  }, drawer: {
    width: 400,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.divider}`,
    background: customtheme.gray[200],
  },
  drawerContent: {
    overflowY: 'auto',
    padding: theme.spacing(2), 
    height: '100%',
  },
  drawerFooter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(2),
    borderTop: `1px solid ${theme.palette.divider}`,
    background: customtheme.gray[200],
  },
  textField: {
    minWidth: 140,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    '& .MuiInputBase-input': {
      color: customtheme.black,
    },
    '& .MuiInput-underline:hover:before': {
      borderBottomColor: customtheme.blue[500],
    },
  },
  textFieldFeedback: {
    minWidth: 140,
    '& .MuiInputBase-input': {
      color: customtheme.black,
    },
    '& .MuiInput-underline:hover:before': {
      borderBottomColor: customtheme.blue[500],
    },
  },
}));

const RequisitionApproved = () => {
  const classes = useStyles();

  const windowHeight = window.innerHeight;
  const headerHeight = 500; // Altura do cabeçalho em pixels
  const footerHeight = 48; // Altura do rodapé em pixels
  const itemHeight = 80; // Altura média de cada item em pixels
  const availableHeight = windowHeight - headerHeight - footerHeight;
  const itemsPerPage = Math.floor((availableHeight) / itemHeight)

  const [selectedData, setSelectedData] = useState<any>([]);
  const [orderBy, setOrderBy] = useState<string>('numero');
  const [selected, setSelected] = useState<string[]>([]);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(itemsPerPage > 5 ? itemsPerPage : 5);
  const [isDialogFeedbackOpen, setIsDialogFeedbackOpen] = useState({open:false, message: '', title: '', btnType: ''});
  const [isSnackbar, setIsSnackbar] = useState({open:false, message: 'info', title: ''});
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState<string>('')
  const [reasonCancel, setReasonCancel] = useState<string>('')

  const headCells: HeadCell[] = [
    { id: 'numeme', numeric: true, disablePadding: false, label: 'Número' },
    { id: 'seqeme', numeric: false, disablePadding: true, label: 'Sequência' },
    { id: 'dateme', numeric: false, disablePadding: true, label: 'Data Emissão' },
    { id: 'cod', numeric: false, disablePadding: true, label: 'Código' },
    { id: 'cplpro', numeric: false, disablePadding: true, label: 'Descrição' },
    { id: 'codder', numeric: false, disablePadding: true, label: 'Derivação' },
    { id: 'unimed', numeric: false, disablePadding: true, label: 'Unidade' },
    { id: 'nomusures', numeric: false, disablePadding: true, label: 'Usuário' },
    { id: 'qtdeme', numeric: true, disablePadding: false, label: 'Quantidade' },
    { id: 'preeme', numeric: true, disablePadding: false, label: 'Preço' },
    { id: 'vlreme', numeric: true, disablePadding: false, label: 'Valor' },
    { id: 'actions', numeric: true, disablePadding: false, label: 'Ações' },
  ];

  const createData = (props:any) => {
    const { codder, codpro, codser, proser, cplpro, dateme, nomusures, numeme, preeme, qtdeme, seqeme, unimed, vlreme,  } = props
    
    if(proser === 'S')
      return { numeme, seqeme, dateme, cod:codser, cplpro, codder, unimed, nomusures, qtdeme, preeme, vlreme, proser, ...props };
    
    return { numeme, seqeme, dateme, cod:codpro, cplpro, codder, unimed, nomusures, qtdeme, preeme, vlreme, proser, ...props };
  }

  const handleCloseSnackbar = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setIsSnackbar({open: false, message: '', title: 'info'});
  };

  const handleCloseErro = () => {
    setIsDialogFeedbackOpen({open:false, message: '', title: '', btnType: 'erro'});
    setSelected([])
  }

  const paginateArray = (array: Array<any>) => {
    const startIndex =  page * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    return array.slice(startIndex, endIndex);
  }

  const { isFetching, refetch } = useQuery(['requisitionApproved'], async () => {
    // obtem as requisicao para aprovar
    const token = localStorage.getItem('token')
    if(token){
      const res = await getErp({
        route: 'gridRequisicoes', 
        idSystem: 'PORTAL', 
        // skip: page+1, 
        // take: rowsPerPage
      } ,token)
      if(res){
        const filtered = res.filter((r:any) => {
          if (`${r.seqeme}`.includes(search) || `${r.numeme}`.includes(search) || search === '')
            return true
          return false
        })
        const paginate = paginateArray(filtered)
        setSelectedData(paginate.map((p:any) => ({
          ...p, 
          id:`${p.numeme+p.seqeme}`, 
        })));
        setCount(res.length)
      }   
    }
    return []
  }, {
    refetchOnWindowFocus: false,
  })
  
  useEffect(() => {
    refetch();
  }, [page, refetch]);

  const Alert = (props: AlertProps) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const handleFormatedDataToDatabase = async (btnType: string) => {
    setIsLoading(true)
    
    const useCaseSuccess = {
      approved: 'aprovar',
      approvedAll: 'aprovar',
      disapproved: 'reprovar',
      disapprovedAll: 'aprovar',
    } as any
    const token = localStorage.getItem('token')
    let res = {
      statusCode: 400,
    } as any
    if(token){
      if(btnType === 'approved' || btnType === 'approvedAll')
        res = await createRequisitionApproved(selectedData.filter((data:any) => selected.find((sel) => sel === data.id)), token)
      if(btnType === 'disapproved' || btnType === 'disapprovedAll')
        res = await createRequisitionDisapproved(
          selectedData
            .filter((data:any) => selected.find((sel) => sel === data.id))
            .map((data:any) => ({...data, reason: reasonCancel})),
          token
        )
       
      setIsLoading(false)
      if(res.statusCode === 200){
        setIsSnackbar({
          open: true,
          message: `Sucesso ao ${useCaseSuccess[btnType]} requisição`,
          title: 'success'
        })
        refetch()
      } else {
        setIsSnackbar({
          open: true,
          message: `Erro ao ${useCaseSuccess[btnType]} requisição`,
          title: 'error'
        })
      }
      setIsDialogFeedbackOpen({
        open: false,
        message: '',
        title: '',
        btnType,
      })
      setSelected([])
    }
  } 

  const handleDisapprovedAll = () => {
    handleDialogConfirmation('disapprovedAll') 
  }

  const handleDisapproved = (id:any) => {
    handleDialogConfirmation('disapproved')
  }

  const handleApprovedAll = () => {
    handleDialogConfirmation('approvedAll') 
  }

  const handleApproved = (id:any) => {
    handleDialogConfirmation('approved')
  }

  const handleDialogConfirmation = (type: string) => {
    const useCaseFeedbackTitle = {
      approved: 'Deseja aprovar requisição',
      approvedAll: 'Deseja aprovar todas as requisições',
      disapproved: 'Deseja reprovar requisição',
      disapprovedAll: 'Deseja reprovar todas as requisições',
    } as any

    setIsDialogFeedbackOpen({
      open: true,
      title: useCaseFeedbackTitle[type],
      message: '',
      btnType: type,
    })
  }

  return (
    <div className={classes.root}>
      <RequisitionApprovedHeader
        refetch={refetch}
        search={search}
        setSearch={setSearch}
      />
      <div className={classes.tableSection}>
        <Container className={classes.tableSectionContainer}>
          <EnhancedTable 
            title='Ordens'
            rowsPerPageOptions={[]}
            isClickable={true}
            data={selectedData?.map((data:any) => createData(data) )}
            count={count}
            orderBy={orderBy}
            setOrderBy={setOrderBy}
            headCells={headCells}
            isLoading={isFetching}
            selected={selected}
            setSelected={setSelected}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            buttonsSelected={[ 
              selected.length === 1 && 
                <Tooltip title="Aprovar" onClick={handleApproved} key='approved'>
                  <IconButton aria-label="aprovar">
                    <DoneIcon style={{color: customtheme.white}}/>
                  </IconButton>
                </Tooltip>,
              selected.length === 1 && 
              <Tooltip title="Reprovar" onClick={handleDisapproved} key='disapproved'>
                <IconButton aria-label="reprovar">
                  <CloseIcon style={{color: customtheme.white}}/>
                </IconButton>
              </Tooltip>,
              selected.length > 1 && 
                <Tooltip title="Aprovar Todos" onClick={handleApprovedAll} key='approvedAll'>
                  <IconButton aria-label="aprovar-todos">
                    <DoneAllIcon style={{color: customtheme.white}}/>
                  </IconButton>
                </Tooltip>,
              selected.length > 1 && 
                <Tooltip title="Reprovar Todos" onClick={handleDisapprovedAll} key='disapprovedAll'>
                  <IconButton aria-label="reprovar-todos">
                    <CloseIcon style={{color: customtheme.white}}/>
                  </IconButton>
                </Tooltip>,
            ]}
            buttonsActions={['approved', 'disapproved']}
            handleApproved={handleApproved}
            handleDisapproved={handleDisapproved}
          />
        </Container>
      </div>
      <RequisitionApprovedDialog
        handleCloseErro={handleCloseErro}
        handleFormatedDataToDatabase={handleFormatedDataToDatabase}
        isDialogFeedbackOpen={isDialogFeedbackOpen}
        isLoading={isLoading}
        reasonCancel={reasonCancel}
        setReasonCancel={setReasonCancel}
      />
      <Snackbar open={isSnackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={isSnackbar.title as any}>
          {isSnackbar.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default RequisitionApproved;