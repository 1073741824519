import { allMonths } from "../../common/functions/getLastSixMonth";

export const checkInfoByMonthAndStatus= (
    status: string, 
    data: any, 
    sixMonthsBefore: {
        sixMonthsBefore: string[],
        date: string
    }
) => {
    const groupedData = {} as any;

    data?.forEach((item:any) => {
        const month = new Date(item.atualizado_em).getMonth()
        if (!groupedData[allMonths[month]]) {
            groupedData[allMonths[month]] = [];
        }

        if (item.status === status) {
        groupedData[allMonths[month]].push(item);
        }
    });

    return sixMonthsBefore.sixMonthsBefore.map((month) => groupedData[month] !== undefined ? groupedData[month].length : 0)
}