import { Container, Typography, makeStyles } from "@material-ui/core";
import SearchButton from "../../../common/components/SearchButton";
import { customtheme } from "../../../app/theme/theme";

const useStyles = makeStyles((theme) => ({
    topBar: {
        backgroundColor: customtheme.gray[200],
        padding: theme.spacing(2),
        paddingTop: theme.spacing(4),
    },  
    topBarContent: {   
        flex: 1,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    topBarButtons: {
        '& > *': {
          marginLeft: theme.spacing(1),
        },
    },
    backgroundColor: {
      background: customtheme.blue[500],
      color: customtheme.white,
      '&:hover': {
        background: customtheme.blue[50],
        color: customtheme.blue[500],
      },
    },
}))

interface PurchaseOrderEmitHeaderProps {
    refetch: () => void;
    search: string;
    setSearch: (value:string) => void;
}

const PurchaseOrderEmitHeader = (props: PurchaseOrderEmitHeaderProps) => {
    const classes = useStyles()
    const { refetch, search, setSearch } = props;

    return (
        <div className={classes.topBar}>
        <Container style={{maxWidth: '100%', display: 'flex', flexWrap: 'wrap'}}>
          <div className={classes.topBarContent}>
            <Typography variant="h4">Aprovar / Reprovar</Typography>
          </div>
          <SearchButton
            value={search}
            onChange={(event) => {
              setSearch(event.currentTarget.value)
            }}
            onClick={() => {
              refetch()
            }}
          />
        </Container>
      </div>
    )
}

export default PurchaseOrderEmitHeader;