import { List } from "@material-ui/core";
import { MenuItem } from "../MenuItem";
import PostAddIcon from '@material-ui/icons/PostAdd';
import ListIcon from '@material-ui/icons/List';
import ListAltIcon from '@material-ui/icons/ListAlt';
import AssignmentIcon from '@material-ui/icons/Assignment';
import DashboardIcon from '@material-ui/icons/Dashboard';
import BallotIcon from '@material-ui/icons/Ballot';
import DnsIcon from '@material-ui/icons/Dns';
import AssignmentReturnedIcon from '@material-ui/icons/AssignmentReturned'
import AssignmentReturnIcon from '@material-ui/icons/AssignmentReturn'
// import ViewListIcon from '@material-ui/icons/ViewList';
import TuneIcon from '@material-ui/icons/Tune';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import { Role } from "../../enum/roles.enum";
import { Modules } from "../../enum/modules.enum";

interface MenuProps {
    userType: string;
    modules: Array<string>; 
    expanded: string;
    onClick:(item: string) => void;
}

const getMenuItems = (userType: string, modules: string[]) => {
  const routes = [
    { id: Modules.DASHBOARD, text: 'Dashboard', icon: <DashboardIcon />, to: '/dashboard' },
    { id: Modules.LICENCA, text: 'Licenças', icon: <AssignmentIcon />, to: '/licencas' },
    { id: Modules.REQUISICAO, text: 'Requisição Eletrônica', icon: <ListAltIcon />, to: '/', submenu: [
      {
        to: '/requisicao/emissao',
        icon: <PostAddIcon />,
        text: 'Emitir Requisição',
      },
      {
        to: '/requisicao/emissao/consulta',
        icon: <ListIcon />,
        text: 'Consulta Emissão',
      }, {
        to: '/requisicao/aprovacao',
        icon: <PlaylistAddIcon />,
        text: 'Aprovação Requisição',
      },
      {
        to: '/requisicao/aprovacao/consulta',
        icon: <ListIcon />,
        text: 'Consulta Aprovação',
      },
    ]},
    // { id: Modules.REQUISICAOAPROVACAO, text: 'Aprovação Requisição Eletrônica', icon: <ViewListIcon />, to: '/', submenu: []},
    { id: Modules.APROVACAO, text: 'Ordem de Compra', icon: <BallotIcon />, to: '/', submenu: [
      {
        to: '/ordemcompra',
        icon: <PostAddIcon />,
        text: 'Aprovação O.C.',
      },
      {
        to: '/ordemcompra/consulta',
        icon: <ListIcon />,
        text: 'Consulta',
      },
    ]},
    { id: Modules.MANUTENCAO, text: 'Manutenção', icon: <DnsIcon />, to: '/', submenu: [
      {
        to: '/manutencao/solicitar',
        icon: <AssignmentIcon />,
        text: 'Solicitar Manutenção',
      },
      {
        to: '/manutencao/solicitar/consulta',
        icon: <ListIcon />,
        text: 'Consulta Solicitar Manutenção',
      },
      {
        to: '/manutencao/apontar',
        icon: <AssignmentReturnIcon />,
        text: 'Apontar Manutenção',
      },
      {
        to: '/manutencao/apontar/consulta',
        icon: <ListIcon />,
        text: 'Consulta Apontamento Manutenção',
      },
      {
        to: '/manutencao/coleta',
        icon: <AssignmentReturnedIcon />,
        text: 'Apontar Coleta',
      },
      {
        to: '/manutencao/coleta/consulta',
        icon: <ListIcon />,
        text: 'Consulta Apontamento Coleta',
      },
      {
        to: '/manutencao/painel',
        icon: <TuneIcon />,
        text: 'Painel',
      },
      {
        to: '/manutencao/dashboard',
        icon: <DashboardIcon />,
        text: 'Dashboard Manutenção',
      },
    ]},
  ]
  if (userType === Role.ADMIN || userType === Role.QUATTUOR) {
    return routes.filter((route) => modules.includes(route.id));
  } else if (userType === Role.VENDEDOR) {
    return routes.filter((route) => route.id !== Modules.LICENCA).filter((route) => modules.includes(route.id));
  } else {
    return [];
  }
};

export const Menu: React.FC<MenuProps> = ({ userType, modules, expanded, onClick }) => {
  const menuItems = getMenuItems(userType, modules)

  return (
    <List>
      {menuItems.map((item, index) =>
        item.submenu ? (
          <MenuItem
            key={item.text}
            to={item.to}
            icon={item.icon}
            text={item.text}
            expanded={expanded}
            onClick={onClick}
            submenu={item.submenu.map((subitem:any) => (
              <MenuItem
                key={subitem.text}
                to={subitem.to}
                icon={subitem.icon}
                text={subitem.text}
                expanded={expanded}
                onClick={onClick}
              />
            ))}
          />
        ) : (
          <MenuItem
              key={item.text}
              to={item.to}
              icon={item.icon}
              text={item.text} 
              expanded={expanded}
              onClick={onClick}
          />
        )
      )}
    </List>
  );
};