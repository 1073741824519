export const options = {
    plugins: {
      legend: {
        position: "top" as const,
      },
      title: {
        display: false,
        text: "",
      },
    },
};

export const optionsInt = {
  scales: {
    // x: {
    //   type: 'linear', // Use 'linear' scale for X-axis
    //   ticks: {
    //     stepSize: 1, // Defina o tamanho do intervalo entre os ticks para 1 (valor inteiro)
    //     precision: 0, // Defina a precisão para 0 para mostrar apenas números inteiros
    //   },
    // },
    y: {
      type: 'linear', // Use 'linear' scale for Y-axis
      ticks: {
        stepSize: 1, // Defina o tamanho do intervalo entre os ticks para 1 (valor inteiro)
        precision: 0, // Defina a precisão para 0 para mostrar apenas números inteiros
      },
    },
  },
  plugins: {
    legend: {
      position: "top" as const,
    },
    title: {
      display: false,
      text: "",
    },
  },
};

export const optionsHorizontal = {
  indexAxis: 'y' as const,
  elements: {
    bar: {
      borderWidth: 2,
    },
  },
  responsive: true,
  plugins: {
    legend: {
      position: 'right' as const,
    },
    title: {
      display: false,
      text: '',
    },
  },
};

export const optionsHorizontalInt = {
  indexAxis: 'y' as const,
  scales: {
    x: {
      type: 'linear', // Use 'linear' scale for X-axis
      ticks: {
        stepSize: 1, // Defina o tamanho do intervalo entre os ticks para 1 (valor inteiro)
        precision: 0, // Defina a precisão para 0 para mostrar apenas números inteiros
      },
    },
    // y: {
    //   type: 'linear', // Use 'linear' scale for Y-axis
    //   ticks: {
    //     stepSize: 1, // Defina o tamanho do intervalo entre os ticks para 1 (valor inteiro)
    //     precision: 0, // Defina a precisão para 0 para mostrar apenas números inteiros
    //   },
    // },
  },
  elements: {
    bar: {
      borderWidth: 2,
    },
  },
  responsive: true,
  plugins: {
    legend: {
      position: 'right' as const,
    },
    title: {
      display: false,
      text: '',
    },
  },
};