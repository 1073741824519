import { CircularProgress, CircularProgressProps, Theme, Typography, createStyles, makeStyles } from "@material-ui/core";
import { ColorModules, Modules } from "../../enum/modules.enum";
import { customtheme } from "../../../app/theme/theme";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      // position: 'relative',
      display: 'flex',
      justifyContent: 'center',
    },
    bottom: {
      color: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    top: {
      color: customtheme.blue[500],
      animationDuration: '550ms',
      position: 'absolute',
      display: 'flex',
    },
    circle: {
      strokeLinecap: 'round',
    },
    progressText: {
      position: 'absolute',
      // top: '50%',
      // left: '50%',
      // transform: 'translate(-50%, -50%)',
      textAlign: 'center',
      marginTop: 60,
    },
  }),
);

interface CircularProgressBackgroundProps extends CircularProgressProps {
  text: string,
  module: Modules
}

export const CircularProgressBackground = (props: CircularProgressBackgroundProps) => {
    const classes = useStyles();
    
    return (  
      <div className={classes.root}>
        <CircularProgress
          variant="determinate"
          className={classes.bottom}
          {...props}
          value={100}
        />
        <CircularProgress
          variant="determinate" 
          className={classes.top}
          {...props}
          style= {{
            color: ColorModules[props.module],
          }}
          classes={{
            circle: classes.circle,
          }}
        />
        <Typography variant="body2" className={classes.progressText}>
          {props.text}
        </Typography>
      </div>
    );
  }