import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, List, ListItem, ListItemText, TextField, Typography, makeStyles } from "@material-ui/core";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useAuth } from "../../hooks/AuthContext";
import { getErp } from "../../../app/services/erp/get-erp.api";
import { customtheme } from "../../../app/theme/theme";
import { Pagination } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
    dialogContent: {
      minWidth: 310,
    },
    listContainer: {
        height: 200,
        overflowY: 'auto',
    },
    listItem: {
        '&.Mui-selected': {
            backgroundColor:customtheme.blue[500],
            color: theme.palette.common.white,
        },      
    },
    listItemText: {
        fontWeight: 'bold',
    },
    backgroundColor: {
      background: customtheme.blue[500],
      color: customtheme.white,
      '&:hover': {
        background: customtheme.blue[50],
        color: customtheme.blue[500],
      },
    },
    backBackgroundColor: {
      background: customtheme.gray[200],
      color: customtheme.blue[500],
      marginRight: 20,
      '&:hover': {
        background: customtheme.blue[50],
        color: customtheme.blue[500],
      },
    },
}));

interface DialogSearchProps {
    title: string;
    keyItem: string;
    keySubItem: string;
    id: string;
    isDialogOpen: boolean;
    isPrevSearch: boolean;
    prevSearch: any;
    prevDataSearch: any;
    handleCloseDialog: () => void;
    handleUpdateField: (data: any) => void;
}

export default function DialogSearch(props: DialogSearchProps) {
    const classes = useStyles();
    const { auth } = useAuth();
    const { 
        title, id, keyItem, isDialogOpen,
        isPrevSearch, prevSearch, keySubItem,
        prevDataSearch,
        handleCloseDialog, handleUpdateField 
    } = props;
      
    const [filterText, setFilterText] = useState('');
    const [filteredItems, setFilteredItems] = useState<Array<any>>([]);
    const [selectedItem, setSelectedItem] = useState<any | null>(null);
    const [page, setPage] = useState(0);
    const sizeList = 10;

    const { data, isFetching, refetch } = useQuery(['dialogSearch'], async () => {
        const empty = {} as any
        empty[keyItem] = 'Nenhum item'
        if(!isPrevSearch){
            const token = localStorage.getItem('token')
            if(auth?.empresa_filial_id && token && auth?.EmpresaFilial && id){
                const res = await getErp({
                    route: id, 
                    idSystem: 'PORTAL', 
                    qtd: 1000, 
                    ...prevDataSearch,
                } ,token)   
                if(res){
                    const updated = [...res]
                    updated.unshift(empty)
                    setFilteredItems(updated)
                    return updated
                }
            }
        } else{
            const updated = [...prevSearch]
            updated.unshift(empty)
            setFilteredItems(updated)
            return updated
        }
        return []
    }, {
        refetchOnWindowFocus: false,
    })

    useEffect(() => {
        if(isDialogOpen)
            refetch();
    }, [isDialogOpen, refetch]);

    const handleItemClick = (item: any) => {
        setSelectedItem(item);
      };
  
    const handleFilterChange = (event: any) => {
        setFilterText(event.target.value)
        setPage(0)
        if (data) {
            if (event.target.value)
                setFilteredItems(data.filter((item:any) =>
                    `${item?.[keyItem]}`?.toLowerCase().includes(event.target.value.toLowerCase()) || 
                    `${item?.[keySubItem]}`?.toLowerCase().includes(event.target.value.toLowerCase())
                ));
            else 
                setFilteredItems(data);
        }
        
    };

    const handleChangePagination = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value-1);
    };

    return (
        <Dialog open={isDialogOpen} onClose={() => {
            setFilterText('')
            setSelectedItem(null)
            handleCloseDialog()
        }}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <TextField
                    label="Filtro"
                    value={filterText}
                    onChange={handleFilterChange}
                    disabled={isFetching}
                    fullWidth
                />
                 <div className={classes.listContainer}>
                    {
                        isFetching ? (
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                               <CircularProgress style={{color: customtheme.blue[500]}} />
                            </div>
                        ) : (
                            <>
                                {
                                    filteredItems?.length === 0 ? (
                                        <Typography variant="body1" style={{ textAlign: 'center', marginTop: '20px' }}>
                                            Sem itens
                                        </Typography>
                                    ) : (
                                        <List>
                                            {
                                                filteredItems?.slice(page*sizeList, page*sizeList+sizeList)
                                                .map((item:any, index:number) => (
                                                    <ListItem
                                                        key={index}
                                                        button
                                                        selected={selectedItem?.[keyItem] === item[keyItem]}
                                                        onClick={() => handleItemClick(item)}
                                                        className={selectedItem?.[keyItem] === item[keyItem] ? classes.listItem : ''}
                                                    >
                                                        <ListItemText 
                                                            primary={                                                               
                                                                item[keySubItem] ? 
                                                                    `${item[keyItem]} - ${item[keySubItem]}` :
                                                                    `${item[keyItem]}`
                                                                }
                                                            primaryTypographyProps={{
                                                                className:
                                                                selectedItem?.[keyItem] === item[keyItem] ? classes.listItemText : '',
                                                            }}
                                                        />
                                                    </ListItem>
                                                ))
                                            }
                                        </List>
                                    )
                                }
                            </>
                        )
                    }
                </div>
                <Pagination count={Math.ceil(filteredItems.length / sizeList)} page={page+1}  onChange={handleChangePagination} />
            </DialogContent>
            <DialogActions style={{flexWrap: 'wrap'}}>
                {
                    selectedItem && (
                        <div style={{}}>
                            <Typography variant="body1">
                                Item selecionado: {selectedItem?.[keyItem]}
                            </Typography>
                        </div>
                    )
                }
                <div>
                    <Button 
                        onClick={() => {
                            setFilteredItems([])
                            setFilterText('')
                            setSelectedItem(null)
                            handleCloseDialog()
                        }} 
                        className={classes.backBackgroundColor}
                    >
                        Cancelar
                    </Button>
                    <Button 
                        onClick={() => {
                            setFilteredItems([])
                            setFilterText('')
                            setSelectedItem(null)
                            handleUpdateField(selectedItem)}
                        } 
                        className={classes.backgroundColor} 
                        variant="contained"
                        disabled={isFetching || !selectedItem}
                    >
                        Confirmar
                    </Button>
                </div>
            </DialogActions>
        </Dialog>
    )
}