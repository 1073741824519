import {
  Container,
  IconButton,
  Tooltip,
} from '@material-ui/core';

import Snackbar from '@material-ui/core/Snackbar';
import { makeStyles } from '@material-ui/core/styles';
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep';
import SendIcon from '@material-ui/icons/Send';
import ErrorIcon from '@material-ui/icons/Error';
import EditIcon from '@material-ui/icons/Edit';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { createRequistionDraft } from '../../app/services/requisition/create-requisition.api';
import { deleteRequistion, deleteAllRequistions } from '../../app/services/requisition/delete-requisition.api';
import { getDraftRequisitionByUser } from '../../app/services/requisition/get-requisiton-module.api';
import { updateRequistion } from '../../app/services/requisition/update-requisition.api';
import DialogSearch from './DialogSearch';
import EnhancedTable, { HeadCell } from '../../common/components/EnhancedTable';
import { useAuth } from '../../common/hooks/AuthContext';
import { processRequistions } from '../../app/services/requisition/process-requisition.api';
import { mapperToRequisition } from '../../app/mappers/requisition/requisition.mapper';
import RequisitionEmitDrawer from './RequisitionEmitDrawer';
import RequisitionEmitDialog from './RequisitonEmitDialog';
import RequisitionEmitHeader from './RequisitionEmitHeader';
import { customtheme } from '../../app/theme/theme';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: customtheme.gray[200],
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  tableSection: {
    padding: theme.spacing(4),
    flex: 1,
    display: 'flex',
  },
  tableSectionContainer: {
    padding: 0, 
    flex: 1,
    display: 'flex', 
    flexDirection: 'column',
    maxWidth: '100%',
  },
  appbar: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    background: customtheme.white,
    color: customtheme.black,
    padding: theme.spacing(2),
    margin: 1,
  },  
  scrollableTabs: {
    overflowX: 'auto',
  }, 
}));

interface DialogSearchData {
  id: string;
  title: string;
  key: string;
  keySub: string;
  isPrevSearch?: boolean;
  prevSearch?: any;
  prevDataSearch: any;
}

const RequisitionEmit = () => {
  const classes = useStyles();

  const windowHeight = window.innerHeight;
  const headerHeight = 500; // Altura do cabeçalho em pixels
  const footerHeight = 48; // Altura do rodapé em pixels
  const itemHeight = 80; // Altura média de cada item em pixels
  const availableHeight = windowHeight - headerHeight - footerHeight;
  const itemsPerPage = Math.floor((availableHeight) / itemHeight)

  const [selectedData, setSelectedData] = useState<any>([]);
  const [orderBy, setOrderBy] = useState<string>('nomrep');
  const [selected, setSelected] = useState<string[]>([]);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(itemsPerPage > 5 ? itemsPerPage : 5);
  const [isDrawerOpen, setIsDrawerOpen] = useState({open: false, btnTitle: 'Salvar', btnType: 'save'});
  const [isDialogSearchOpen, setIsDialogSearchOpen] = useState(false);
  const [isDialogFeedbackOpen, setIsDialogFeedbackOpen] = useState({open:false, message: '', title: '', btnType: ''});
  const [isSnackbar, setIsSnackbar] = useState({open:false, message: 'info', title: ''});
  const [isLoading, setIsLoading] = useState(false);
  const [dialogDataSearch, setDialogDataSearch] = useState<DialogSearchData | null>(null);
  const fieldsInit = mapperToRequisition({})

  const [fields, setFields] = useState<any[]>(fieldsInit)
  const { auth } = useAuth() 
  const headCells: HeadCell[] = [
    { id: 'codpro', numeric: false, disablePadding: true, label: 'Código Produto' },
    { id: 'despro', numeric: false, disablePadding: true, label: 'Produto' },
    { id: 'codder', numeric: false, disablePadding: true, label: 'Código Derivação' },
    { id: 'desder', numeric: false, disablePadding: true, label: 'Derivação' },
    { id: 'qtdeme', numeric: true, disablePadding: false, label: 'Quantidade' },
    { id: 'dateme', numeric: false, disablePadding: true, label: 'Data Emissão' },
    { id: 'datprv', numeric: false, disablePadding: true, label: 'Data Previsão' },
    // { id: 'coddep', numeric: false, disablePadding: true, label: 'Código Depósito' },
    // { id: 'desdep', numeric: false, disablePadding: true, label: 'Depósito' },
    { id: 'codccu', numeric: false, disablePadding: true, label: 'Código Centro Custo' },
    { id: 'desccu', numeric: false, disablePadding: true, label: 'Centro de Custo' },
    { id: 'actions', numeric: true, disablePadding: false, label: 'Ações' },
  ];

  const createData = (props:any) => {
    const {id, codpro, despro, codccu, desccu, codder, desder, coddep, desdep, qtdeme, dateme, datprv} = props
    return { id, codpro, despro, codder, desder, qtdeme, dateme, datprv, coddep, desdep, codccu, desccu, ...props };
  }

  const handleOpenDrawer = () => {
    setIsDrawerOpen({open: true, btnTitle: 'Salvar', btnType: 'save'});
  };

  const handleCloseDrawer = () => {
    setSelected([])
    setFields(fieldsInit);
    setIsDrawerOpen({open: false, btnTitle: 'Salvar', btnType: 'save'});
  };

  const handleOpenDialogSearch = (data: DialogSearchData) => {
    setIsDialogSearchOpen(true);
    setDialogDataSearch(data);
  };

  const handleCloseDialogSearch = () => {
    setIsDialogSearchOpen(false);
    setDialogDataSearch(null);
    setSelected([]);
  };

  const handleCloseSnackbar = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setIsSnackbar({open: false, message: '', title: 'info'});
  };

  const handleCloseErro = () => {
    setIsDialogFeedbackOpen({open:false, message: '', title: '', btnType: 'erro'});
    setSelected([])
    setFields(fieldsInit);
  }

  const handleUpdateField = (data: any) => {
    const update = [...fields];
    update[update.findIndex((field) => field.id === dialogDataSearch?.id)].value = 
      data[dialogDataSearch?.key || ''] === 'Nenhum item' ? null : data
    setFields(update)
    setIsDialogSearchOpen(false);
    setDialogDataSearch(null);
  }

  const { isFetching, refetch } = useQuery(['requisitionEmit'], async () => {
    // obtem as requisicao rascunho
    const token = localStorage.getItem('token')
    if(token){
      const res = await getDraftRequisitionByUser(token, {
        take: rowsPerPage,
        skip: page*rowsPerPage,
      })
      if(res){
        setSelectedData(res.results)
        setCount(res.total)
      }
    }
    return []
  }, {
    refetchOnWindowFocus: false,
  })
  
  useEffect(() => {
    refetch();
  }, [page, refetch]);

  const Alert = (props: AlertProps) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const handleEmptyField = (data:any, key:string, val: any, type: string) => {
    if(val){
      data[key] = type === 'string' ? val+'' : val;
    } else {
      data[key] = null;
    } 
    return data;
  }

  const handleFormatedDataToDatabase = async (btnType: string) => {
    setIsLoading(true)
    
    let data = {
      codemp: auth?.EmpresaFilial.codemp,
      codfil: auth?.EmpresaFilial.codfil,
    }
    data = handleEmptyField(data, 'codpro', fields.filter((field) => field.id === 'produto')[0].value?.codpro, 'string')
    data = handleEmptyField(data, 'despro', fields.filter((field) => field.id === 'produto')[0].value?.despro, 'string')
    data = handleEmptyField(data, 'qtdeme', fields.filter((field) => field.id === 'qtdeme')[0].value?.qtdeme, 'number')
    data = handleEmptyField(data, 'dateme', fields.filter((field) => field.id === 'dateme')[0].value?.dateme, 'string')
    data = handleEmptyField(data, 'datprv', fields.filter((field) => field.id === 'datprv')[0].value?.datprv, 'string')
    data = handleEmptyField(data, 'codder', fields.filter((field) => field.id === 'derivacao')[0].value?.codder, 'string' )
    data = handleEmptyField(data, 'desder', fields.filter((field) => field.id === 'derivacao')[0].value?.desder, 'string' )
    data = handleEmptyField(data, 'coddep', fields.filter((field) => field.id === 'deposito')[0].value?.coddep, 'string' )
    data = handleEmptyField(data, 'desdep', fields.filter((field) => field.id === 'deposito')[0].value?.desdep, 'string' )
    data = handleEmptyField(data, 'codccu', fields.filter((field) => field.id === 'centrosCusto')[0].value?.codccu, 'string' )
    data = handleEmptyField(data, 'desccu', fields.filter((field) => field.id === 'centrosCusto')[0].value?.desccu, 'string' )
    
    const useCaseSuccess = {
      edit: 'editar requisição',
      save: 'criar requisição',
      remove: 'remover requisição',
      removeAll: `remover requisiç${selected.length > 1 ? 'ões': 'ão'}`,
      proccess: `processar requisiç${selected.length > 1 ? 'ões': 'ão'}`
    } as any

    const token = localStorage.getItem('token')
    let res = {
      statusCode: 400,
    } as any
    
    if(token){
      if(btnType === 'save')
        res = await createRequistionDraft(data, token)
      if(btnType === 'edit' && selected.length > 0)
        res = await updateRequistion(selected[0], data, token)
      if(btnType === 'remove' && selected.length > 0)
        res = await deleteRequistion(selected[0], token)
      if(btnType === 'removeAll' && selected.length > 0)
          res = await deleteAllRequistions(selected, token)
      if(btnType === 'proccess' && selected.length > 0)
        res = await processRequistions(selected, token)
      
      setIsLoading(false)
      if(res.statusCode === 200){
        setIsSnackbar({
          open: true,
          message: `Sucesso ao ${useCaseSuccess[btnType]}`,
          title: 'success'
        })
      } else {
        setIsSnackbar({
          open: true,
          message: `Erro ao ${useCaseSuccess[btnType]}`,
          title: 'error'
        })
      }
      refetch()
      setIsDialogFeedbackOpen({
        open: false,
        message: '',
        title: '',
        btnType,
      })
      setIsDrawerOpen({
        open: false,
        btnTitle: '',
        btnType: '',
      })
      setFields(fieldsInit)
      setSelected([])
    } else {
      setIsSnackbar({
        open: true,
        message: `Erro ao ${useCaseSuccess[btnType]}`,
        title: 'error'
      })
    }
  } 

  const handleRemoveAll = () => {
    handleDialogConfirmation('removeAll') 
  }

  const handleProcess = () => {
    handleDialogConfirmation('proccess') 
  }

  const handleRemove = (id:string) => {
    handleDialogConfirmation('remove')
  }
  
  const handleErro = (id:string) => {
    setIsDialogFeedbackOpen({
      open: true,
      message: selectedData.filter((data:any) => data.id === id)[0].msgerro,
      title: 'Erro Erp',
      btnType: 'erro'
    })
  }

  const handleEdit = (id:string) => {
    const item = selectedData.filter((data:any) => data.id === id )
    
    setFields(mapperToRequisition(item[0]))
    setIsDrawerOpen({open: true, btnTitle: 'Editar', btnType: 'edit'});
  }

  const handleCheckFields = () => {
    const requireds = fields.filter((field) => field.required)
    if(requireds.length !== requireds.filter((required) => required.value).length) {
      setIsSnackbar({open:true, message: 'Por favor, adicione os campos obrigatórios!', title: 'error'})
    } else { 
      handleDialogConfirmation(isDrawerOpen.btnType)
    }
  }
  
  const handleDialogConfirmation = (type: string) => {
    const useCaseFeedbackTitle = {
      save: 'Deseja criar requisição',
      edit: 'Deseja editar requisição',
      remove: 'Deseja remover requisição',
      removeAll: 'Deseja remover as requisições selecionadas',
      proccess: `Deseja processar a${selected.length > 1 ? 's requisições' : ' requisição'} `
    } as any

    setIsDialogFeedbackOpen({
      open: true,
      title: useCaseFeedbackTitle[type],
      message: '',
      btnType: type,
    })
  }

  const handleCheckError = () => {
    const itemSelected = selectedData.filter((data:any) => data.id === selected[0])
    if(itemSelected){
      const erro =  Object.entries(itemSelected[0]).find(([key, val]) => key.includes('erro'))
      if(erro && erro[1] !== null)
        return true
      else
        return false
    }
    return false
  }

  return (
    <div className={classes.root}>
      <RequisitionEmitHeader
        onClick={handleOpenDrawer}
      />
      <div className={classes.tableSection}>
        <Container className={classes.tableSectionContainer}>
          <EnhancedTable 
            title='Requisições'
            rowsPerPageOptions={[]}
            isClickable={true}
            data={selectedData?.map((data:any) => createData(data) )}
            count={count}
            orderBy={orderBy}
            setOrderBy={setOrderBy}
            headCells={headCells}
            isLoading={isFetching}
            selected={selected}
            setSelected={setSelected}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            buttonsSelected={[
              selected.length === 1 && 
              <Tooltip 
                title="Editar"
                onClick={() => handleEdit(`${selected[0]}`)}
                key='edit'
              >
                <IconButton aria-label="edit">
                  <EditIcon style={{color: customtheme.white}}/>
                </IconButton>
              </Tooltip>,
            (selected.length === 1 && handleCheckError()) && 
              <Tooltip
                title="Erro"
                onClick={() => handleErro(`${selected[0]}`)}
                key='erro'
              >
              <IconButton aria-label="erro">
                <ErrorIcon style={{color: customtheme.white}}/>
              </IconButton>
            </Tooltip>,
              <Tooltip title="Deletar" onClick={handleRemoveAll} key='deleteall'>
                <IconButton aria-label="delete">
                  <DeleteSweepIcon style={{color: customtheme.white}}/>
                </IconButton>
              </Tooltip>,
              <Tooltip title="Processar" onClick={handleProcess} key='process'>
                <IconButton aria-label="processar">
                  <SendIcon style={{color: customtheme.white}}/>
                </IconButton>
              </Tooltip>,
            ]}
            buttonsActions={['edit', 'erro', 'remove']}
            handleEdit={handleEdit}
            handleErro={handleErro}
            handleRemove={handleRemove}
          />
        </Container>
      </div>
      <RequisitionEmitDrawer
        fields={fields}
        setFields={setFields}
        handleCheckFields={handleCheckFields}
        handleCloseDrawer={handleCloseDrawer}
        handleCloseSnackbar={handleCloseSnackbar}
        handleOpenDialogSearch={handleOpenDialogSearch}
        isDrawerOpen={isDrawerOpen}
      />
      <DialogSearch
        title={dialogDataSearch?.title || ''}
        id={dialogDataSearch?.id || ''}
        keyItem={dialogDataSearch?.key || ''}
        keySubItem={dialogDataSearch?.keySub || ''}
        isDialogOpen={isDialogSearchOpen}
        isPrevSearch={dialogDataSearch?.isPrevSearch || false}
        prevSearch={dialogDataSearch?.prevSearch || []}
        prevDataSearch={dialogDataSearch?.prevDataSearch}
        handleCloseDialog={handleCloseDialogSearch}
        handleUpdateField={(data:any) => { handleUpdateField(data) }}
      />
      <RequisitionEmitDialog
        handleCloseErro={handleCloseErro}
        handleFormatedDataToDatabase={handleFormatedDataToDatabase}
        isDialogFeedbackOpen={isDialogFeedbackOpen}
        isLoading={isLoading}
      />
      <Snackbar open={isSnackbar.open} autoHideDuration={3000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={isSnackbar.title as any}>
          {isSnackbar.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default RequisitionEmit;