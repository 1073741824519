import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, List, ListItem, ListItemText, TextField, Tooltip, Typography, makeStyles } from "@material-ui/core";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useAuth } from "../../../common/hooks/AuthContext";
import { getErp } from "../../../app/services/erp/get-erp.api";
import { customtheme } from "../../../app/theme/theme";
import { Pagination } from "@material-ui/lab";
import { Close, Search } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
    dialogContent: {
      minWidth: 310,
    },
    listContainer: {
        height: 200,
        overflowY: 'auto',
    },
    listItem: {
        '&.Mui-selected': {
            backgroundColor:customtheme.blue[500],
            color: theme.palette.common.white,
        },      
    },
    listItemText: {
        fontWeight: 'bold',
    },
    backgroundColor: {
      background: customtheme.blue[500],
      color: customtheme.white,
      '&:hover': {
        background: customtheme.blue[50],
        color: customtheme.blue[500],
      },
    },
    backBackgroundColor: {
      background: customtheme.gray[200],
      color: customtheme.blue[500],
      marginRight: 20,
      '&:hover': {
        background: customtheme.blue[50],
        color: customtheme.blue[500],
      },
    },
}));

interface DialogSearchProps {
    title: string;
    keyItem: string;
    keySubItem: string;
    id: string;
    isDialogOpen: boolean;
    isPrevSearch: boolean;
    prevSearch: any;
    prevDataSearch: any;
    handleCloseDialog: () => void;
    handleUpdateField: (data: any) => void;
}

export default function DialogSearch(props: DialogSearchProps) {
    const classes = useStyles();
    const { auth } = useAuth();
    const { 
        title, id, keyItem, isDialogOpen,
        isPrevSearch, prevSearch, keySubItem,
        prevDataSearch,
        handleCloseDialog, handleUpdateField 
    } = props;
      
    const [filterText, setFilterText] = useState('');
    const [filterTextSend, setFilterTextSend] = useState('');
    const [filteredItems, setFilteredItems] = useState<Array<any>>([]);
    const [selectedItem, setSelectedItem] = useState<any | null>(null);
    const [page, setPage] = useState(0);
    const [totalItens, setTotalItens] = useState(0);
    const [isPaginated, setIsPaginated] = useState(false);
    const [refreshData, setRefreshData] = useState(false);
    const sizeList = 10;

    const { isFetching, refetch } = useQuery(['dialogSearch'], async () => {
        const empty = {} as any
        empty[keyItem] = 'Nenhum item'
        if(!isPrevSearch){
            const token = localStorage.getItem('token')
            if(auth?.empresa_filial_id && token && auth?.EmpresaFilial && id){
                const res = await getErp({
                    route: id, 
                    idSystem: 'PORTAL', 
                    qtd: 1000, 
                    quantidade: sizeList,
                    pagina: page + 1,
                    filtro: filterTextSend,
                    ...prevDataSearch,
                } ,token)
                if(res){
                    const resData = res.data === undefined ? res : res.data
                    const resTotal = res.total === undefined ? res : res.total
                    res.total !== undefined ? setIsPaginated(true) : setIsPaginated(false);
                    const updated = [...resData]
                    updated.unshift(empty)
                    setFilteredItems(updated)
                    setTotalItens(resTotal)
                    return updated
                }
            }
        } else{
            const updated = [...prevSearch]
            updated.unshift(empty)
            setFilteredItems(updated)
            setTotalItens(updated.length)
            return updated
        }
        return []
    }, {
        refetchOnWindowFocus: false,
    })

    useEffect(() => {
        if(isDialogOpen)
            refetch();
    }, [isDialogOpen, refetch]);

    useEffect(() => {
        if(isPaginated && refreshData){
            refetch();
            setRefreshData(false);
        }
    }, [refreshData, isPaginated, refetch]);

    const handleItemClick = (item: any) => {
        setSelectedItem(item);
      };
  
    const handleFilterChange = (event: any) => {
        setFilterText(event.target.value);
    };

    const handleFilterRefetch = () => {
        setPage(0);
        setFilterTextSend(filterText);
        if (isPrevSearch) {
            let updated = []
            if (filterText === '') {
                const prevUpdated = [...prevSearch]
                const empty = {} as any
                empty[keyItem] = 'Nenhum item'
                prevUpdated.unshift(empty)
                updated = prevUpdated;
            } else {
                updated = filteredItems.filter((item) => item[keySubItem]?.includes(filterText))
            }
            setFilteredItems(updated)
            setTotalItens(updated.length)
        } else {
            setRefreshData(true);
        } 
    };

    const handleFilterClose = () => {
        setPage(0);
        setFilterText('');
        setFilterTextSend('');
        setRefreshData(true);
    };

    const handleChangePagination = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value-1);
        setRefreshData(true);
    };

    const handleCloseUseStates = () => {
        setFilteredItems([])
        setFilterText('')
        setFilterTextSend('')
        setSelectedItem(null)
    }

    return (
        <Dialog open={isDialogOpen} onClose={() => {
            handleCloseUseStates()
            handleCloseDialog()
        }}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent className={classes.dialogContent}>
            <Box display="flex" alignItems="center" justifyContent="center">
                <TextField
                    label="Filtro"
                    value={filterText}
                    onChange={handleFilterChange}
                    disabled={isFetching}
                    fullWidth
                />
                <IconButton onClick={handleFilterRefetch} disabled={isFetching}>
                    <Tooltip title="Buscar">
                        <Search />
                    </Tooltip>
                </IconButton>
                <IconButton onClick={handleFilterClose} disabled={isFetching}>
                    <Tooltip title="Limpar">
                        <Close />
                    </Tooltip>
                </IconButton>
            </Box>
                
                 <div className={classes.listContainer}>
                    {
                        isFetching ? (
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                               <CircularProgress style={{color: customtheme.blue[500]}} />
                            </div>
                        ) : (
                            <>
                                {
                                    filteredItems?.length === 0 ? (
                                        <Typography variant="body1" style={{ textAlign: 'center', marginTop: '20px' }}>
                                            Sem itens
                                        </Typography>
                                    ) : (
                                        <List>
                                            {
                                                filteredItems?.slice(isPaginated ? 0 : page*sizeList, isPaginated ? sizeList : page*sizeList+sizeList)
                                                .map((item:any, index:number) => (
                                                    <ListItem
                                                        key={index}
                                                        button
                                                        selected={selectedItem?.[keyItem] === item[keyItem]}
                                                        onClick={() => handleItemClick(item)}
                                                        className={selectedItem?.[keyItem] === item[keyItem] ? classes.listItem : ''}
                                                    >
                                                        <ListItemText 
                                                            primary={                                                               
                                                                item[keySubItem] ? 
                                                                    `${item[keyItem]} - ${item[keySubItem]}` :
                                                                    `${item[keyItem]}`
                                                                }
                                                            primaryTypographyProps={{
                                                                className:
                                                                selectedItem?.[keyItem] === item[keyItem] ? classes.listItemText : '',
                                                            }}
                                                        />
                                                    </ListItem>
                                                ))
                                            }
                                        </List>
                                    )
                                }
                            </>
                        )
                    }
                </div>
                <Pagination count={Math.ceil((isPaginated ? totalItens : filteredItems.length) / sizeList)} page={page+1}  onChange={handleChangePagination} />
            </DialogContent>
            <DialogActions style={{flexWrap: 'wrap'}}>
                {
                    selectedItem && (
                        <div style={{}}>
                            <Typography variant="body1">
                                Item selecionado: {selectedItem?.[keyItem]}
                            </Typography>
                        </div>
                    )
                }
                <div>
                    <Button 
                        onClick={() => {
                            handleCloseUseStates()
                            handleCloseDialog()
                        }} 
                        className={classes.backBackgroundColor}
                    >
                        Cancelar
                    </Button>
                    <Button 
                        onClick={() => {
                            handleCloseUseStates()
                            handleUpdateField(selectedItem)}
                        } 
                        className={classes.backgroundColor} 
                        variant="contained"
                        disabled={isFetching || !selectedItem}
                    >
                        Confirmar
                    </Button>
                </div>
            </DialogActions>
        </Dialog>
    )
}