import { Container, Grid, List, ListItem, ListItemText, Paper, TextField, Typography, makeStyles } from "@material-ui/core";
import { Dispatch, SetStateAction, useState } from "react";
import { getLicencesByCompany } from "../../../app/services/licences/get-licences.api";
import { useAuth } from "../../hooks/AuthContext";
import { useQuery } from "@tanstack/react-query";
import { Skeleton } from "@material-ui/lab";
import { Modules } from "../../enum/modules.enum";
import { customtheme } from "../../../app/theme/theme";

const useStyles = makeStyles((theme) => ({
    container: {
      padding: theme.spacing(4),
      backgroundColor: customtheme.gray[200],
      height: '100%',
    },
    paper: {
      padding: theme.spacing(3),
    },
    searchInput: {
      marginBottom: theme.spacing(2),
    },
    list: {
      maxHeight: '300px',
      overflow: 'auto',
    },
    listItem: {
      '&.Mui-selected, &:hover': {
        backgroundColor: customtheme.blue[500],
        color: customtheme.white,
      },
      '&.Mui-selected:hover': {
        background: customtheme.blue[50],
        color: customtheme.blue[500],
      },
    },
    loadingIcon: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: theme.spacing(2),
    },
    backgroundColor: {
      background: customtheme.blue[500],
      color: customtheme.white,
    },
}));

export interface SelectedProps {
  id: string;
  name: string;
  tipo: Modules
}

interface SelectStepProps {
    selected: SelectedProps | null;
    setSelected: Dispatch<SetStateAction<SelectedProps | null>>
    textEmpty: string; 
    textSearch: string;
    typeSearch: string;
    textSelected: string;
}

export const SelectStep: React.FC<SelectStepProps> = ({
  selected, textEmpty, textSearch, typeSearch, textSelected, setSelected
}) => {
    const classes = useStyles();
    const [searchQuery, setSearchQuery] = useState('');
    const [isLoading, setIsLoading] = useState(false)
    const { auth } = useAuth()
  
    const { data } = useQuery(['selecteSetp'], async (): Promise<SelectedProps[]> => {
      const useCase = {
        licence: getLicencesByCompany,
      } as any
      
      const token = localStorage.getItem('token');
      if(!selected)
        setIsLoading(true)

      if(auth?.empresa_filial_id && token){
        const res = await useCase[typeSearch](auth.empresa_filial_id, token)
        setIsLoading(false);
        return res.map((r:any) => ({id: r.id, name: r.descricao, tipo: r.tipo}))
      }
      
      setIsLoading(false);
      return []
    }, {
      keepPreviousData: true,
    })

    const handleDataSelection = (data:any) => {
      if(setSelected)
        setSelected(data);
    };
  
    const handleSearch = (event:any) => {
      setSearchQuery(event.target.value);
    };
  
    const filteredData = data?.filter((data) =>
      data.name.toLowerCase().includes(searchQuery.toLowerCase())
    ) || [];
  
    return (
      <Container maxWidth={false} className={classes.container}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Paper className={classes.paper}>
              <TextField
                label={textSearch}
                value={searchQuery}
                onChange={handleSearch}
                className={classes.searchInput}
              />
              {                   
                  isLoading ? (
                    <div style={{ display: 'flex', height: 300, flexDirection: 'column' }}>
                      {
                        Array.from({length: 3}).map((_, index) => (
                          <Skeleton key={index} animation="wave" height={30} width="100%" style={{ marginBottom: 8 }} /> 
                        ))
                      }
                    </div>
                  ):
                  <List component="nav" className={classes.list}>
                    {
                        filteredData.length > 0 ?
                          filteredData.map((data) => (
                          <ListItem
                              key={data.id}
                              button
                              selected={selected?.id === data.id}
                              onClick={() => handleDataSelection(data)}
                              className={classes.listItem}
                              // style={{
                              //   backgroundColor: selected?.id === data.id ? customtheme.blue[500] : 'inherit',
                              //   color: selected?.id === data.id ? customtheme.white : 'inherit',
                              // }}
                          >
                              <ListItemText primary={data.name} />
                          </ListItem>
                          )) :
                          <Typography variant="h6">
                            {textEmpty}
                          </Typography>
                    }
                  </List>
                }
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Paper className={classes.paper}>
              {selected && (
                <Typography variant="h6">
                  {textSelected} {selected.name}
                </Typography>
              )}
            </Paper>
          </Grid>
        </Grid>
      </Container>
    );
};
