import {
  AppBar,
  Button,
  Container,
  Tab,
  Tabs,
  useMediaQuery,
} from '@material-ui/core';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import EnhancedTable, { HeadCell } from '../../common/components/EnhancedTable';
import { useQuery } from '@tanstack/react-query';
import { useAuth } from '../../common/hooks/AuthContext';
import { getUsersModuleByCompanyParams } from '../../app/services/modules/get-users-module.api';
import { Modules } from '../../common/enum/modules.enum';
import { customtheme } from '../../app/theme/theme';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: customtheme.gray[200],
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  topBar: {
    backgroundColor: customtheme.white,
    padding: theme.spacing(2),
  },
  topBarContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  topBarButtons: {
    '& > *': {
      marginLeft: theme.spacing(1),
    },
  },
  content: {
    // position: 'relative',
    // flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  contentBackground: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: '50%',
    backgroundColor: customtheme.white,
  },
  card: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  tableSection: {
    padding: theme.spacing(4),
    flex: 1,
    display: 'flex',
  },
  tableSectionContainer: {
    padding: 0, 
    flex: 1,
    display: 'flex', 
    flexDirection: 'column',
    maxWidth: '100%',
  },
  indicator: {
    backgroundColor: customtheme.blue[500],
  },
  selectedTab: {
    color: customtheme.blue[500],
  },
  tabHover: {
    '&:hover': {
      color: customtheme.blue[500],
    },
  },
  tabHoverSelected: {
    '&:hover': {
      color: customtheme.blue[50],
    },
  },
  appbar: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    background: customtheme.gray[400],
    color: customtheme.black,
  },  
  scrollableTabs: {
    overflowX: 'auto',
  },
  backgroundColor: {
    background: customtheme.blue[500],
    color: customtheme.white,
    '&:hover': {
      background: customtheme.blue[50],
      color: customtheme.blue[500],
    },
  },
}));

interface BodyCell {
  id: string;
  nomrep: string;
  codrep: number;
  intnet: string;
}

const Licences = () => {
  const classes = useStyles();

  const windowHeight = window.innerHeight;
  const headerHeight = 400; // Altura do cabeçalho em pixels
  const footerHeight = 0; // Altura do rodapé em pixels
  const itemHeight = 80; // Altura média de cada item em pixels
  const availableHeight = windowHeight - headerHeight - footerHeight;
  const itemsPerPage = Math.floor((availableHeight) / itemHeight)

  const [selectedTab, setSelectedTab] = useState(0);
  const [tabs] = useState([
    {index: 0, label: 'Dashboard', key: Modules.DASHBOARD},
    {index: 1, label: 'Requisição', key: Modules.REQUISICAO},
    {index: 2, label: 'Aprovação Requisição', key: Modules.REQUISICAOAPROVACAO},
    {index: 3, label: 'Aprovação Ordem de Compra', key: Modules.APROVACAO},
    {index: 4, label: 'Manutenção', key: Modules.MANUTENCAO},
    {index: 5, label: 'Força Vendas', key: Modules.FORCAVENDA},
  ]);
  const [selectedData, setSelectedData] = useState<any>([]);
  const [orderBy, setOrderBy] = useState<string>('nomrep');
  const [selected, setSelected] = useState<string[]>([]);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(itemsPerPage);
  const navigate = useNavigate();
  const { auth } = useAuth() 
  const theme = useTheme();
  const isSmScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleTabChange = (event:any, newValue:number) => {
    setSelectedTab(newValue);
    setOrderBy('nomrep')
    setSelected([])
  };

  const handleAddNewItem = () => {
    // Lógica para adicionar um novo item na tabela
    navigate('/licencas/checkout');
  };

  const { isFetching, refetch } = useQuery(['licences'], async () => {
    const token = localStorage.getItem('token')
    if(auth?.empresa_filial_id && token){
      const res = await getUsersModuleByCompanyParams(auth.empresa_filial_id, token, {
        take: rowsPerPage,
        skip: page * rowsPerPage,
        tipo: tabs[selectedTab].key
      })
      if(res){
        setSelectedData(res.results)
        setCount(res.total)
      }
    }
    return []
  })

  useEffect(() => {
    refetch();
  }, [selectedTab, refetch]);
  
  const headCells: HeadCell[] = [
    { id: 'nomrep', numeric: false, disablePadding: true, label: 'Nome' },
    { id: 'codrep', numeric: true, disablePadding: false, label: 'Código' },
    { id: 'intnet', numeric: false, disablePadding: true, label: 'Email' },
  ];

  function createData(props:any): BodyCell {
    const {id, nomrep, codrep, intnet} = props
    return { id, nomrep, codrep, intnet };
  }

  const renderTable = () => {
    switch (selectedTab) {
      case 0:
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
        return <EnhancedTable 
          title='Usuários'
          rowsPerPageOptions={[]}
          isClickable={false}
          data={selectedData?.map((data:any) => createData(data))}
          count={count}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          headCells={headCells}
          isLoading={isFetching}
          selected={selected}
          setSelected={setSelected}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          buttonsSelected={[]}
          buttonsActions={[]}
        />;
      default:
        return <EnhancedTable 
          title='Usuários'
          rowsPerPageOptions={[]}
          isClickable={false}
          data={[]}
          count={0}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          headCells={headCells}
          isLoading={false}
          selected={selected}
          setSelected={setSelected}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          buttonsSelected={[]}
          buttonsActions={[]}
        />;
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.tableSection}>
        <Container className={classes.tableSectionContainer}>
          <AppBar position="static" className={classes.appbar}>
            <Tabs 
              value={selectedTab} 
              onChange={handleTabChange} 
              classes={{ indicator: classes.indicator }}
              variant={isSmScreen ? 'scrollable' : 'fullWidth'}
              scrollButtons={isSmScreen ? 'auto' : 'off'}
              className={isSmScreen ? classes.scrollableTabs : ''}
            >
              {
                tabs.map(tab => (
                  <Tab 
                    key={tab.index} 
                    disabled={!auth?.modulos.includes(tab.key)}
                    classes={{
                      selected: classes.selectedTab,
                      wrapper: selectedTab === tab.index? classes.tabHoverSelected: classes.tabHover
                    }} 
                    tabIndex={tab.index} 
                    label={tab.label}
                  />
                ))
              }
            </Tabs>
            <Button variant="contained" className={classes.backgroundColor} onClick={handleAddNewItem}>
              Mover usuários
            </Button>
          </AppBar>
          {renderTable()}
        </Container>
      </div>
    </div>
  );
};

export default Licences;