export enum Modules {
  DASHBOARD = 'DASHBOARD',
  REQUISICAO = 'REQUISICAO',
  REQUISICAOAPROVACAO = 'REQUISICAOAPROVACAO',
  APROVACAO = 'APROVACAO',
  FORCAVENDA = 'FORCAVENDA',
  LICENCA = 'LICENCA',
  MANUTENCAO = 'MANUTENCAO',
}


export enum ColorModules {
  DASHBOARD = 'red', 
  REQUISICAO = 'blue', 
  REQUISICAOAPROVACAO = 'green', 
  APROVACAO = 'orange', 
  FORCAVENDA = 'purple', 
  LICENCA = 'teal', 
  MANUTENCAO = 'brown', 
 };