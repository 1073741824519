import { Button, Drawer, Grid, IconButton, TextField, Typography, makeStyles } from "@material-ui/core";
import { handleReturnValue } from '../../../common/functions/returnValue';
import SearchIcon from '@material-ui/icons/Search';
import { customtheme } from "../../../app/theme/theme";

const useStyles = makeStyles((theme) => ({
    drawer: {
        width: 400,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: theme.spacing(2),
        borderBottom: `1px solid ${theme.palette.divider}`,
        background: customtheme.gray[200],
    },
    drawerContent: {
        overflowY: 'auto',
        padding: theme.spacing(2), 
        height: '100%',
    },
    drawerFooter: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(2),
        borderTop: `1px solid ${theme.palette.divider}`,
        background: customtheme.gray[200],
    },
    textField: {
        minWidth: 140,
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        '& .MuiInputBase-input': {
          color: customtheme.black,
        },
        '& .MuiInput-underline:hover:before': {
          borderBottomColor:customtheme.blue[500],
        },
    },
    searchIcon: {
        marginRight: theme.spacing(1),
        color:customtheme.blue[500],
    },
    loadingIcon: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        marginTop: theme.spacing(2),
    },
    backgroundColor: {
      background: customtheme.blue[500],
      color: customtheme.white,
      '&:hover': {
        background: customtheme.blue[50],
        color: customtheme.blue[500],
      },
    },
    backBackgroundColor: {
        background: customtheme.gray[200],
        color: customtheme.blue[500],
        '&:hover': {
        background: customtheme.blue[50],
        color: customtheme.blue[500],
        },
    },
}))

interface DialogSearchData {
    id: string;
    title: string;
    key: string;
    keySub: string;
    isPrevSearch?: boolean;
    prevSearch?: any;
    prevDataSearch: any;
}

interface CollectionPointDrawerProps {
    handleCloseDrawer: () => void;
    handleOpenDialogSearch: (data: DialogSearchData) => void;
    handleCheckFields: () => void;
    handleCloseSnackbar: ( event?: React.SyntheticEvent, reason?: string ) => void;
    isDrawerOpen: { open: boolean, btnTitle: string, btnType: string };
    fields: any[];
    setFields: ( array: any[] ) => void;
    checkPrevCompanyValue: { exists: boolean, value: any};
    checkPrevEquipamentValue: { exists: boolean, value: any};
}

const CollectionPointDrawer = (props: CollectionPointDrawerProps) => {
    const classes = useStyles()
    const { 
        isDrawerOpen, fields, checkPrevCompanyValue, checkPrevEquipamentValue, 
        setFields, handleCheckFields, handleCloseDrawer, handleOpenDialogSearch
    } = props;

    return (
        <>
            <Drawer
                anchor="right"
                open={isDrawerOpen.open}
                onClose={handleCloseDrawer}
                classes={{ paper: classes.drawer }}
            >
                <div className={classes.drawerHeader}>
                <Typography variant="h6">Adicionar Item</Typography>
                </div>
                <div className={classes.drawerContent}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            label="Empresa"
                            onClick={() => handleOpenDialogSearch({
                            id: 'dados',
                            title: 'Empresa',
                            keySub: 'nomemp',
                            key: 'codemp',
                            prevDataSearch: {},
                            })}
                            value={
                            handleReturnValue('dados', fields)                 
                            }
                            fullWidth
                            className={classes.textField}
                            InputProps={{
                            endAdornment: (
                                <IconButton
                                className={classes.searchIcon}
                                onClick={() => handleOpenDialogSearch({
                                    id: 'dados',
                                    title: 'Empresa',
                                    keySub: 'nomemp',
                                    key: 'codemp',
                                    prevDataSearch: {},
                                    })}>
                                <SearchIcon />
                                </IconButton>
                            ),
                            }}
                        />
                        <TextField
                            label="Equipamento"
                            onClick={
                            checkPrevCompanyValue.exists ? 
                                () => handleOpenDialogSearch({
                                id: 'equip',
                                title: 'Equipamentos',
                                keySub: 'deseqp',
                                key: 'codeqp',
                                prevDataSearch: {
                                    codemp: checkPrevCompanyValue.value,
                                }
                                })
                            : () => {}
                            }
                            value={
                            checkPrevCompanyValue.exists ?
                                handleReturnValue('equip', fields)
                            : 'Selecione uma empresa primeiro'
                            }
                            fullWidth
                            className={classes.textField}
                            disabled={
                            checkPrevCompanyValue.exists ? 
                                false 
                            : true
                            }
                            InputProps={{
                            endAdornment: (
                                <IconButton
                                className={classes.searchIcon}
                                onClick={
                                    checkPrevCompanyValue.exists ?
                                    () => handleOpenDialogSearch({
                                        id: 'equip',
                                        title: 'Equipamentos',
                                        keySub: 'deseqp',
                                        key: 'codeqp',
                                        prevDataSearch: {
                                        codemp: checkPrevCompanyValue.value,
                                        }
                                    })
                                    : () => {}
                                }
                                disabled={
                                    checkPrevCompanyValue.exists ? 
                                    false 
                                    : true
                                }
                                >
                                <SearchIcon />
                                </IconButton>
                            ),
                            }}
                        />
                        <TextField
                            label="Coleta"
                            onClick={
                            checkPrevCompanyValue.exists ? 
                                checkPrevEquipamentValue.exists ?
                                () => handleOpenDialogSearch({
                                    id: 'coleta',
                                    title: 'Coleta',
                                    keySub: 'desccm',
                                    key: 'codccm',
                                    prevDataSearch: {
                                    codemp: checkPrevCompanyValue.value,
                                    codeqp: checkPrevEquipamentValue.value,
                                    }
                                })
                                : () => {}
                            : () => {}
                            }
                            value={
                            checkPrevCompanyValue.exists ?
                                checkPrevEquipamentValue.exists ?
                                handleReturnValue('coleta', fields)
                                : 'Selecione um equipamento primeiro'
                            : 'Selecione uma empresa primeiro'
                            }
                            fullWidth
                            className={classes.textField}
                            disabled={
                            checkPrevCompanyValue.exists ? 
                                checkPrevEquipamentValue.exists ?
                                false
                                : true 
                            : true
                            }
                            InputProps={{
                            endAdornment: (
                                <IconButton
                                className={classes.searchIcon}
                                onClick={
                                    checkPrevCompanyValue.exists ? 
                                    checkPrevEquipamentValue.exists ?
                                        () => handleOpenDialogSearch({
                                        id: 'coleta',
                                        title: 'Coleta',
                                        keySub: 'desccm',
                                        key: 'codccm',
                                        prevDataSearch: {
                                            codemp: checkPrevCompanyValue.value,
                                            codeqp: checkPrevEquipamentValue.value,
                                        }
                                        })
                                    : () => {}
                                    : () => {}
                                }
                                disabled={
                                    checkPrevCompanyValue.exists ? 
                                    checkPrevEquipamentValue.exists ?
                                        false
                                        : true 
                                    : true
                                }
                                >
                                <SearchIcon />
                                </IconButton>
                            ),
                            }}
                        />
                        <TextField
                            id="valorcoleta"
                            label="Valor da coleta"
                            type='number'
                            value={fields.filter((field) => field.id === 'valorcoleta')[0].value?.vlrcol}
                            onChange={(event) => {
                                const update = [...fields]
                                update[update.findIndex((up) => up.id === 'valorcoleta')].value = {
                                    vlrcol: parseFloat(event.target.value),
                                };
                                setFields(update);
                            }}
                            className={classes.textField}
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        {/* <NumberInput
                            label='Valor da coleta'
                            onValueChange={
                            (value) => {
                                const update = [...fields]
                                update[update.findIndex((up) => up.id === 'valorcoleta')].value = {
                                vlrcol: parseFloat(value),
                                };
                                setFields(update);
                            }
                            }
                            value={fields.filter((field) => field.id === 'valorcoleta')[0].value?.vlrcol || 0}
                        /> */}
                        <TextField
                            id="date"
                            label="Data Coleta"
                            type='datetime-local'
                            value={fields.filter((field) => field.id === 'datacoleta')[0].value?.datcol}
                            onChange={(event) => {
                                const update = [...fields]
                                update[update.findIndex((up) => up.id === 'datacoleta')].value = {
                                    datcol: event.currentTarget.value
                                }
                                setFields(update)
                            }}
                            className={classes.textField}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                </Grid>
                </div>
                <div className={classes.drawerFooter}>
                <Button
                    onClick={handleCloseDrawer}
                    className={classes.backBackgroundColor}
                    style={{marginRight: 20}}
                >
                    Cancelar
                </Button>
                <Button 
                    onClick={() => {
                    handleCheckFields()
                    }} 
                    className={classes.backgroundColor}
                >
                    {isDrawerOpen.btnTitle}
                </Button>
                </div>
            </Drawer>  
        </>
    )
}

export default CollectionPointDrawer;