export const allMonths = [
    'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
    'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'
];

export function getLastSixMonth () {
    const currentDate = new Date();

    const currentMonthIndex = currentDate.getMonth(); // Índice do mês atual
    const startMonthIndex = currentMonthIndex - 5 < 0 ? 12 + (currentMonthIndex - 5) : currentMonthIndex - 5;

    const sixMonthsBefore: string[] = [];
    for (let i = 0; i < 6; i++) {
        const monthIndex = (startMonthIndex + i) % 12;
        sixMonthsBefore.push(allMonths[monthIndex]);
    }

    const firstMonth = allMonths.findIndex((month) => month === sixMonthsBefore[0])
    const firstYear = currentDate.getFullYear() - ( currentMonthIndex < 5 ? 1 : 0)
    
    let dateString = `${firstMonth}/01/${firstYear}`
    if (firstMonth === 0) dateString = `01/01/${firstYear}`
    if (firstMonth > 0 && firstMonth < 10) dateString = `0${firstMonth}/01/${firstYear}`

    return {
        sixMonthsBefore,
        date: dateString
    };
}