import { makeStyles } from '@material-ui/core/styles';
import { useQuery } from '@tanstack/react-query';
import { Box, Container, IconButton, Tooltip, Typography } from '@material-ui/core';
import { getErp } from '../../app/services/erp/get-erp.api';
import { customtheme } from '../../app/theme/theme';
import EnhancedTable, { HeadCell } from '../../common/components/EnhancedTable';
import { useState } from 'react';
import { formatedStringDateErp, formatedYearDateErp } from '../../common/functions/getDate';
import SendIcon from '@material-ui/icons/Send';
import { mapperToMaintenancePoint } from '../../app/mappers/maintenance/maintenance-point.mapper';
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: customtheme.gray[200],
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
      },
      topBar: {
        backgroundColor: customtheme.gray[200],
        padding: theme.spacing(2),
        paddingTop: theme.spacing(4),
      },
      tableSection: {
        padding: theme.spacing(4),
        flex: 1,
        display: 'flex',
      },
      tableSectionContainer: {
        padding: 0, 
        flex: 1,
        display: 'flex', 
        flexDirection: 'column',
        maxWidth: '100%',
      },
      content: {
        // position: 'relative',
        // flex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      situation: {
        width: '32px',
        height: '32px',
        borderRadius: '50%',
      }
}));

export const PainelMaintenance = () => {
    const navigate = useNavigate();
    const classes = useStyles()
    const windowHeight = window.innerHeight;
    const headerHeight = 500; // Altura do cabeçalho em pixels
    const footerHeight = 48; // Altura do rodapé em pixels
    const itemHeight = 80; // Altura média de cada item em pixels
    const availableHeight = windowHeight - headerHeight - footerHeight;
    const itemsPerPage = Math.floor((availableHeight) / itemHeight)
  
    const [selectedData, setSelectedData] = useState<any>([]);
    const [orderBy, setOrderBy] = useState<string>('nomrep');
    const [selected, setSelected] = useState<string[]>([]);
    const [page, setPage] = useState(0);
    const [count, setCount] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(itemsPerPage > 5 ? itemsPerPage : 5);
  
    const { isFetching } = useQuery(['panelMaintenance'], async () => {
        const token = localStorage.getItem('token')
        if(token){
            const res = await getErp({route: 'panelMaintenance', idSystem: 'PORTAL', page: 1, numitem: 1000}, token)
            if(res){
                setSelectedData(res.map((data:any) => ({...data, id: `${data.nummnt}`})))
                setCount(res.length)
            }
        }
        return []
      }, {
        refetchOnWindowFocus: false,
    })

    const headCells: HeadCell[] = [
        { id: 'sit', numeric: false, disablePadding: true, label: 'Indicador' },
        { id: 'codemp', numeric: true, disablePadding: false, label: 'Código Empresa' },
        { id: 'nomemp', numeric: true, disablePadding: false, label: 'Empresa' },
        { id: 'codeqp', numeric: false, disablePadding: true, label: 'Equipamento' },
        { id: 'deseqp', numeric: false, disablePadding: true, label: 'Descrição Equipamento' },
        { id: 'nummnt', numeric: true, disablePadding: false, label: 'Manutenção' },
        { id: 'codser', numeric: false, disablePadding: true, label: 'Serviço' },
        { id: 'desser', numeric: false, disablePadding: true, label: 'Descrição Serviço' },
        { id: 'sitemt', numeric: false, disablePadding: true, label: 'Situação' },
        { id: 'tipmnt', numeric: false, disablePadding: true, label: 'Tipo Manutenção' },
        { id: 'datger', numeric: false, disablePadding: true, label: 'Data Geração' },
        { id: 'datprg', numeric: false, disablePadding: true, label: 'Data Programação' },
        { id: 'datrei', numeric: false, disablePadding: true, label: 'Data Início' },
      ];
    
      const createData = (props:any) => {
        const {id, codemp, nomemp, codeqp, deseqp, nummnt, codser, desser, sitemt, tipmnt, datger, datprg, datrei} = props

        let sit = 'yellow'
        const actualDate = new Date()
        actualDate.setHours(0,0,0,0);
        let prgDate = new Date(formatedStringDateErp(datprg))
        if ((prgDate > actualDate && sitemt === 'Emitida') || sitemt === 'Iniciada') {
            sit = 'green';
        } else if (prgDate < actualDate && sitemt === 'Emitida') {
            sit = 'red';
        }

        return { id, sit, codemp, nomemp, codeqp, deseqp, nummnt, codser, desser, sitemt, tipmnt, datger, datprg, datrei, ...props };
      }

    const handleSend = (id:string) => {
      const item = selectedData.filter((data:any) => data.id === id )
      
      const fimoop = item[0].sitemt === 'Iniciada' ? 'S' : 'N'
      const datapt = item[0].sitemt === 'Iniciada' ? formatedYearDateErp(item[0].datrei) : formatedYearDateErp(item[0].datger)
      const horapt = '00:00'
      const itemMapper = mapperToMaintenancePoint({...item[0], fimoop, datapt, horapt})
      navigate('../manutencao/apontar', { replace: true, state: itemMapper })
    }
  
    return (
        <div className={classes.root}>
            <div className={classes.topBar}></div>
            <div className={classes.content}></div>
            <div className={classes.tableSection}>
                <Container className={classes.tableSectionContainer}>
                    <EnhancedTable 
                        title='Manutenções'
                        rowsPerPageOptions={[]}
                        isClickable
                        data={selectedData?.map((data:any) => createData(data) )}
                        count={count}
                        orderBy={orderBy}
                        setOrderBy={setOrderBy}
                        headCells={headCells}
                        isLoading={isFetching}
                        selected={selected}
                        setSelected={setSelected}
                        page={page}
                        setPage={setPage}
                        rowsPerPage={rowsPerPage}
                        setRowsPerPage={setRowsPerPage}
                        buttonsSelected={[
                          selected.length === 1 && 
                          <Tooltip
                            title="Abrir"
                            onClick={() => handleSend(`${selected[0]}`)}
                            key='send'
                          >
                            <IconButton aria-label="abrir">
                              <SendIcon style={{color: customtheme.white}}/>
                            </IconButton>
                          </Tooltip>
                        ]}
                        buttonsActions={['send']}
                        handleSend={handleSend}
                        isBoldHeader
                    />
                    <Box display={'flex'} padding={4} flexDirection={'column'}>
                        <Typography style={{ fontWeight: 'bold' }}>Legenda</Typography>
                      <Box display={'flex'} marginTop={2} alignItems={'center'}>
                        <Box className={classes.situation} bgcolor={'green'}/>
                        <Box display={'flex'} flexDirection={'column'} marginLeft={2}>
                          <Typography>Situação = Emitida, Data Programação {`>`} Data atual</Typography>
                          <Typography>Situação = Iniciada</Typography>
                        </Box>
                      </Box>
                      <Box display={'flex'} marginTop={2} alignItems={'center'}>
                        <Box className={classes.situation} bgcolor={'yellow'}/>
                        <Box marginLeft={2}>
                          <Typography>Situação = Emitida, Data Programação {`=`} Data atual</Typography>
                        </Box>
                      </Box>
                      <Box display={'flex'} marginTop={2} alignItems={'center'}>
                        <Box className={classes.situation} bgcolor={'red'}/>
                        <Box marginLeft={2}>
                          <Typography>Situação = Emitida, Data Programação {`<`} Data atual</Typography>
                        </Box>
                      </Box>
                    </Box>
                </Container>
            </div>
        </div>
    );
};
