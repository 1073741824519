import { Box, Typography } from "@material-ui/core";

interface FooterProps {
    isMobile: boolean;
}

export const Footer: React.FC<FooterProps> = ({ isMobile }:FooterProps) => {
    
    return (
        <Box display={'flex'} flexDirection={'column'} alignItems={'end'} padding={2}>
            <Typography variant="body1" color="textSecondary">
                © 2024 Quattuor Consultoria
            </Typography> 
            <Typography variant="body1"  color="textSecondary">
                Todos os direitos reservados
            </Typography> 
        </Box>
    );
};