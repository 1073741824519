import axios from "axios";

export async function getTeamAccess(token:string): Promise<any> {
    try {
      const response = await axios.get(process.env.REACT_APP_BASE_URL+`/logs/dashboard`, 
        {
          headers: {
              Authorization: 'Bearer ' + token
          }
        });
  
      return response.data; // Supondo que os usuarios sejam retornados pelo backend
    } catch (error) {
      throw new Error('Falha na autenticação');
    }
  }