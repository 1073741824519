import { IconButton, Theme, Toolbar, Tooltip, Typography, createStyles, makeStyles } from "@material-ui/core";
import FilterListIcon from '@material-ui/icons/FilterList';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import clsx from "clsx";
import { ReactNode } from "react";
import { customtheme } from "../../../app/theme/theme";

const useToolbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
      justifyContent: 'space-between',
    },
    highlight: {
      color: customtheme.white,
      backgroundColor: customtheme.blue[100],
    },
    title: {
      flex: '1 1 100%',
    },
  }),
);


interface EnhancedTableToolbarProps {
  numSelected: number;
  title: string;
  buttonsSelected: Array<ReactNode>;
  isFilter?: boolean;
  handleClearAll: () => void;
  isBoldHeader?: boolean;
}
  
export const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
    const classes = useToolbarStyles();
    const { numSelected, title, isFilter, buttonsSelected, handleClearAll, isBoldHeader } = props;
  
    return (
      <Toolbar
        className={clsx(classes.root, {
          [classes.highlight]: numSelected > 0,
        })}
      >
        {
          numSelected > 0 ? (
            <div style={{display: 'flex', alignItems: 'center'}}>
              <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
                {numSelected} selecionado{numSelected > 1 && 's'}
              </Typography>
              <Tooltip title="Limpar todos" onClick={handleClearAll}>
                <IconButton aria-label="clear all">
                  <ClearAllIcon style={{color: customtheme.white}} />
                </IconButton>
              </Tooltip>
            </div>
          ) : (
            <Typography style={{ fontWeight: isBoldHeader ? 'bold' : 'normal'}} className={classes.title} variant="h6" id="tableTitle" component="div">
              {
                title
              }
            </Typography>
          )
        }
        {
          numSelected > 0 ? (
            <div>
              { buttonsSelected }
            </div>
          ) : (
            isFilter &&
              <Tooltip title="Filter list">
                <IconButton aria-label="filter list">
                  <FilterListIcon />
                </IconButton>
              </Tooltip>
          )
        }
      </Toolbar>
    );
  };