import { 
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
    makeStyles
} from "@material-ui/core";
import { customtheme } from "../../../app/theme/theme";

const useStyles = makeStyles((theme) => ({
  loadingIcon: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    marginTop: theme.spacing(2),
  },
  backgroundColor: {
    background: customtheme.blue[500],
    color: customtheme.white,
    '&:hover': {
      background: customtheme.blue[50],
      color: customtheme.blue[500],
    },
  },
  backBackgroundColor: {
    background: customtheme.gray[200],
    color: customtheme.blue[500],
    '&:hover': {
      background: customtheme.blue[50],
      color: customtheme.blue[500],
    },
  },
  textFieldFeedback: {
    minWidth: 140,
    '& .MuiInputBase-input': {
      color: customtheme.black,
    },
    '& .MuiInput-underline:hover:before': {
      borderBottomColor: customtheme.blue[500],
    },
  },
}))

interface PurchaseOrderEmitDialogProps {
    handleCloseErro: () => void;
    isLoading: boolean;
    isDialogFeedbackOpen: { open: boolean, title: string, message: string, btnType: string };
    handleFormatedDataToDatabase: (value: string) => void;
    reasonCancel: string;
    setReasonCancel: (value: string) => void;
}

const PurchaseOrderEmitDialog = (props: PurchaseOrderEmitDialogProps) => {
    const classes = useStyles()
    const {
        isLoading, isDialogFeedbackOpen, reasonCancel,
        setReasonCancel, handleCloseErro, handleFormatedDataToDatabase
    } = props;

    return (
      <Dialog
      open={isDialogFeedbackOpen.open}
      onClose={!isLoading ? handleCloseErro : () => {}}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{isDialogFeedbackOpen.title}</DialogTitle>
      <DialogContent>
        {
          isDialogFeedbackOpen.message &&
            <DialogContentText id="alert-dialog-description">
              {isDialogFeedbackOpen.message}
            </DialogContentText>
        }{
          isDialogFeedbackOpen.btnType.includes('disapproved') &&
            <TextField
              label="Motivo"
              value={reasonCancel}
              onChange={(event) => {
                setReasonCancel(event.currentTarget.value)
              }}
              className={classes.textFieldFeedback}
              fullWidth
            />
        }
      </DialogContent>
      <DialogActions>
        {
          isLoading ? (
          <div className={classes.loadingIcon}>
            <CircularProgress style={{color: customtheme.blue[500]}} />
          </div>
          ) : (
            <>
              <Button
                onClick={handleCloseErro}
                className={classes.backBackgroundColor}
                style={{
                  marginRight: isDialogFeedbackOpen.btnType.includes('erro') ? 0 : 20,
                }}
              >
                Voltar
              </Button>
              {
                !isDialogFeedbackOpen.btnType.includes('erro') && 
                 (
                  (reasonCancel !== "" && isDialogFeedbackOpen.btnType.includes('disapproved')) || 
                  !isDialogFeedbackOpen.btnType.includes('disapproved')
                 ) &&
                  <Button
                    onClick={() => handleFormatedDataToDatabase(isDialogFeedbackOpen.btnType)}
                    className={classes.backgroundColor}
                  >
                    Confirmar
                  </Button>
              }
            </>
          )
        }
      </DialogActions>
    </Dialog>
    )
}

export default PurchaseOrderEmitDialog;