export const mapperToMaintenanceRequest = (data: any) => {
  return [
    {
      id: "dados",
      value: data.codemp
        ? {
            codemp: data.codemp,
            nomemp: data.nomemp,
          }
        : null,
      required: true,
    },
    {
      id: "filiais",
      value: data.codfil
        ? {
            codfil: data.codfil,
            nomfil: data.nomfil,
          }
        : null,
      required: true,
    },
    {
      id: "equip",
      value: data.codeqp
        ? {
            codeqp: data.codeqp,
            deseqp: data.deseqp,
          }
        : null,
      required: true,
    },
    {
      id: "tipo",
      value: {
        tipo: data.codeqp !== null ? "equipamento" : "centrocusto",
      },
      required: true,
    },
    {
      id: "tipmnt",
      value: data.tipmnt
        ? {
            tipmnt: data.tipmnt,
          }
        : null,
      required: true,
    },
    {
      id: "obtmnt",
      value: data.obtmnt
        ? {
            obtmnt: data.obtmnt,
          }
        : null,
      required: true,
    },
    {
      id: "centrosCusto",
      value: data.codccu
        ? {
            codccu: data.codccu,
            desccu: data.desccu,
          }
        : null,
      required: true,
    },
    {
      id: "servico",
      value: data.codser
        ? {
            codser: data.codser,
            desser: data.desser,
          }
        : null,
      required: true,
    },
    {
      id: "categoria",
      value: data.codcat
        ? {
            codcat: data.codcat,
            descat: data.descat,
          }
        : null,
      required: true,
    },
    {
      id: "prioridade",
      value: data.codpri
        ? {
            codpri: data.codpri,
            despri: data.despri,
          }
        : null,
      required: true,
    },
    {
      id: "datainicial",
      value: {
        datpri: data.datpri
          ? data.datpri
          : new Date().toISOString().slice(0, -8),
      },
      required: true,
    },
    {
      id: "datafim",
      value: {
        datprf: data.datprf
          ? data.datprf
          : new Date().toISOString().slice(0, -8),
      },
      required: true,
    },
    {
      id: "observacao",
      value: data.desmnt
        ? {
            desmnt: data.desmnt,
          }
        : null,
      required: false,
    },
  ];
};
