import { makeStyles } from '@material-ui/core/styles';
import { NumberLicences } from '../../common/components/NumberLicences';
import { TeamMates } from '../../common/components/TeamMates';
import { TeamMatesAccess } from '../../common/components/TeamMatesAccess';
import { DashboardRequisition } from './DashboardRequisition';
import { DashboardPurchaseOrder } from './DashboardPurchaseOrder';
import { useAuth } from '../../common/hooks/AuthContext';
import { Role } from '../../common/enum/roles.enum';
import { Modules } from '../../common/enum/modules.enum';
import { DashboardMaintenance } from './DashboardMaintenance';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    display: 'flex',
    flexWrap: 'wrap',
    gap: 20,
    flex: 1,
    // justifyContent: 'space-between',
  },
}));

export const Dashboard = () => {
  const classes = useStyles();
  const user = useAuth()
  
  return (
    <div className={classes.root}>
      {
        (user.auth?.perfil === Role.ADMIN || user.auth?.perfil === Role.QUATTUOR) &&
        <>
          <NumberLicences />
          <TeamMates />
          <TeamMatesAccess />
        </>
      }
      {
        user.auth?.modulos.includes(Modules.REQUISICAOAPROVACAO) &&
          <DashboardRequisition />
      }
      {
        user.auth?.modulos.includes(Modules.APROVACAO) &&
          <DashboardPurchaseOrder />
      }
      {
        user.auth?.modulos.includes(Modules.MANUTENCAO) &&
          <DashboardMaintenance />
      }
    </div>
  );
};
