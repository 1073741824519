
import { Divider, Drawer, IconButton, List, Typography, useMediaQuery } from '@material-ui/core';
import { ThemeProvider,  makeStyles, useTheme } from '@material-ui/core/styles';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import React, { ReactNode, useState } from 'react';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import { Header } from '../../common/components/Header';
import { Menu } from '../../common/components/Menu';
import { Role } from '../../common/enum/roles.enum';
import { useAuth } from '../../common/hooks/AuthContext';
import Logo from '../../app/assets/new_layout/logo_quattuor_sem_escrito.png';
import { customtheme } from '../../app/theme/theme';
import { Footer } from '../../common/components/Footer';

//unstable_createMuiStrictModeTheme as createMuiTheme
// const theme = createMuiThemunstable_createMuiStrictModeTheme as createMuiThemee();
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  footer: {
    marginTop: 'auto',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: theme.spacing(2),
  },
  logo: {
    width: '100%',
  },
  logoButton: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    borderRadius: 0,
  },
  logoText: {
    marginLeft: theme.spacing(2),
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
  },
  subtitle: {
    fontSize: 14,
    color: theme.palette.text.secondary,
  },
  logout: {
    borderRadius: 12,
    '&:hover': {
      backgroundColor: customtheme.blue[500],
      color: customtheme.white
    },
  }
}));

export const PrivateRoute: React.FC<{ path: string, children: ReactNode }> = ({ path, children }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const { isAuthenticated, isFetching, auth, logout } = useAuth()
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();

  const [expanded, setExpanded] = useState('');
  const classes = useStyles();
  
  const handleDrawerToggle = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const handleSubmenuToggle = (itemText: string) => {
    if (expanded === itemText) {
      setExpanded('');
    } else {
      setExpanded(itemText);
    }
  };

  const handleLogout = () => {
    logout()
  }

  if (!isAuthenticated &&  !isFetching) {
    return <Navigate to="/login" />;
  }

  return (
    <div>
      {/* Drawer */}
      <ThemeProvider theme={theme}>
        <Drawer 
          variant={isMobile ? 'temporary' : 'permanent'} 
          anchor="left" 
          open={!isMobile || isDrawerOpen} 
          onClose={handleDrawerToggle}
          className={classes.drawer}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.logo}>
            <IconButton onClick={() => { navigate('/dashboard')}} className={classes.logoButton}>
              <img src={Logo} alt="Logo" style={{
                width: '40px',
                height: '40px',
                objectFit: 'cover',
              }}/>
              <div className={classes.logoText}>
                <Typography className={classes.title}>Quattuor</Typography>
                <Typography className={classes.subtitle}>Portal</Typography>
              </div>
            </IconButton>
          </div>
          <Divider />
          <List style={{flex:1}}>
            <Menu 
              modules={auth?.modulos || []}
              expanded={expanded} 
              userType={auth?.perfil || Role.VENDEDOR} 
              onClick={handleSubmenuToggle}/>
          </List> 
          <Divider />
          <div className={classes.footer}>
            <IconButton 
              color="inherit" 
              className={classes.logout}
              onClick={handleLogout}>
              <Typography variant="body2" style={{marginRight: 20}}>Sair</Typography>
              <ExitToAppIcon />
            </IconButton>
          </div>
        </Drawer>
      </ThemeProvider>
      <div style={{ marginLeft: isMobile ? 0 : 240, padding: 0, height: '100%', minHeight: '100vh', display: 'flex', flexDirection: 'column'}}>
        <Header isMobile={isMobile} isLoading={isFetching} onClick={handleDrawerToggle} />
        <Divider />
        <Outlet />
        <Divider />
        <Footer isMobile={isMobile} />
      </div>
    </div>
  );
};