import { 
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    makeStyles
} from "@material-ui/core";
import { customtheme } from "../../../app/theme/theme";

const useStyles = makeStyles((theme) => ({
  loadingIcon: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    marginTop: theme.spacing(2),
  },
  backgroundColor: {
    background: customtheme.blue[500],
    color: customtheme.white,
    '&:hover': {
      background: customtheme.blue[50],
      color: customtheme.blue[500],
    },
  },
  backBackgroundColor: {
    background: customtheme.gray[200],
    color: customtheme.blue[500],
    '&:hover': {
      background: customtheme.blue[50],
      color: customtheme.blue[500],
    },
  },
}))

interface MaintenancePointDialogProps {
    handleCloseErro: () => void;
    isLoading: boolean;
    isDialogFeedbackOpen: { open: boolean, title: string, message: string, btnType: string };
    handleFormatedDataToDatabase: (value: string) => void;
}

const MaintenancePointDialog = (props: MaintenancePointDialogProps) => {
    const classes = useStyles()
    const {
        isLoading, isDialogFeedbackOpen, 
        handleCloseErro, handleFormatedDataToDatabase
    } = props;

    return (
        <Dialog
            open={isDialogFeedbackOpen.open}
            onClose={!isLoading ? handleCloseErro : () => {}}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{isDialogFeedbackOpen.title}</DialogTitle>
            <DialogContent>
            <DialogContentText id="alert-dialog-description">
                {isDialogFeedbackOpen.message}
            </DialogContentText>
            </DialogContent>
            <DialogActions>
            {
                isLoading ? (
                <div className={classes.loadingIcon}>
                <CircularProgress style={{color: customtheme.blue[500]}} />
                </div>
                ) : (
                <>
                    <Button onClick={handleCloseErro} className={classes.backBackgroundColor}>
                    Voltar
                    </Button>
                    {
                    !isDialogFeedbackOpen.btnType.includes('erro') &&
                        <Button
                        onClick={() => handleFormatedDataToDatabase(isDialogFeedbackOpen.btnType)}
                        className={classes.backgroundColor}
                        >
                        Confirmar
                        </Button>
                    }
                </>
                )
            }
            </DialogActions>
        </Dialog>
    )
}

export default MaintenancePointDialog;