import {
  Button,
  Container,
  Step,
  StepIconProps,
  StepLabel,
  Stepper,
  makeStyles
} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// import { TransferStep } from '../../common/components/MoveStep';
import { TransferStep } from '../../common/components/TransferStep';
import { SelectStep, SelectedProps } from '../../common/components/SelectStep';
import { Modules } from '../../common/enum/modules.enum';
import { customtheme } from '../../app/theme/theme';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  container: {
    padding: theme.spacing(4),
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    marginBottom: theme.spacing(2),
  },
  content: {
    flex: 1,
  },
  footer: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'flex-end',
  },
  backgroundColor: {
    background: customtheme.blue[500],
    color: customtheme.white,
    '&:hover': {
      background: customtheme.blue[50],
      color: customtheme.blue[500],
    },
  },
  backBackgroundColor: {
    background: customtheme.gray[200],
    color: customtheme.blue[500],
    '&:hover': {
      background: customtheme.blue[50],
      color: customtheme.blue[500],
    },
  },
  completedStepIcon: {
    color: customtheme.blue[500],
  },
  activeStepIcon: {
    color: customtheme.blue[500],
  },
  stepIcon: {
    background: customtheme.blue[500],
    color: customtheme.white,
    padding: 4,
    width: 16,
    height: 16,
    fontSize: 12,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 20,
  },
  stepIconNot: {
    background: 'gray',
    color: customtheme.white,
    padding: 4,
    width: 16,
    height: 16,
    fontSize: 12,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 20,
  }
}));

const CustomStepIcon: React.FC<StepIconProps> = (props) => {
  const classes = useStyles();

  const { active, completed } = props;

  return (
    <div>
      {completed ? (
        <CheckCircleIcon style={{fontSize: 27}} className={classes.completedStepIcon} />
      ) : (
        <div
          className={`${active ? classes.stepIcon : classes.stepIconNot}`}
        >
          {props.icon}
        </div>
      )}
    </div>
  );
};

// Componente principal do checkout
const CheckoutLicences: React.FC = () => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [selectedLicence, setSelectedLicence] = useState<SelectedProps | null>(null);
  const [isMoved] = useState(false);
  const [isBtnDisabled, setIsBtnDisabled] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    if (selectedLicence) {
      setIsBtnDisabled(false);
    }
  }, [selectedLicence]);

  const stepsComponents = [
    <SelectStep
      selected={selectedLicence}
      setSelected={setSelectedLicence}
      textEmpty={'Não existe licenças.'}
      textSearch={'Pesquisar licenças'}
      typeSearch={'licence'}
      textSelected={'Licença selecionada:'}
    />,
    <TransferStep
      prevData={selectedLicence || { name: '', id: '',  tipo: Modules.DASHBOARD }}
      isMoved={isMoved}
      typeMove={'users'}
      titleFirstColumn={'Não possui licença'}
      titleSecondColumn={'Contém licença'}
      // setIsMoved={setIsMoved}
    />,
  ];

  const handleNext = () => {
    if (activeStep === stepsComponents.length - 1) {
      navigate('/licencas', { replace: true });
    } else {
      if (selectedLicence) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    }
  };

  const handleBack = () => {
    if (activeStep === 0) {
      navigate(-1);
    } else {
      setIsBtnDisabled(false);
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  return (
    <div className={classes.root}>
      <Container maxWidth={false} className={classes.container}>
        <div className={classes.header}>
          <Stepper
            activeStep={activeStep}
          >
            <Step>
              <StepLabel
               StepIconComponent={CustomStepIcon}
              classes={{
                active: classes.activeStepIcon,
                completed: classes.completedStepIcon,
              }}
              >
                Selecionar licença
              </StepLabel>
            </Step>
            <Step>
              <StepLabel
               StepIconComponent={CustomStepIcon}
              classes={{
                active: classes.activeStepIcon,
                completed: classes.completedStepIcon,
              }}
              >
                Movimentar usuário
              </StepLabel>
            </Step>
          </Stepper>
        </div>
        <div className={classes.content}>{stepsComponents[activeStep]}</div>
        <div className={classes.footer}>
          <Button onClick={handleBack} style={{marginRight: 20}} className={classes.backBackgroundColor}>
            Voltar
          </Button>
          <Button
            variant="contained"
            className={classes.backgroundColor}
            onClick={handleNext}
            disabled={isBtnDisabled}
          >
            {activeStep === stepsComponents.length - 1 ? 'Finalizar' : 'Próxima etapa'}
          </Button>
        </div>
      </Container>
    </div>
  );
};

export default CheckoutLicences;
