import { Checkbox, TableCell, TableHead, TableRow, TableSortLabel, Theme, Typography, createStyles, makeStyles } from "@material-ui/core";
import { HeadCell, Order } from "../EnhancedTable";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
  }),
);

interface EnhancedTableProps {
    isClickable: boolean;
    numSelected: number;
    onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
    headCells: HeadCell[];
    isBoldHeader?: boolean; 
  }
  

export function EnhancedTableHead(props: EnhancedTableProps) {
    const classes = useStyles()
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, isClickable, isBoldHeader } = props;
    const createSortHandler = (property: string) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };
  
    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            {
              isClickable &&
                <Checkbox
                  indeterminate={numSelected > 0 && numSelected < rowCount}
                  checked={rowCount > 0 && numSelected === rowCount}
                  onChange={onSelectAllClick}
                  inputProps={{ 'aria-label': 'select all desserts' }}
                  style={{color: rowCount > 0 && numSelected === rowCount ? 'blue': ''}}
              />
            }
          </TableCell>
          {
            props.headCells.map((headCell) => (
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? 'right' : 'left'}
                padding={headCell.disablePadding ? 'none' : 'normal'}
                sortDirection={orderBy === headCell.id ? order : false}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={createSortHandler(headCell.id)}
                >
                  <Typography style={{fontWeight: isBoldHeader ? 'bold' : 'normal'}}>{headCell.label} </Typography>
                  {orderBy === headCell.id ? (
                    <span className={classes.visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </span>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            ))
          }
        </TableRow>
      </TableHead>
    );
  }
  