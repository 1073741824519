import axios from "axios";

export async function authenticate(username: string, password: string): Promise<string> {
    try {
      const response = await axios.post(process.env.REACT_APP_BASE_URL+'/auth/login', {
        username,
        senha: password,
      });
  
      const token = response.data.access_token; // Supondo que o token de autenticação seja retornado pelo backend
      return token;
    } catch (error: any) {
      if(error.response.status === 401)
        throw new Error('Credencias inválidas');
      if(error.response.status === 403 || error.response.status === 503 )
        throw new Error(error.response.data.message);
      throw new Error('Falha na autenticação');
    }
}