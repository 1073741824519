import {
  Container,
  IconButton,
  Tooltip
} from '@material-ui/core';

import Snackbar from '@material-ui/core/Snackbar';
import { makeStyles } from '@material-ui/core/styles';
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep';
import EditIcon from '@material-ui/icons/Edit';
import ErrorIcon from '@material-ui/icons/Error';
import SendIcon from '@material-ui/icons/Send';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { mapperToMaintenanceRequest } from '../../app/mappers/maintenance/maintenance-request.mapper';
import { createMaintenanceRequestDraft } from '../../app/services/maintenance/request/create-request.api';
import { deleteAllMaintenanceRequests, deleteMaintenanceRequest } from '../../app/services/maintenance/request/delete-request.api';
import { getDraftMaintenanceRequestByUser } from '../../app/services/maintenance/request/get-request.api';
import { processMaintenanceRequest } from '../../app/services/maintenance/request/process-request.api';
import { updateMaintenanceRequest } from '../../app/services/maintenance/request/update-request.api';
import DialogSearch from '../../common/components/DialogSearch';
import EnhancedTable, { HeadCell } from '../../common/components/EnhancedTable';
import { handleCheckPrevValue } from '../../common/functions/checkPrevValue';
import MaintenanceRequestDialog from './MaintenanceRequestDialog';
import MaintenanceRequestDrawer from './MaintenanceRequestDrawer';
import MaintenanceRequestHeader from './MaintenanceRequestHeader';
import { customtheme } from '../../app/theme/theme';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: customtheme.gray[200],
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  tableSection: {
    padding: theme.spacing(4),
    flex: 1,
    display: 'flex',
  },
  tableSectionContainer: {
    padding: 0, 
    flex: 1,
    display: 'flex', 
    flexDirection: 'column',
    maxWidth: '100%',
  },
  appbar: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    background: customtheme.white,
    color: customtheme.black,
    padding: theme.spacing(2),
    margin: 1,
  },  
  scrollableTabs: {
    overflowX: 'auto',
  }, 
}));
interface DialogSearchData {
  id: string;
  title: string;
  key: string;
  keySub: string;
  isPrevSearch?: boolean;
  prevSearch?: any;
  prevDataSearch: any;
}

const MaintenanceRequest = () => {
  const classes = useStyles();

  const windowHeight = window.innerHeight;
  const headerHeight = 500; // Altura do cabeçalho em pixels
  const footerHeight = 48; // Altura do rodapé em pixels
  const itemHeight = 80; // Altura média de cada item em pixels
  const availableHeight = windowHeight - headerHeight - footerHeight;
  const itemsPerPage = Math.floor((availableHeight) / itemHeight)

  const [selectedData, setSelectedData] = useState<any>([]);
  const [orderBy, setOrderBy] = useState<string>('nomrep');
  const [selected, setSelected] = useState<string[]>([]);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(itemsPerPage > 5 ? itemsPerPage : 5);
  const [isDrawerOpen, setIsDrawerOpen] = useState({open: false, btnTitle: 'Salvar', btnType: 'save'});
  const [isDialogSearchOpen, setIsDialogSearchOpen] = useState(false);
  const [isDialogFeedbackOpen, setIsDialogFeedbackOpen] = useState({open:false, message: '', title: '', btnType: ''});
  const [isSnackbar, setIsSnackbar] = useState({open:false, message: 'info', title: ''});
  const [isLoading, setIsLoading] = useState(false);
  const [dialogDataSearch, setDialogDataSearch] = useState<DialogSearchData | null>(null);
  const [checkPrevCompanyValue, setCheckPrevCompanyValue] = useState({
    exists: false,
    value: ''
  })
  const [checkPrevTipoValue, setCheckPrevTipoValue] = useState({
    exists: true,
    value: 'equipamento'
  })
  const fieldsInit = mapperToMaintenanceRequest({})

  const [fields, setFields] = useState<any[]>(fieldsInit)
  const headCells: HeadCell[] = [
    { id: 'codemp', numeric: true, disablePadding: false, label: 'Código Empresa' },
    { id: 'nomemp', numeric: false, disablePadding: true, label: 'Empresa' },
    { id: 'codfil', numeric: true, disablePadding: false, label: 'Código Filial' },
    { id: 'nomfil', numeric: false, disablePadding: true, label: 'Filial' },
    { id: 'codeqp', numeric: false, disablePadding: true, label: 'Código Equipamento' },
    { id: 'deseqp', numeric: false, disablePadding: true, label: 'Equipamento' },
    { id: 'codccu', numeric: false, disablePadding: true, label: 'Código Centro Custo' },
    { id: 'desccu', numeric: false, disablePadding: true, label: 'Centro de Custo' },
    { id: 'codser', numeric: false, disablePadding: true, label: 'Código Serviço' },
    { id: 'desser', numeric: false, disablePadding: true, label: 'Serviço' },
    { id: 'tipmnt', numeric: false, disablePadding: true, label: 'Tipo Manutenção' },
    { id: 'obtmnt', numeric: false, disablePadding: true, label: 'Obtenção Manutenção' },
    { id: 'datpri', numeric: false, disablePadding: true, label: 'Data Emissão' },
    { id: 'datprf', numeric: false, disablePadding: true, label: 'Data Previsão' },
    { id: 'codcat', numeric: false, disablePadding: true, label: 'Código Categoria' },
    { id: 'descat', numeric: false, disablePadding: true, label: 'Categoria' },
    { id: 'codpri', numeric: false, disablePadding: true, label: 'Código Prioridade' },
    { id: 'despri', numeric: false, disablePadding: true, label: 'Prioridade' },
    { id: 'actions', numeric: true, disablePadding: false, label: 'Ações' },
  ];

  const createData = (props:any) => {
    const {id, codemp, nomemp, codfil, nomfil, codeqp, deseqp, codccu, desccu, codser, desser, tipmnt, obtmnt, desmnt, datpri, datprf, codcat, descat, codpri, despri} = props
    return { id, codemp, nomemp, codfil, nomfil, codeqp, deseqp, codccu, desccu, codser, desser, tipmnt, obtmnt, desmnt, datpri, datprf, codcat, descat, codpri, despri, ...props };
  }

  const handleOpenDrawer = () => {
    setIsDrawerOpen({open: true, btnTitle: 'Salvar', btnType: 'save'});
  };

  const handleCloseDrawer = () => {
    setSelected([])
    setFields(fieldsInit);
    setIsDrawerOpen({open: false, btnTitle: 'Salvar', btnType: 'save'});
  };

  const handleOpenDialogSearch = (data: DialogSearchData) => {
    setIsDialogSearchOpen(true);
    setDialogDataSearch(data);
  };

  const handleCloseDialogSearch = () => {
    setIsDialogSearchOpen(false);
    setDialogDataSearch(null);
    setSelected([]);
  };

  const handleCloseSnackbar = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setIsSnackbar({open: false, message: '', title: 'info'});
  };

  const handleCloseErro = () => {
    setIsDialogFeedbackOpen({open:false, message: '', title: '', btnType: 'erro'});
    setSelected([])
    // setFields(fieldsInit);
  }

  const handleUpdateField = (data: any) => {
    const update = [...fields];
    update[update.findIndex((field) => field.id === dialogDataSearch?.id)].value = 
      data[dialogDataSearch?.key || ''] === 'Nenhum item' ? null : data
    setFields(update)
    setIsDialogSearchOpen(false);
    setDialogDataSearch(null);
    if(dialogDataSearch?.id === 'dados')
      setCheckPrevCompanyValue(handleCheckPrevValue('dados', update, 'codemp'))
  }

  const { isFetching, refetch } = useQuery(['maintenanceRequest'], async () => {
    // obtem as requisicao rascunho
    const token = localStorage.getItem('token')
    if(token){
      const res = await getDraftMaintenanceRequestByUser(token, {
        take: rowsPerPage,
        skip: page*rowsPerPage,
      })
      if(res){
        setSelectedData(res.results)
        setCount(res.total)
      }
    }
    return []
  }, {
    refetchOnWindowFocus: false,
  })
  
  useEffect(() => {
    refetch();
  }, [page, refetch]);

  const Alert = (props: AlertProps) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const handleFormatedDataToDatabase = async (btnType: string) => {
    setIsLoading(true)
    
    let data = {
      codemp: checkPrevCompanyValue.value,
      nomemp: fields.filter((field) => field.id === 'dados')[0].value?.nomemp,
      codfil: fields.filter((field) => field.id === 'filiais')[0].value?.codfil,
      nomfil: fields.filter((field) => field.id === 'filiais')[0].value?.nomfil,
      codeqp: fields.filter((field) => field.id === 'equip')[0].value?.codeqp,
      deseqp: fields.filter((field) => field.id === 'equip')[0].value?.deseqp,
      codccu: fields.filter((field) => field.id === 'centrosCusto')[0].value?.codccu,
      desccu: fields.filter((field) => field.id === 'centrosCusto')[0].value?.desccu,
      tipmnt: fields.filter((field) => field.id === 'tipmnt')[0].value?.tipmnt,
      codser: fields.filter((field) => field.id === 'servico')[0].value?.codser,
      desser: fields.filter((field) => field.id === 'servico')[0].value?.desser,
      obtmnt: fields.filter((field) => field.id === 'obtmnt')[0].value?.obtmnt,
      desmnt: fields.filter((field) => field.id === 'observacao')[0].value?.desmnt,
      datpri: fields.filter((field) => field.id === 'datainicial')[0].value?.datpri,
      datprf: fields.filter((field) => field.id === 'datafim')[0].value?.datprf,
      codcat: fields.filter((field) => field.id === 'categoria')[0].value?.codcat,
      descat: fields.filter((field) => field.id === 'categoria')[0].value?.descat,
      codpri: fields.filter((field) => field.id === 'prioridade')[0].value?.codpri,
      despri: fields.filter((field) => field.id === 'prioridade')[0].value?.despri,
    }
    
    const useCaseSuccess = {
      save: 'solicitar manutenção',
      edit: 'editar solicitação manutenção',
      remove: 'remover solicitação manutenção',
      removeAll: `remover solicitaç${selected.length > 1 ? 'ões': 'ão'} manutenção`,
      proccess: `processar solicitaç${selected.length > 1 ? 'ões': 'ão'} manutenção`
    } as any

    const token = localStorage.getItem('token')
    let res = {
      statusCode: 400,
    } as any
    
    if(token){
      if(btnType === 'save')
        res = await createMaintenanceRequestDraft(data, token)
        if(btnType === 'edit' && selected.length > 0)
          res = await updateMaintenanceRequest(selected[0], data, token)
        if(btnType === 'remove' && selected.length > 0)
          res = await deleteMaintenanceRequest(selected[0], token)
        if(btnType === 'removeAll' && selected.length > 0)
            res = await deleteAllMaintenanceRequests(selected, token)
        if(btnType === 'proccess' && selected.length > 0)
          res = await processMaintenanceRequest(selected, token)
      
      setIsLoading(false)
      if(res.statusCode === 200){
        setIsSnackbar({
          open: true,
          message: `Sucesso ao ${useCaseSuccess[btnType]}`,
          title: 'success'
        })
      } else {
        setIsSnackbar({
          open: true,
          message: `Erro ao ${useCaseSuccess[btnType]}`,
          title: 'error'
        })
      }
      refetch()
      setIsDialogFeedbackOpen({
        open: false,
        message: '',
        title: '',
        btnType,
      })
      setIsDrawerOpen({
        open: false,
        btnTitle: '',
        btnType: '',
      })
      setFields(fieldsInit)
      setSelected([])
    } else {
      setIsSnackbar({
        open: true,
        message: `Erro ao ${useCaseSuccess[btnType]}`,
        title: 'error'
      })
    }
  } 

  const handleRemoveAll = () => {
    handleDialogConfirmation('removeAll') 
  }

  const handleProcess = () => {
    handleDialogConfirmation('proccess') 
  }

  const handleRemove = (id:any) => {
    handleDialogConfirmation('remove')
  }
  
  const handleErro = (id:any) => {
    setIsDialogFeedbackOpen({
      open: true,
      message: selectedData.filter((data:any) => data.id === id)[0].msgerro,
      title: 'Erro Erp',
      btnType: 'erro'
    })
  }

  const handleEdit = (id:any) => {
    const item = selectedData.filter((data:any) => data.id === id )
    
    setCheckPrevCompanyValue({
      value: item[0].codemp,
      exists: true,
    })

    setCheckPrevTipoValue({
      value: item[0].codeqp !== null ? 'equipamento' : 'centrocusto',
      exists: true,
    })

    setFields(mapperToMaintenanceRequest(item[0]))
    setIsDrawerOpen({open: true, btnTitle: 'Editar', btnType: 'edit'});
  }

  const handleCheckFields = () => {
    const requireds = fields.filter((field) => field.required)
    if(requireds.length -1 > requireds.filter((required) => required.value).length) {
      setIsSnackbar({open:true, message: 'Por favor, adicione os campos obrigatórios!', title: 'error'})
    } else { 
      handleDialogConfirmation(isDrawerOpen.btnType)
    }
  }
  
  const handleDialogConfirmation = (type: string) => {
    const useCaseFeedbackTitle = {
      save: 'Deseja solicitar manutenção',
      edit: 'Deseja editar uma solicitar de manutenção',
      remove: 'Deseja remover uma solicitar de manutenção',
      removeAll: 'Deseja remover as solicitações de manutenção selecionadas',
      proccess: `Deseja processar a${selected.length > 1 ? 's solicitações' : ' solicitação'} de manutenção`
    } as any

    setIsDialogFeedbackOpen({
      open: true,
      title: useCaseFeedbackTitle[type],
      message: '',
      btnType: type,
    })
  }

  const handleCheckError = () => {
    const itemSelected = selectedData.filter((data:any) => data.id === selected[0])
    if(itemSelected){
      const erro =  Object.entries(itemSelected[0]).find(([key, val]) => key.includes('erro'))
      if(erro && erro[1] !== null)
        return true
      else
        return false
    }
    return false
  }

  return (
    <div className={classes.root}>
      <MaintenanceRequestHeader
        onClick={handleOpenDrawer}
      />
      <div className={classes.tableSection}>
        <Container className={classes.tableSectionContainer}>
          <EnhancedTable 
            title='Apontamentos'
            rowsPerPageOptions={[]}
            isClickable={true}
            data={selectedData?.map((data:any) => createData(data) )}
            count={count}
            orderBy={orderBy}
            setOrderBy={setOrderBy}
            headCells={headCells}
            isLoading={isFetching}
            selected={selected}
            setSelected={setSelected}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            buttonsSelected={[
              selected.length === 1 && 
              <Tooltip 
                title="Editar"
                onClick={() => handleEdit(`${selected[0]}`)}
                key='edit'
              >
                <IconButton aria-label="edit">
                  <EditIcon style={{color: customtheme.white}}/>
                </IconButton>
              </Tooltip>,
            (selected.length === 1 && handleCheckError()) && 
              <Tooltip
                title="Erro"
                onClick={() => handleErro(`${selected[0]}`)}
                key='erro'
              >
              <IconButton aria-label="erro">
                <ErrorIcon style={{color: customtheme.white}}/>
              </IconButton>
            </Tooltip>,
              <Tooltip title="Deletar" onClick={handleRemoveAll} key='deleteall'>
                <IconButton aria-label="delete">
                  <DeleteSweepIcon style={{color: customtheme.white}}/>
                </IconButton>
              </Tooltip>,
              <Tooltip title="Processar" onClick={handleProcess} key='process'>
                <IconButton aria-label="processar">
                  <SendIcon style={{color: customtheme.white}}/>
                </IconButton>
              </Tooltip>,
            ]}
            buttonsActions={['edit', 'erro', 'remove']}
            handleEdit={handleEdit}
            handleErro={handleErro}
            handleRemove={handleRemove}
          />
        </Container>
      </div>
      <MaintenanceRequestDrawer
        checkPrevCompanyValue={checkPrevCompanyValue}
        checkPrevTipoValue={checkPrevTipoValue}
        fields={fields}
        handleCheckFields={handleCheckFields}
        handleCloseDrawer={handleCloseDrawer}
        handleCloseSnackbar={handleCloseSnackbar}
        handleOpenDialogSearch={handleOpenDialogSearch}
        isDrawerOpen={isDrawerOpen}
        setCheckPrevTipoValue={setCheckPrevTipoValue}
        setFields={setFields}
      />
      <DialogSearch
        title={dialogDataSearch?.title || ''}
        id={dialogDataSearch?.id || ''}
        keyItem={dialogDataSearch?.key || ''}
        keySubItem={dialogDataSearch?.keySub || ''}
        isDialogOpen={isDialogSearchOpen}
        isPrevSearch={dialogDataSearch?.isPrevSearch || false}
        prevSearch={dialogDataSearch?.prevSearch || []}
        prevDataSearch={dialogDataSearch?.prevDataSearch}
        handleCloseDialog={handleCloseDialogSearch}
        handleUpdateField={(data:any) => { handleUpdateField(data) }}
      />
      <MaintenanceRequestDialog
        handleCloseErro={handleCloseErro}
        handleFormatedDataToDatabase={handleFormatedDataToDatabase}
        isDialogFeedbackOpen={isDialogFeedbackOpen}
        isLoading={isLoading}
      />
      <Snackbar open={isSnackbar.open} autoHideDuration={3000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={isSnackbar.title as any}>
          {isSnackbar.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default MaintenanceRequest;