export const mapperToRequisition = (data: any) => {
    return [
        {
          id: 'produto',
          value: data.codpro ? {
            codpro: data.codpro,
            despro: data.despro,
          } : null,
          required: true,
        },
        {
          id: 'derivacao',
          value: data.codder ? {
            codder: data.codder,
            desder: data.desder,
          } : null,
          required: false,
        },
        {
          id: 'qtdeme',
          value: data.qtdeme ? {
            qtdeme: data.qtdeme,
          } : {
            qtdeme: 1,
          },
          required: true,
        },
        {
          id: 'dateme',
          value: {
            dateme: data.dateme ? data.dateme : new Date().toISOString().split('T')[0],
          },
          required: true,
        },
        {
          id: 'datprv',
          value: {
            datprv: data.datprv ? data.datprv : new Date().toISOString().split('T')[0],
          },
          required: true,
        },
        {
          id: 'deposito',
          value: data.coddep ? {
            coddep: data.coddep,
            desdep: data.desdep,
          } : null,
          required: false,
        },
        {
          id: 'centrosCusto',
          value: data.codccu ? {
            codccu: data.codccu,
            desccu: data.desccu,
          } : null,
          required: false,
        },
      ];
}