import {
    Card,
    CardContent,
    Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery } from '@tanstack/react-query';
import { useAuth } from '../../hooks/AuthContext';
import { getLicencesByCompanyDashboard } from '../../../app/services/licences/get-licences.api';
import { CircularProgressBackground } from '../CircularProgressBackground';
import { Skeleton } from '@material-ui/lab';
  
const useStyles = makeStyles((theme) => ({
    card: {
      minWidth: 320,
      width: 'fit-content',
      minHeight: 280,
      display: 'flex',
    },
    progressItem: {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      justifyContent: 'center',
      gap: 40,
      padding: 20,
    },
    label: {
      textAlign: 'center',
      marginTop: 20,
    },
}));
    
export const NumberLicences = () => {
    const classes = useStyles();
    const { auth } = useAuth();
  
    const { data, isFetching } = useQuery(['dashboard'], async () => {
      const token = localStorage.getItem('token');
      if (auth?.empresa_filial_id && token) {
        const res = await getLicencesByCompanyDashboard(auth.empresa_filial_id, token);
        return res;
      }
      return [];
    }, {
      refetchOnWindowFocus: false,
    });
  
    return (
        <Card className={classes.card}>
          <CardContent style={{flex: 1}}>
            <Typography variant="h5" component="h2">
              Número de Licenças
            </Typography>
            {
              isFetching ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', gap: 40 }}>
                  {                   
                    Array.from({length: 3}).map((_, index) => (
                      <div key={`${index}-div`} style={{ display: 'flex', flexDirection: 'column'}}>
                        <Skeleton key={`${index}-circle`} animation="wave" variant="circle" width={160} height={160} />
                        <Skeleton key={`${index}-text`} animation="wave" height={30} width="100%" style={{ marginBottom: 6 }} />  
                      </div>
                    ))
                  }      
                </div>
              ) : (
                <div className={classes.progressItem}>
                  {
                    data?.map((d:any, index: number) => (
                      <div key={index} style={{  maxWidth: 200, height: 200}}>
                        <CircularProgressBackground
                          variant="determinate" 
                          value={(d.count * 100) / d.total} 
                          size={'9rem'} 
                          thickness={4}
                          text={`${d.count}/${d.total}`}
                          module={d.tipo}
                        />
                        <Typography variant="body2" className={classes.label}>
                          {d.descricao}
                        </Typography>
                      </div>
                    ))
                  }
                </div>
              )
            }
          </CardContent>
        </Card>
    );
  };
  