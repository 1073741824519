import {
  Avatar,
    Box,
    Card,
    CardContent,
    CardHeader,
    Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery } from '@tanstack/react-query';
import { useAuth } from '../../hooks/AuthContext';
import { getUsersByCompany } from '../../../app/services/users/get.user.api';
import ModalTeamMates from './ModalTeamMates';
import { COLORS } from '../../enum/colors.enum';
import { Skeleton } from '@material-ui/lab';
  
const useStyles = makeStyles((theme) => ({
    card: {
      minWidth: 320,
      width: 'fit-content',
      height: 440,
      display: 'flex',
    },
    cardContent: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
    },
    item: {
      display: 'flex',
      flexDirection: 'column',
      gap: 12,
      width: '100%',
    },
    itemContainer: {
      // marginTop: 20,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
      justifyContent: 'space-between',
      height: '100%',
    },
    loading: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
    },
}));
    
export const TeamMates = () => {
    const classes = useStyles();
    const { auth } = useAuth();
    const numberMax = 4;
  
    const { data, isFetching } = useQuery(['teammates'], async () => {
      const token = localStorage.getItem('token');
      if (auth?.empresa_filial_id && token) {
        const res = await getUsersByCompany(auth.empresa_filial_id, token);
        return res as any[];
      }
      return [];
    }, {
      refetchOnWindowFocus: false,
    });
  
    return (
        <Card className={classes.card}>
          <CardContent className={classes.cardContent}>
            <Typography variant="h5" component="h2">
              Time
            </Typography>
              <Box className={classes.itemContainer}>
                <Box className={classes.item}>
                    {data?.filter((d:any, index: number) => index < numberMax).map((d:any, index: number) => (
                      <Card key={index}>
                        <CardHeader
                          avatar={
                            isFetching ? (
                              <Skeleton animation="wave" variant="circle" width={40} height={40} />
                            ) : (
                            <Avatar
                              aria-label="recipe"
                              style={{ 
                                backgroundColor: 
                                  d.nomrep ? 
                                    COLORS[d.nomrep[0].toUpperCase() as keyof typeof COLORS]
                                  : 
                                    'gray'
                              }}
                            >
                              {d.nomrep ? d.nomrep[0].toUpperCase() : ''}
                            </Avatar>
                            )
                          }
                          title={
                            isFetching ? (
                              <Skeleton animation="wave" height={10} width="80%" style={{ marginBottom: 6 }} />
                            ) : d.nomrep
                          }
                          subheader={ 
                            isFetching ? (
                              <Skeleton animation="wave" height={10} width="80%" style={{ marginBottom: 6 }} />
                            ) : d.perfil
                          }
                        />
                      </Card>
                    ))}
                </Box>
                {
                  isFetching ? 
                    <Skeleton animation="wave" variant="rect" width={100} height={60} style={{ marginTop: 20 }} />
                  :
                    data && data?.length > numberMax && <ModalTeamMates values={data}/>
                }
              </Box>
          </CardContent>
        </Card>
    );
  };
  