import axios, { HttpStatusCode } from "axios";
import { StatusMessageResponse } from "../../../../common/interfaces/status-message-response";

export async function deleteAllMaintenanceRequests(data: Array<any>, token: string): Promise<StatusMessageResponse> {
    try {
      const response = await axios.delete(process.env.REACT_APP_BASE_URL+'/manutencao/solicitar/all/ids/', 
        {
          data,
          headers: {
            Authorization: 'Bearer ' + token
          }
        }
      );
  
      const res = response.data; // Supondo que a resposta de criação seja retornado pelo backend
      return res;
    } catch (error) {
      return {
      statusCode: HttpStatusCode.InternalServerError,
      message: 'Falha ao deletar o solicitação de manutenção',
    }
    }
}

export async function deleteMaintenanceRequest(id: string, token: string): Promise<StatusMessageResponse> {
  try {
    const response = await axios.delete(process.env.REACT_APP_BASE_URL+'/manutencao/solicitar/'+id, 
      {
        headers: {
          Authorization: 'Bearer ' + token
        }
      }
    );

    const res = response.data; // Supondo que a resposta de criação seja retornado pelo backend
    return res;
  } catch (error) {
    return {
      statusCode: HttpStatusCode.InternalServerError,
      message: 'Falha ao deletar as solicitações de manutenção',
    }
  }
}