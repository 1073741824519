import { Button, Drawer, Grid, IconButton, TextField, Typography, makeStyles } from "@material-ui/core";
import { handleReturnValue } from '../../../common/functions/returnValue';
import SearchIcon from '@material-ui/icons/Search';
import { customtheme } from "../../../app/theme/theme";

const useStyles = makeStyles((theme) => ({
    drawer: {
        width: 400,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: theme.spacing(2),
        borderBottom: `1px solid ${theme.palette.divider}`,
        background: customtheme.gray[200],
    },
    drawerContent: {
        overflowY: 'auto',
        padding: theme.spacing(2), 
        height: '100%',
    },
    drawerFooter: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(2),
        borderTop: `1px solid ${theme.palette.divider}`,
        background: customtheme.gray[200],
    },
    textField: {
        minWidth: 140,
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        '& .MuiInputBase-input': {
          color: customtheme.black,
        },
        '& .MuiInput-underline:hover:before': {
          borderBottomColor:customtheme.blue[500],
        },
    },
    searchIcon: {
        marginRight: theme.spacing(1),
        color:customtheme.blue[500],
    },
    loadingIcon: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        marginTop: theme.spacing(2),
    },
    backgroundColor: {
      background: customtheme.blue[500],
      color: customtheme.white,
      '&:hover': {
        background: customtheme.blue[50],
        color: customtheme.blue[500],
      },
    },
    backBackgroundColor: {
        background: customtheme.gray[200],
        color: customtheme.blue[500],
        '&:hover': {
        background: customtheme.blue[50],
        color: customtheme.blue[500],
        },
    },
}))

interface DialogSearchData {
    id: string;
    title: string;
    key: string;
    keySub: string;
    isPrevSearch?: boolean;
    prevSearch?: any;
    prevDataSearch: any;
}

interface RequisitionEmitDrawerProps {
    handleCloseDrawer: () => void;
    handleOpenDialogSearch: (data: DialogSearchData) => void;
    handleCheckFields: () => void;
    handleCloseSnackbar: ( event?: React.SyntheticEvent, reason?: string ) => void;
    isDrawerOpen: { open: boolean, btnTitle: string, btnType: string };
    fields: any[];
    setFields: ( array: any[] ) => void;
}

const RequisitionEmitDrawer = (props: RequisitionEmitDrawerProps) => {
    const classes = useStyles()
    const { 
        isDrawerOpen, fields, setFields,
        handleCheckFields, handleCloseDrawer, handleOpenDialogSearch
    } = props;

    const setLowerKeys = (array: Array<any>) => {
        const arrayChecked = !Array.isArray(array)
        ? [array]
        : array;
    
        return arrayChecked.map((r: any) =>
              Object.entries(r).reduce((acc:any, [key, value]:any) => {
                acc[key.toLowerCase()] = value;
                return acc;
              }, {}),
            );
    }

    return (
        <>
            <Drawer
                anchor="right"
                open={isDrawerOpen.open}
                onClose={handleCloseDrawer}
                classes={{ paper: classes.drawer }}
            >
                <div className={classes.drawerHeader}>
                <Typography variant="h6">Adicionar Item</Typography>
                </div>
                <div className={classes.drawerContent}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                    <TextField
                        label="Produto"
                        onClick={() => handleOpenDialogSearch({
                            id: 'produto', 
                            title: 'Produtos', 
                            keySub: 'despro', 
                            key: 'codpro',
                            prevDataSearch: {}
                            })}
                        value={ 
                        handleReturnValue('produto', fields)
                        }
                        fullWidth
                        className={classes.textField}
                        InputProps={{
                        endAdornment: (
                            <IconButton
                            className={classes.searchIcon}
                            onClick={() => handleOpenDialogSearch({
                                id: 'produto', 
                                title: 'Produtos', 
                                keySub: 'despro', 
                                key: 'codpro',
                                prevDataSearch: {}
                            })}>
                            <SearchIcon />
                            </IconButton>
                        ),
                        }}
                    />
                    <TextField
                        label="Derivação"
                        onClick={
                        fields.filter((field) => field.id === 'produto')[0].value ?
                        () => 
                        handleOpenDialogSearch({
                            id: 'derivacao', 
                            title: 'Derivação', 
                            keySub: 'desder', 
                            key: 'codder',
                            isPrevSearch: true,
                            prevSearch: setLowerKeys(fields.filter((field) => field.id === 'produto')[0].value?.derivacao || []),
                            prevDataSearch: {}
                        }) : 
                        () => {}
                        }
                        disabled={fields.filter((field) => field.id === 'produto')[0].value ? false : true}
                        value={
                        fields.filter((field) => field.id === 'produto')[0].value ?
                            handleReturnValue('derivacao', fields)
                        : 'Selecione um produto primeiro'} 
                        fullWidth
                        className={classes.textField}
                        InputProps={{
                        endAdornment: (
                            <IconButton
                            className={classes.searchIcon} 
                            onClick={
                                fields.filter((field) => field.id === 'produto')[0].value ?
                                () => 
                                handleOpenDialogSearch({
                                    id: 'derivacao', 
                                    title: 'Derivação', 
                                    keySub: 'desder',
                                    key: 'codder',
                                    isPrevSearch: true,
                                    prevSearch: setLowerKeys(fields.filter((field) => field.id === 'produto')[0].value?.derivacao || []),
                                    prevDataSearch: {}
                                    }) : 
                                () => {}
                            }
                            disabled={fields.filter((field) => field.id === 'produto')[0].value ? false : true}
                            >
                            <SearchIcon />
                            </IconButton>
                        ),
                        }}
                    />
                    <TextField
                        label="Quantidade requerida"
                        type="number"
                        inputProps={{
                            step: 0.01,
                            min: 0.01,
                        }}
                        value={fields.filter((field) => field.id === 'qtdeme')[0].value?.qtdeme || 0.01}
                        className={classes.textField}
                        onChange={(event) => {
                            const update = [...fields]
                            if (Number(event.target.value) > -1) {
                                update[update.findIndex((up) => up.id === 'qtdeme')].value = {
                                qtdeme: Number(event.target.value)
                                }
                            }
                            setFields(update)
                        }}
                        fullWidth
                    />
                    <div style={{justifyContent: 'space-between', display: 'flex'}}>
                        <TextField
                            id="date"
                            label="Data Emissão"
                            type="date"
                            disabled
                            value={fields.filter((field) => field.id === 'dateme')[0].value?.dateme}
                            onChange={() => {}}
                            className={classes.textField}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        <TextField
                        id="date"
                        label="Data Previsão"
                        type="date"
                        value={fields.filter((field) => field.id === 'datprv')[0].value?.datprv}
                        onChange={(event) => {
                            const update = [...fields]
                            update[update.findIndex((up) => up.id === 'datprv')].value = {
                            datprv: event.currentTarget.value
                            }
                            setFields(update)
                        }}
                        className={classes.textField}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        />
                    </div>
                    {/* <TextField
                        label="Depósito"
                        onClick={() => handleOpenDialogSearch({
                        id: 'deposito', 
                        title: 'Depósito', 
                        keySub: 'desdep', 
                        key: 'coddep',
                        prevDataSearch: {}
                        })}
                        value={handleReturnValue('deposito', fields)}
                        fullWidth
                        className={classes.textField}
                        InputProps={{
                        endAdornment: (
                            <IconButton 
                            className={classes.searchIcon} 
                            onClick={() => handleOpenDialogSearch({
                                id: 'deposito', 
                                title: 'Depósito',
                                keySub: 'desdep',
                                key: 'coddep',
                                prevDataSearch: {}
                            })}>
                            <SearchIcon />
                            </IconButton>
                        ),
                        }}
                    /> */}
                    <TextField
                        label="Centro de custo"
                        onClick={() => handleOpenDialogSearch({
                        id: 'centrosCusto',
                        title: 'Centro de custo',
                        keySub: 'desccu',
                        key: 'codccu',
                        prevDataSearch: {}
                        })}
                        value={handleReturnValue('centrosCusto', fields)}
                        fullWidth
                        className={classes.textField}
                        InputProps={{
                        endAdornment: (
                            <IconButton 
                            className={classes.searchIcon} 
                            onClick={() => handleOpenDialogSearch({
                                id: 'centrosCusto',
                                title: 'Centro de custo',
                                keySub: 'desccu',
                                key: 'codccu',
                                prevDataSearch: {}
                            })}>
                            <SearchIcon />
                            </IconButton>
                        ),
                        }}
                    />
                    </Grid>
                </Grid>
                </div>
                <div className={classes.drawerFooter}>
                <Button
                    onClick={handleCloseDrawer}
                    className={classes.backBackgroundColor}
                    style={{marginRight: 20}}
                >
                    Cancelar
                </Button>
                <Button 
                    onClick={() => {
                    handleCheckFields()
                    }} 
                    className={classes.backgroundColor}
                >
                    {isDrawerOpen.btnTitle}
                </Button>
                </div>
            </Drawer>  
        </>
    )
}

export default RequisitionEmitDrawer;