export function formatedDateErp(date: string) {
    const newDate = new Date(date);
    return `${newDate.getDate()}/${
      newDate.getMonth() + 1
    }/${newDate.getFullYear()}`;
}

export function formatedStringDateErp(date: string) {
    const dateSplited = date.split('/')
    return `${dateSplited[1]}/${dateSplited[0]}/${dateSplited[2]}`;
}

export function formatedYearDateErp(date: string) {
  const dateSplited = date.split('/')
  return `${dateSplited[2]}-${dateSplited[1]}-${dateSplited[0]}`;
}
