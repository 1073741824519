import { QueryClientProvider } from '@tanstack/react-query';
import React from 'react';
import { Route, HashRouter, Routes } from 'react-router-dom';
import { AuthProvider } from './common/hooks/AuthContext';
import { queryClient } from './common/hooks/queryClient';
import { Dashboard } from './pages/Dashboard';
import { LoginPage } from './pages/Login';
import { PrivateRoute } from './pages/PrivateRoute';
import Licences from './pages/Licences';
import CheckoutLicences from './pages/CheckoutLicences';
import RequisitionEmit from './pages/RequisitionEmit';
import RequisitionConsult from './pages/RequisitionConsult';
import PurchaseOrderEmit from './pages/PurchaseOrderEmit';
import PurchaseOrderConsult from './pages/PurchaseOrderConsult';
import MaintenanceRequest from './pages/MaintenanceRequest';
import MaintenancePoint from './pages/MaintenancePoint';
import CollectionPoint from './pages/CollectionPoint';
import RequisitionApproved from './pages/RequisitionApproved';
import RequisitionApprovedConsult from './pages/RequisitionApprovedConsult';
import MaintenanceRequestConsult from './pages/MaintenanceRequestConsult';
import MaintenancePointConsult from './pages/MaintenancePointConsult';
import CollectionPointConsult from './pages/CollectionPointConsult';
import { PainelMaintenance } from './pages/PainelMaintenance';
import { DashboardMaintenance } from './pages/DashboardMaintenance';

const App: React.FC = () => {
  
  return (    
    <div style={{height: '100vh'}}> 
    <QueryClientProvider client={queryClient}>
      <HashRouter>
        <AuthProvider>
          <Routes>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/" element={<PrivateRoute path="/" children={undefined} />}>
              <Route index path='/dashboard' element={<Dashboard />} />
              <Route path="/licencas" element={<Licences />} />
              <Route path="/licencas/checkout" element={<CheckoutLicences />} />
              <Route path="/requisicao/emissao" element={<RequisitionEmit />} />
              <Route path="/requisicao/emissao/consulta" element={<RequisitionConsult />} />
              <Route path="/requisicao/aprovacao" element={<RequisitionApproved />} />
              <Route path="/requisicao/aprovacao/consulta" element={<RequisitionApprovedConsult />} />
              <Route path="/ordemcompra" element={<PurchaseOrderEmit />} />
              <Route path="/ordemcompra/consulta" element={<PurchaseOrderConsult />} />
              <Route path="/manutencao/solicitar" element={<MaintenanceRequest />} />
              <Route path="/manutencao/apontar" element={<MaintenancePoint />} />
              <Route path="/manutencao/coleta" element={<CollectionPoint />} />
              <Route path="/manutencao/solicitar/consulta" element={<MaintenanceRequestConsult />} />
              <Route path="/manutencao/apontar/consulta" element={<MaintenancePointConsult />} />
              <Route path="/manutencao/coleta/consulta" element={<CollectionPointConsult />} />
              <Route path="/manutencao/painel" element={<PainelMaintenance />} />
              <Route path="/manutencao/dashboard" element={<DashboardMaintenance />} />
            </Route>
          </Routes>
        </AuthProvider>
      </HashRouter>
    </QueryClientProvider>
    </div>
  );
};

export default App;