import axios, { HttpStatusCode } from "axios";
import { StatusMessageResponse } from "../../../common/interfaces/status-message-response";

export async function createRequistionDraft(data: any, token: string): Promise<StatusMessageResponse> {
    try {
      const response = await axios.post(process.env.REACT_APP_BASE_URL+'/requisicao/rascunho', 
        data, 
        {
            headers: {
                Authorization: 'Bearer ' + token
            }
        }
      );
  
      const res = response.data; // Supondo que a resposta de criação seja retornado pelo backend
      return res;
    } catch (error) {
      return {
        statusCode: HttpStatusCode.InternalServerError,
        message: 'Falha na criação de uma requisição',
      }
    }
}