export const handleReturnValue = (type: string, fields: Array<any>) => {
    const item = fields.filter((field) => field.id === type)[0].value
    const useCaseType = {
      produto: `${item?.codpro} - ${item?.despro}`,
      derivacao: `${item?.codder} - ${item?.desder}`,
      deposito: `${item?.coddep} - ${item?.desdep}`,
      centrosCusto: `${item?.codccu} - ${item?.desccu}`,
      equip: `${item?.codeqp} - ${item?.deseqp}`,
      servico: `${item?.codser} - ${item?.desser}`,
      categoria: `${item?.codcat} - ${item?.descat}`,
      prioridade: `${item?.codpri} - ${item?.despri}`,
      dados: `${item?.codemp} - ${item?.nomemp}`,
      filiais: `${item?.codfil} - ${item?.nomfil}`,
      manutencao: `${item?.nummnt}`,
      estagio: `${item?.codetg} - ${item?.desetg}`,
      sequencia: `${item?.seqrot}`,
      operador: `${item?.numcad} - ${item?.nomope}`,
      coleta: `${item?.codccm}`,
    } as any

    if (item)
      return useCaseType[type]
    return 'Nenhum item selecionado'
}