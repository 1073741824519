import {
  Button,
  CircularProgress,
  Container,
  Grid,
  Modal,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery } from '@tanstack/react-query';
import React, { useState } from 'react';
import { createModule } from '../../../app/services/modules/create-module.api';
import { deleteModuleByLicenceAndUser } from '../../../app/services/modules/delete-module.api';
import { getUsersModuleByCompany, getUsersModuleWithoutCompany } from '../../../app/services/modules/get-users-module.api';
import { useAuth } from '../../hooks/AuthContext';
import { SelectedProps } from '../SelectStep';
import { CustomList } from './CustomList';
import { customtheme } from '../../../app/theme/theme';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    backgroundColor: customtheme.gray[200],
    height: '100%',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      height: 'auto',
    },
    [theme.breakpoints.down('xs')]: {
      padding: "16px 0px",
    },
  },
  column: {
    backgroundColor: customtheme.white,
    padding: theme.spacing(2),
    borderRadius: 4,
    boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.1)',
    minHeight: 200,
  },
  user: {
    backgroundColor: customtheme.gray[100],
    padding: theme.spacing(2),
    borderRadius: 4,
    marginBottom: theme.spacing(1),
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContent: {
    backgroundColor: customtheme.white,
    padding: theme.spacing(4),
    borderRadius: 4,
    maxWidth: 400,
    textAlign: 'center',
  },
  loadingIcon: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(2),
  },
  buttonSpacing: {
    marginRight: theme.spacing(2), // Define a margem direita entre os botões
  },
  button: {
    margin: theme.spacing(0.5, 0),
    color:customtheme.blue[500],
  },
}));

export interface DataProps {
  id: string;
  name: string;
  hasInfo: boolean;
}

interface TransferStepProps {
  prevData: SelectedProps;
  isMoved?: boolean;
  typeMove: string;
  titleFirstColumn: string;
  titleSecondColumn: string;
}

export const TransferStep: React.FC<TransferStepProps> = ({prevData, typeMove, titleFirstColumn, titleSecondColumn}) => {
  const classes = useStyles();
  const [dataWithoutInfo, setDataWithoutInfo] = useState<DataProps[]>([]);
  const [dataWithInfo, setDataWithInfo] = useState<DataProps[]>([]);
  const [transferModalOpen, setTransferModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isTransferLoading, setIsTransferLoading] = useState(false);
  const [transferError, setTransferError] = useState<string | null>(null);
  const [checked, setChecked] = useState<DataProps[]>([]);

  const leftChecked = intersection(checked, dataWithInfo);
  const rightChecked = intersection(checked, dataWithoutInfo);

  const { auth } = useAuth()
  
  const { refetch } = useQuery(['moveData'], async () => {
    const useCase = {
      usersWith: getUsersModuleByCompany,
      usersWithout: getUsersModuleWithoutCompany,
    } as any

    setIsLoading(true)
    const token = localStorage.getItem('token');
    if(auth?.empresa_filial_id && token){
      const res = await Promise.all([
        useCase[typeMove+'With'](auth.empresa_filial_id, token, {tipo: prevData.tipo}),
        useCase[typeMove+'Without'](auth.empresa_filial_id, token, {tipo: prevData.tipo})
      ])
      
      if(res){
        setDataWithInfo(res[0].map((r:any) => handleFormatData(true, r)))
        setDataWithoutInfo(res[1].map((r:any) => handleFormatData(false, r)))
      }
    }
    setIsLoading(false)
    return null;
  }, {
    refetchOnWindowFocus: false,
  });

  const handleFormatData = (hasInfo: boolean, data: any) => {
    return {
      id: data.id,
      name: data.nomrep,
      hasInfo: hasInfo,
    }
  }

  const handleConfirmTransfer = async () => {
    if (checked.length > 0){
      const promisses = checked.map((user) => {
        if (user.hasInfo) {
          return handleRemoveUserFromLicence(user)
        } else {
          return handleMoveUserToLicence(user)
        }
      })

      await Promise.all(promisses)
      
    }
  };

  const handleReturnJsonFormatedModule = (user: DataProps) => {
    return JSON.parse(`{
      "licenca_id": "${prevData.id}",
      "usuario_id": "${user.id}"
    }`)
  }

  const handleMoveUserToLicence = async (user: DataProps) => {
    const token = localStorage.getItem('token');
    setIsTransferLoading(true);
    setTransferError(null);
    const response = await createModule(handleReturnJsonFormatedModule(user), token || '')
    if(response.statusCode === 200) {
      setTransferModalOpen(false)
    } else {
      setTransferError(response.message);
    }
    setIsTransferLoading(false);
    setChecked([]);
    refetch()
  }

  const handleRemoveUserFromLicence  = async (user: DataProps) => {
    const token = localStorage.getItem('token');
    setIsTransferLoading(true);
    setTransferError(null);
    const response = await deleteModuleByLicenceAndUser(handleReturnJsonFormatedModule(user), token || '')
    if(response.statusCode === 200) {
      setTransferModalOpen(false)
    } else {
      setTransferError(response.message);
    }
    setIsTransferLoading(false);
    setChecked([]);
    refetch()
  }

  const handleCancelTransfer = () => {
    setTransferModalOpen(false);
    setChecked([]);
    setIsTransferLoading(false);
    setTransferError(null);
  };


  const handleCheckedRight = () => {
    setTransferModalOpen(true)
  };

  const handleCheckedLeft = () => {
    setTransferModalOpen(true)
  };

  function intersection(a: any[], b: any[]) {
    return a.filter((value) => b.indexOf(value) !== -1);
  }

  return (
    <Container maxWidth={false} className={classes.root}>
      <Grid
        container
        spacing={2}
        justifyContent="center"
        alignItems="center"
        className={classes.root}
      >
        <Grid item>
          <CustomList
            title={titleFirstColumn}
            checked={checked}
            isLoading={isLoading}
            items={dataWithoutInfo}
            setChecked={setChecked}
            intersection={intersection}
          />
        </Grid>
        <Grid item>
          <Grid container direction="column" alignItems="center" style={{minWidth: 160}}>
            <Button
              variant="outlined"
              size="small"
              className={classes.button}
              onClick={handleCheckedLeft}
              disabled={rightChecked.length === 0}
              aria-label="move selected left"
            >
              &gt;
            </Button>
            <Button
              variant="outlined"
              size="small"
              className={classes.button}
              onClick={handleCheckedRight}
              disabled={leftChecked.length === 0}
              aria-label="move selected right"
            >
              &lt;
            </Button>
          </Grid>
        </Grid>
        <Grid item>
          <CustomList
            title={titleSecondColumn}
            checked={checked}
            isLoading={isLoading}
            items={dataWithInfo}
            setChecked={setChecked}
            intersection={intersection}
          />
        </Grid>
      </Grid>

      <Modal
        className={classes.modal}
        open={transferModalOpen}
        onClose={handleCancelTransfer}
      >
        <div className={classes.modalContent}>
          {
            transferError ? (
              <div>
              <Typography variant="h6">Erro</Typography>
                <Typography variant="body1" color="error">
                  Erro ao transferir o usuário: {transferError}
                </Typography>
                <Button
                  variant="contained"
                  color="default"
                  onClick={handleCancelTransfer} 
                  className={classes.buttonSpacing}
                >
                  Voltar
                </Button>
              </div>
            ) : (
            <div>
              <Typography variant="h6">Confirmação</Typography>
              <Typography variant="body1">
                Deseja transferir { checked.length > 1 ? `os usuários` : `o usuário` } {
                  checked.map((user, index) => index > 0 ? `, ${user.name}` : user.name)
                }?
              </Typography>
              {
                isTransferLoading ? (
                  <div className={classes.loadingIcon}>
                    <CircularProgress style={{color: customtheme.blue[500]}} />
                  </div>
                ) : (
                  <>
                    <Button
                      variant="contained"
                      style={{
                        background: customtheme.blue[500],
                        color: customtheme.white,
                      }}
                      onClick={handleConfirmTransfer} 
                      className={classes.buttonSpacing}
                    >
                      Transferir
                    </Button>
                    <Button
                      variant="contained"
                      color="default"
                      onClick={handleCancelTransfer} 
                      className={classes.buttonSpacing}
                    >
                      Cancelar
                    </Button>
                  </>
                )
              }
            </div>)
          }
        </div>
      </Modal>
    </Container>
  );
};
