import axios from "axios";

export async function getDraftRequisitionByUser(token:string, params:any): Promise<any> {
    try {
      const response = await axios.get(process.env.REACT_APP_BASE_URL+`/requisicao/rascunho`, {
        params,
        headers: {
            Authorization: 'Bearer ' + token
        }
        });
  
      return response.data; // Supondo que as requisições sejam retornados pelo backend
    } catch (error) {
      throw new Error('Falha na autenticação');
    }
}

export async function getProcessRequisitionByUser(token:string, params:any): Promise<any> {
  try {
    const response = await axios.get(process.env.REACT_APP_BASE_URL+`/requisicao/processadas`, {
      params,
      headers: {
          Authorization: 'Bearer ' + token
      }
      });

    return response.data; // Supondo que as requisições sejam retornados pelo backend
  } catch (error) {
    throw new Error('Falha na autenticação');
  }
}