export const mapperToMaintenancePoint = (data: any) => {
    return [
      {
        id: 'dados',
        value: data.codemp ? {
          codemp: data.codemp,
          nomemp: data.nomemp,
        } : null,
        required: true,
      },
      {
        id: 'equip',
        value: data.codeqp ? {
          codeqp: data.codeqp,
          deseqp: data.deseqp,
        } : null,
        required: true,
      },
      {
        id: 'manutencao',
        value: data.nummnt ? {
          nummnt: data.nummnt,
        } : null,
        required: true,
      },
      {
        id: 'datapt',
        value: {
          datapt: data.datapt ? `${data.datapt}T${data.horapt}` : new Date().toISOString().slice(0, -8),
        },
        required: true,
      },
      {
        id: 'turtrb',
        value: data.turtrb ? {
          turtrb: data.turtrb,
        } : null,
        required: true,
      },
      {
        id: 'operador',
        value: data.numcad ? {
          numcad: data.numcad,
          nomope: data.descad,
        } : null,
        required: true,
      },
      {
        id: 'estagio',
        value: data.codetg ? {
          codetg: data.codetg,
          desetg: data.desetg,
        } : null,
        required: true,
      },
      {
        id: 'sequencia',
        value: data.seqrot ? {
          seqrot: data.seqrot,
        } : null,
        required: true,
      },
      {
        id: 'fimoop',
        value: data.fimoop ? {
          fimoop: data.fimoop,
        } : {
          fimoop: 'S',
        },
        required: true,
      },
      {
        id: 'obsapt',
        value: data.obsapt ? {
          obsapt: data.obsapt,
        } : null,
        required: false,
      },
    ];
}