import { Collapse, List, ListItem, ListItemIcon, ListItemText, makeStyles } from "@material-ui/core";
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useLocation, useNavigate } from "react-router-dom";
import { customtheme } from "../../../app/theme/theme";

const useStyles = makeStyles((theme) => ({
  listItem: {
    '&.Mui-selected, &:hover': {
      backgroundColor: customtheme.blue[500],
      color: customtheme.white,
    },
    '&.Mui-selected:hover': {
      background: customtheme.blue[50],
      color: customtheme.blue[500],
    },
  },
  listItemMenu: {
    '&, &:hover': {
      backgroundColor: customtheme.blue[500],
      color: customtheme.white,
    },
    '&': {
      background: customtheme.blue[50],
      color: customtheme.blue[500],
    },
  },
}))

interface MenuItemProps {
    to: string;
    icon: React.ReactNode;
    text: string; 
    submenu?: React.ReactNode;
    expanded: string;
    onClick: (item: string) => void;
}

export const MenuItem: React.FC<MenuItemProps> = ({
    to,
    icon,
    text,
    submenu,
    expanded,
    onClick
  }) => {
    const location = useLocation();

    const handleActive = () => {
      if(to === '/')
        return false
      else {
        if(location.pathname.includes('consulta')){
          return location.pathname === to
        }
        return location.pathname === to
      }
    }

    const isActive = handleActive();
    const isExpanded = expanded === text;
    const navigate = useNavigate();
    const classes = useStyles();

    const handleClick = () => {
        if (submenu) {
            onClick(text);
        } else {
            navigate(to);
        }
    };

    return (
      <List component={'div'}>
        <ListItem 
          button
          onClick={handleClick}
          selected={isActive}
          className={submenu && isExpanded ? classes.listItemMenu : classes.listItem}
          // style={{
          //   backgroundColor: isActive ? customtheme.blue[500] : 'inherit',
          //   color: isActive ? customtheme.white : 'inherit'
          // }}
        >
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText primary={text} />
          {submenu && (isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />)}
        </ListItem>
        {
          submenu && (
            <Collapse in={isExpanded} timeout="auto" unmountOnExit ref={undefined}>
              <List component="div" disablePadding >
                {submenu}
              </List>
            </Collapse>
          )
        }
      </List>
    );
  };