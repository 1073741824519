import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import { TextField, Typography, Box } from '@material-ui/core';
import { customtheme } from '../../../app/theme/theme';

const useStyles = makeStyles((theme) => ({
  card: {
    marginBottom: theme.spacing(2),
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  title: {
    color: customtheme.blue[500],
  },
  textField: {
    margin: theme.spacing(1),
  },
  stringTextField: {
    width: 200,
  },
  numberTextField: {
    width: 96,
  },
}));

interface ItemsDrawerProps {
  fields: any;
  fieldsItems: Array<string>;
  labelsFields: Array<{
    key: string,
    value: string
  }>;
  keyTitle: string;
  keySubTitle: string;
}

const ItemsDrawer = ({ fields, keyTitle, keySubTitle, fieldsItems, labelsFields }: ItemsDrawerProps) => {
  const classes = useStyles();
  // const theme = useTheme();
  
  return (
    <>
      {fields.map((field: any, index: number) => (
        <React.Fragment key={index}>
          <Card className={classes.card}>
            <CardContent>
              <Typography variant="h6" className={classes.title}>
                {field[keyTitle]}
              </Typography>
              <Box>
                {
                  Object.entries(field).map(([key, val]) => {
                    if (fieldsItems.includes(key) && key !== keyTitle) {
                      const isNumber = typeof val === 'number';
                      const textFieldClass = isNumber ? classes.numberTextField : classes.stringTextField;
                      const label = labelsFields.filter((label) => label.key === key)[0] || {}

                      if (isNumber){
                        const valNumber = val as number
                        val = valNumber.toFixed(2);
                      }
                      
                      return (
                        <TextField
                          key={key}
                          label={label?.value}
                          onClick={() => {}}
                          value={`${val || ''}`.replace('.',',')}
                          disabled
                          className={`${classes.textField} ${textFieldClass}`}
                          InputLabelProps={{
                            style: {
                              // color: customtheme.blue[500],
                            }
                          }}
                          InputProps={{
                            style: {
                              marginTop: 24,
                            },
                          }}
                        />
                      );
                    }
                    return null;
                  })
                }
              </Box>
            </CardContent>
          </Card>
          {index < fields.length - 1 && <Divider className={classes.divider} />}
        </React.Fragment>
      ))}
    </>
  );
};

export default ItemsDrawer;
