import {
  Button,
  Container,
  Drawer,
  Grid,
  IconButton,
  Tooltip,
  Typography
} from '@material-ui/core';

import Snackbar from '@material-ui/core/Snackbar';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import ErrorIcon from '@material-ui/icons/Error';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { getErp } from '../../app/services/erp/get-erp.api';
import { createPurshaseOrderApproved, createPurshaseOrderDisapproved } from '../../app/services/purchase-order/create-purchase-order.api';
import EnhancedTable, { HeadCell } from '../../common/components/EnhancedTable';
import ItemsDrawer from '../../common/components/ItemsDrawer';
import PurchaseOrderEmitDialog from './PurchaseOrderEmitDialog';
import PurchaseOrderEmitHeader from './PurchaseOrderEmitHeader';
import { customtheme } from '../../app/theme/theme';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: customtheme.gray[200],
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  topBar: {
    backgroundColor: customtheme.gray[200],
    padding: theme.spacing(2),
    paddingTop: theme.spacing(4),
  },  
  topBarContent: {   
    flex: 1,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  topBarButtons: {
    '& > *': {
      marginLeft: theme.spacing(1),
    },
  },
  content: {
    // position: 'relative',
    // flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  contentBackground: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: '50%',
    backgroundColor: customtheme.white,
  },
  card: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  tableSection: {
    padding: theme.spacing(4),
    flex: 1,
    display: 'flex',
  },
  tableSectionContainer: {
    padding: 0, 
    flex: 1,
    display: 'flex', 
    flexDirection: 'column',
    maxWidth: '100%',
  },
  appbar: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    background: customtheme.white,
    color: customtheme.black,
    padding: theme.spacing(2),
    margin: 1,
  },  
  scrollableTabs: {
    overflowX: 'auto',
  }, drawer: {
    width: 400,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.divider}`,
    background: customtheme.gray[200],
  },
  drawerContent: {
    overflowY: 'auto',
    padding: theme.spacing(2), 
    height: '100%',
  },
  drawerFooter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(2),
    borderTop: `1px solid ${theme.palette.divider}`,
    background: customtheme.gray[200],
  },
  textField: {
    minWidth: 140,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    '& .MuiInputBase-input': {
      color: customtheme.black,
    },
    '& .MuiInput-underline:hover:before': {
      borderBottomColor: customtheme.blue[500],
    },
  },
  searchIcon: {
    marginRight: theme.spacing(1),
    color: customtheme.blue[500],
  },
  loadingIcon: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    marginTop: theme.spacing(2),
  },
  backgroundColor: {
    background: customtheme.blue[500],
    color: customtheme.white,
    '&:hover': {
      background: customtheme.blue[50],
      color: customtheme.blue[500],
    },
  },
  backBackgroundColor: {
    background: customtheme.gray[200],
    color: customtheme.blue[500],
    '&:hover': {
      background: customtheme.blue[50],
      color: customtheme.blue[500],
    },
  },
}));

const PurchaseOrderEmit = () => {
  const classes = useStyles();

  const windowHeight = window.innerHeight;
  const headerHeight = 500; // Altura do cabeçalho em pixels
  const footerHeight = 48; // Altura do rodapé em pixels
  const itemHeight = 80; // Altura média de cada item em pixels
  const availableHeight = windowHeight - headerHeight - footerHeight;
  const itemsPerPage = Math.floor((availableHeight) / itemHeight)

  const [selectedData, setSelectedData] = useState<any>([]);
  const [orderBy, setOrderBy] = useState<string>('numero');
  const [selected, setSelected] = useState<string[]>([]);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(itemsPerPage > 5 ? itemsPerPage : 5);
  const [isDrawerOpen, setIsDrawerOpen] = useState({open: false, btnTitle: 'Voltar', btnType: 'back'});
  const [isDialogFeedbackOpen, setIsDialogFeedbackOpen] = useState({open:false, message: '', title: '', btnType: ''});
  const [isSnackbar, setIsSnackbar] = useState({open:false, message: 'info', title: ''});
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState<string>('')
  const [reasonCancel, setReasonCancel] = useState<string>('')
  
  const fieldsItems = ['descricao', 'observacao', 'quantidadesolicitada', 
    'valorliquido', 'valorliquidomoeda', 'valorunitario', 'vlrbru', 'vlrlpr', 
  ];

  const [fields, setFields] = useState<any[]>([])
  const headCells: HeadCell[] = [
    { id: 'numero', numeric: true, disablePadding: false, label: 'Número' },
    { id: 'nomefornecedor', numeric: false, disablePadding: true, label: 'Nome Fornecedor' },
    { id: 'valoraproximado', numeric: true, disablePadding: false, label: 'Valor Aproximado' },
    { id: 'dataemissao', numeric: false, disablePadding: true, label: 'Data Emissão' },
    { id: 'actions', numeric: true, disablePadding: false, label: 'Ações' },
  ];

  const createData = (props:any) => {
    const { id, numero, nomefornecedor, valoraproximado, dataemissao} = props
    return { id, numero, nomefornecedor, valoraproximado, dataemissao, ...props };
  }

  const handleCloseDrawer = () => {
    setSelected([])
    setFields([]);
    setIsDrawerOpen({open: false, btnTitle: 'Salvar', btnType: 'save'});
  };

  const handleCloseSnackbar = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setIsSnackbar({open: false, message: '', title: 'info'});
  };

  const handleCloseErro = () => {
    setIsDialogFeedbackOpen({open:false, message: '', title: '', btnType: 'erro'});
    setSelected([])
    setFields([]);
    setReasonCancel('');
  }

  const paginateArray = (array: Array<any>) => {
    const startIndex =  page * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    return array.slice(startIndex, endIndex);
  }

  const { isFetching, refetch } = useQuery(['purchaseOrderEmit'], async () => {
    // obtem as requisicao rascunho
    const token = localStorage.getItem('token')
    if(token){
      
      const res = await getErp({route: 'ordemCompra', idSystem: 'PORTAL', page: page, numitem: 1000} ,token)
      if(res){
        const filtered = res.filter((r:any) => {
          if (`${r.numero}`.includes(search) || search === '')
            return true
          return false
        })
        const paginate = paginateArray(filtered)
        setSelectedData(paginate.map((p:any) => ({
          ...p, 
          id:`${p.numero}`, 
          item: setLowerKeys(!Array.isArray(p.item) ? [p.item]: p.item)
        })));
        setCount(res.length)
      }   
    }
    return []
  }, {
    refetchOnWindowFocus: false,
  })
  
  useEffect(() => {
    refetch();
  }, [page, refetch]);

  const Alert = (props: AlertProps) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const setLowerKeys = (array: Array<any>) => {
    return array.map((r: any) =>
          Object.entries(r).reduce((acc:any, [key, value]:any) => {
            acc[key.toLowerCase()] = value;
            return acc;
          }, {}),
        );
  }

  const handleFormatedDataToDatabase = async (btnType: string) => {
    setIsLoading(true)
    
    const useCaseSuccess = {
      approved: 'aprovar',
      approvedAll: 'aprovar',
      disapproved: 'reprovar',
      disapprovedAll: 'reprovar'
    } as any
    const token = localStorage.getItem('token')
    let res = {
      statusCode: 400,
    } as any
    if(token){
      if(btnType === 'approved' || btnType === 'approvedAll')
        res = await createPurshaseOrderApproved(selectedData.filter((data:any) => selected.find((sel) => sel === data.id)), token)
      if(btnType === 'disapproved' || btnType === 'disapprovedAll')
        res = await createPurshaseOrderDisapproved(
          selectedData
            .filter((data:any) => selected.find((sel) => sel === data.id))
            .map((data:any) => ({...data, reason: reasonCancel})),
          token
        )
        
      setIsLoading(false)
      setReasonCancel('')
      if(res.statusCode === 200){
        setIsSnackbar({
          open: true,
          message: `Sucesso ao ${useCaseSuccess[btnType]} ordem de compra`,
          title: 'success'
        })
        refetch()
      } else {
        setIsSnackbar({
          open: true,
          message: `Erro ao ${useCaseSuccess[btnType]} ordem de compra`,
          title: 'error'
        })
      }
      setIsDialogFeedbackOpen({
        open: false,
        message: '',
        title: '',
        btnType,
      })
      setIsDrawerOpen({
        open: false,
        btnTitle: '',
        btnType: '',
      })
      setSelected([])
    }
  } 

  const handleInfo = (id:any) => {
    const item = selectedData.filter((data:any) => data.id === id )
    if(item.length > 0){
      setFields(item[0].item);
      setIsDrawerOpen({open: true, btnTitle: 'Info', btnType: 'info'});
    }
  }

  const handleDisapprovedAll = () => {
    handleDialogConfirmation('disapprovedAll') 
  }
  
  const handleApprovedAll = () => {
    handleDialogConfirmation('approvedAll') 
  }

  const handleDisapproved = (id:any) => {
    handleDialogConfirmation('disapproved')
  }

  const handleApproved = (id:any) => {
    handleDialogConfirmation('approved')
  }

  const handleDialogConfirmation = (type: string) => {
    const useCaseFeedbackTitle = {
      approved: 'Deseja aprovar ordem',
      disapproved: 'Deseja reprovar ordem',
      approvedAll: 'Deseja aprovar todas as ordem',
      disapprovedAll: 'Deseja reprovar todas as ordem',
    } as any

    setIsDialogFeedbackOpen({
      open: true,
      title: useCaseFeedbackTitle[type],
      message: '',
      btnType: type,
    })
  }

  const handleCheckItems = () => {
    const itemSelected = selectedData.filter((data:any) => data.id === selected[0])
    if(itemSelected){
      const items = Object.entries(itemSelected[0]).find(([key, val]:any) => key === 'item' && val.length > 0)
      if(items && items[1] !== null)
        return true
      else
        return false
    }
    return false
  }

  return (
    <div className={classes.root}>
      <PurchaseOrderEmitHeader
        refetch={refetch}
        search={search}
        setSearch={setSearch}
      />
      <div className={classes.tableSection}>
        <Container className={classes.tableSectionContainer}>
          <EnhancedTable 
            title='Ordens'
            rowsPerPageOptions={[]}
            isClickable
            data={selectedData?.map((data:any) => createData(data) )}
            count={count}
            orderBy={orderBy}
            setOrderBy={setOrderBy}
            headCells={headCells}
            isLoading={isFetching}
            selected={selected}
            setSelected={setSelected}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            buttonsSelected={[ 
              selected.length === 1 && 
                <Tooltip title="Aprovar" onClick={handleApproved} key='approved'>
                  <IconButton aria-label="aprovar">
                    <DoneIcon style={{color: customtheme.white}}/>
                  </IconButton>
                </Tooltip>,
              selected.length === 1 && 
                <Tooltip title="Reprovar" onClick={handleDisapproved} key='disapproved'>
                  <IconButton aria-label="reprovar">
                    <CloseIcon style={{color: customtheme.white}}/>
                  </IconButton>
                </Tooltip>,
              (selected.length === 1 && handleCheckItems()) && 
                <Tooltip
                  title="Info"
                  onClick={() => handleInfo(`${selected[0]}`)}
                  key='info'
                >
                <IconButton aria-label="info">
                  <ErrorIcon style={{color: customtheme.white}}/>
                </IconButton>
              </Tooltip>,
              selected.length > 1 && 
                <Tooltip title="Aprovar Todos" onClick={handleApprovedAll} key='approvedAll'>
                  <IconButton aria-label="aprovar-todos">
                    <DoneAllIcon style={{color: customtheme.white}}/>
                  </IconButton>
                </Tooltip>,
              selected.length > 1 && 
                <Tooltip title="Reprovar Todos" onClick={handleDisapprovedAll} key='disapprovedAll'>
                  <IconButton aria-label="reprovar-todos">
                    <CloseIcon style={{color: customtheme.white}}/>
                  </IconButton>
                </Tooltip>,
            ]}
            buttonsActions={['approved', 'disapproved', 'info']}
            handleDisapproved={handleDisapproved}
            handleInfo={handleInfo}
            handleApproved={handleApproved}
          />
        </Container>
      </div>
      <Drawer
        anchor="right"
        open={isDrawerOpen.open}
        onClose={handleCloseDrawer}
        classes={{ paper: classes.drawer }}
      >
        <div className={classes.drawerHeader}>
          <Typography variant="h6">Itens</Typography>
        </div>
        <div className={classes.drawerContent}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {
                <ItemsDrawer
                  fields={fields} 
                  fieldsItems={fieldsItems}
                  keyTitle='descricao'
                  keySubTitle=''
                  labelsFields={
                    [
                      {
                        key: 'observacao',
                        value: 'Observação',
                      },
                      {
                        key: 'quantidadesolicitada',
                        value: 'Quantidade',
                      },
                      {
                        key: 'valorliquido',
                        value: 'Valor Liquido',
                      },
                      {
                        key: 'valorliquidomoeda',
                        value: 'Valor Liquido Moeda',
                      },
                      {
                        key: 'valorunitario',
                        value: 'Valor Unitário',
                      },
                      {
                        key: 'vlrbru',
                        value: 'Valor Bruto',
                      },
                      {
                        key: 'vlrlpr',
                        value: 'Valor Liquido',
                      },
                    ]
                  }
                />
              }
            </Grid>
          </Grid>
        </div>
        <div className={classes.drawerFooter}>
          <Button
            onClick={handleCloseDrawer}
            className={classes.backBackgroundColor}
          >
            Voltar
          </Button>
        </div>
      </Drawer>
      <PurchaseOrderEmitDialog
        handleCloseErro={handleCloseErro}
        handleFormatedDataToDatabase={handleFormatedDataToDatabase}
        isDialogFeedbackOpen={isDialogFeedbackOpen}
        isLoading={isLoading}
        reasonCancel={reasonCancel}
        setReasonCancel={setReasonCancel}
      />
      <Snackbar open={isSnackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={isSnackbar.title as any}>
          {isSnackbar.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default PurchaseOrderEmit;