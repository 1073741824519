import { Button, Drawer, FormControl, FormControlLabel, FormLabel, Grid, IconButton, InputLabel, NativeSelect, Radio, RadioGroup, TextField, Typography, makeStyles } from "@material-ui/core";
import { handleReturnValue } from '../../../common/functions/returnValue';
import SearchIcon from '@material-ui/icons/Search';
import { customtheme } from "../../../app/theme/theme";

const useStyles = makeStyles((theme) => ({
    drawer: {
        width: 400,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: theme.spacing(2),
        borderBottom: `1px solid ${theme.palette.divider}`,
        background: customtheme.gray[200],
    },
    drawerContent: {
        overflowY: 'auto',
        padding: theme.spacing(2), 
        height: '100%',
    },
    drawerFooter: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(2),
        borderTop: `1px solid ${theme.palette.divider}`,
        background: customtheme.gray[200],
    },
    textField: {
        minWidth: 140,
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        '& .MuiInputBase-input': {
          color: customtheme.black,
        },
        '& .MuiInput-underline:hover:before': {
          borderBottomColor:customtheme.blue[500],
        },
    },
    searchIcon: {
        marginRight: theme.spacing(1),
        color:customtheme.blue[500],
    },
    loadingIcon: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        marginTop: theme.spacing(2),
    },
    backgroundColor: {
      background: customtheme.blue[500],
      color: customtheme.white,
      '&:hover': {
        background: customtheme.blue[50],
        color: customtheme.blue[500],
      },
    },
    backBackgroundColor: {
        background: customtheme.gray[200],
        color: customtheme.blue[500],
        '&:hover': {
        background: customtheme.blue[50],
        color: customtheme.blue[500],
        },
    },
    selectFieldForm: {
      width: '100%',
      // minWidth: 160,
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    selectField: {
      '& .MuiNativeSelect-select': {
        marginTop: theme.spacing(1),
      },
    },
}))

interface DialogSearchData {
    id: string;
    title: string;
    key: string;
    keySub: string;
    isPrevSearch?: boolean;
    prevSearch?: any;
    prevDataSearch: any;
}

interface MaintenanceRequestDrawerProps {
    handleCloseDrawer: () => void;
    handleOpenDialogSearch: (data: DialogSearchData) => void;
    handleCheckFields: () => void;
    handleCloseSnackbar: ( event?: React.SyntheticEvent, reason?: string ) => void;
    isDrawerOpen: { open: boolean, btnTitle: string, btnType: string };
    fields: any[];
    setFields: ( array: any[] ) => void;
    checkPrevCompanyValue: { exists: boolean, value: any };
    checkPrevTipoValue: { exists: boolean, value: any };
    setCheckPrevTipoValue: ({exists, value}: { exists: boolean, value: any}) => void;
}

const MaintenanceRequestDrawer = (props: MaintenanceRequestDrawerProps) => {
    const classes = useStyles()
    const { 
        isDrawerOpen, fields, checkPrevCompanyValue, 
        checkPrevTipoValue, setCheckPrevTipoValue,
        setFields, handleCheckFields, handleCloseDrawer, handleOpenDialogSearch
    } = props;

    const setLowerKeys = (array: Array<any>) => {
        const arrayChecked = !Array.isArray(array)
        ? [array]
        : array;
        return arrayChecked.map((r: any) =>
            Object.entries(r).reduce((acc:any, [key, value]:any) => {
                acc[key.toLowerCase()] = value;
                return acc;
            }, {}),
            );
    }

    return (
        <>
            <Drawer
                anchor="right"
                open={isDrawerOpen.open}
                onClose={handleCloseDrawer}
                classes={{ paper: classes.drawer }}
            >
                <div className={classes.drawerHeader}>
                <Typography variant="h6">Adicionar Item</Typography>
                </div>
                <div className={classes.drawerContent}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            label="Empresa"
                            onClick={() => handleOpenDialogSearch({
                            id: 'dados',
                            title: 'Empresa',
                            keySub: 'nomemp',
                            key: 'codemp',
                            prevDataSearch: {},
                            })}
                            value={
                                handleReturnValue('dados', fields)                 
                            }
                            fullWidth
                            className={classes.textField}
                            InputProps={{
                            endAdornment: (
                                <IconButton
                                className={classes.searchIcon}
                                onClick={() => handleOpenDialogSearch({
                                    id: 'dados',
                                    title: 'Empresa',
                                    keySub: 'nomemp',
                                    key: 'codemp',
                                    prevDataSearch: {},
                                    })}>
                                <SearchIcon />
                                </IconButton>
                            ),
                            }}
                        />
                        <TextField
                            label="Filial"
                            onClick={
                            () => checkPrevCompanyValue.exists ? 
                                handleOpenDialogSearch({
                                id: 'filiais', 
                                title: 'Filial', 
                                keySub: 'nomfil', 
                                key: 'codfil',
                                isPrevSearch: true,
                                prevSearch: setLowerKeys(fields.filter((field) => field.id === 'dados')[0].value?.filiais || []),
                                prevDataSearch: {},
                                }) 
                                : () => {}
                            }
                            value={
                                checkPrevCompanyValue.exists ?
                                    handleReturnValue('filiais', fields)
                                : 'Selecione uma empresa primeiro'
                            }
                            fullWidth
                            className={classes.textField}
                            disabled={
                            checkPrevCompanyValue.exists ? false : true
                            }
                            InputProps={{
                            endAdornment: (
                                <IconButton
                                className={classes.searchIcon}
                                onClick={() => checkPrevCompanyValue.exists ? 
                                    handleOpenDialogSearch({
                                    id: 'filiais', 
                                    title: 'Filial', 
                                    keySub: 'nomfil', 
                                    key: 'codfil',
                                    isPrevSearch: true,
                                    prevSearch: setLowerKeys(fields.filter((field) => field.id === 'dados')[0].value?.filiais || []),
                                    prevDataSearch: {},
                                    }) : 
                                    () => {}
                                }
                                disabled={
                                    checkPrevCompanyValue.exists ? false : true
                                }
                                >
                                <SearchIcon />
                                </IconButton>
                            ),
                            }}
                        />
                        <>
                            <FormLabel component="legend">Tipo Solicitação</FormLabel>
                            <RadioGroup
                            aria-label="tipo"
                            name="tipo"
                            value={fields.filter((field) => field.id === 'tipo')[0].value?.tipo || 'Nenhum item selecionado'} 
                            onChange={(event) => {
                                const update = [...fields]
                                update[update.findIndex((up) => up.id === 'tipo')].value = {
                                tipo: event.currentTarget.value
                                }
                                setCheckPrevTipoValue({
                                    exists: true,
                                    value: event.currentTarget.value,
                                })
                                setFields(update)
                            }}
                            > 
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <FormControlLabel value="equipamento" control={<Radio style={{color: customtheme.blue[500]}} />} label="Equipamento" />
                                <FormControlLabel value="centrocusto" control={<Radio style={{color: customtheme.blue[500]}} />} label="Centro Custo" />
                            </div>
                            </RadioGroup>
                        </>
                        <TextField
                            label="Equipamento"
                            onClick={
                            checkPrevTipoValue.value === 'equipamento' ? 
                                checkPrevCompanyValue.exists ? 
                                () => handleOpenDialogSearch({
                                    id: 'equip',
                                    title: 'Equipamento',
                                    keySub: 'deseqp',
                                    key: 'codeqp',
                                    prevDataSearch: {
                                    codemp: checkPrevCompanyValue.value,
                                    }
                                })
                                : () => {}
                            : () => {}
                            }
                            value={
                            checkPrevTipoValue.value === 'equipamento' ?
                                checkPrevCompanyValue.exists ?  
                                handleReturnValue('equip', fields)  
                                : 'Selecione uma empresa primeiro'
                            : 'Não selecionavel'
                            }
                            fullWidth
                            className={classes.textField}
                            disabled={
                            checkPrevTipoValue.value === 'equipamento' ? 
                                checkPrevCompanyValue.exists ? 
                                false
                                : true 
                            : true
                            }
                            InputProps={{
                            endAdornment: (
                                <IconButton
                                className={classes.searchIcon}
                                onClick={
                                    checkPrevTipoValue.value === 'equipamento' ? 
                                    checkPrevCompanyValue.exists ? 
                                        () => handleOpenDialogSearch({
                                        id: 'equip',
                                        title: 'Equipamento',
                                        keySub: 'deseqp',
                                        key: 'codeqp',
                                        prevDataSearch: {
                                            codemp: checkPrevCompanyValue.value,
                                        }
                                        })
                                        : () => {}
                                    : () => {}
                                }

                                disabled={
                                    checkPrevTipoValue.value === 'equipamento' ?
                                    checkPrevCompanyValue.exists ? 
                                    false
                                    : true
                                    : true
                                }
                                >
                                <SearchIcon />
                                </IconButton>
                            ),
                            }}
                        />
                        <TextField
                            label="Centro de Custo"
                            onClick={
                            checkPrevTipoValue.value === 'centrocusto' ?
                                checkPrevCompanyValue.exists ? 
                                () => handleOpenDialogSearch({
                                    id: 'centrosCusto', 
                                    title: 'Centro de Custo', 
                                    keySub: 'desccu',
                                    key: 'codccu',
                                    prevDataSearch: {
                                    codemp: checkPrevCompanyValue.value,
                                    codfil: fields.filter((field) => field.id === 'filiais')[0].value?.codfil,
                                    },
                                }) 
                                : () => {}
                            : () => {}
                            }
                            value={
                            checkPrevTipoValue.value === 'centrocusto' ?
                                checkPrevCompanyValue.exists ? 
                                handleReturnValue('centrosCusto', fields)  
                                : 'Selecione uma empresa primeiro'
                            : 'Não selecionavel'
                            }
                            fullWidth
                            className={classes.textField} 
                            disabled={
                            checkPrevTipoValue.value === 'centrocusto' ?
                                checkPrevCompanyValue.exists ? 
                                false
                                : true
                            : true
                            }
                            InputProps={{
                            endAdornment: (
                                <IconButton
                                className={classes.searchIcon} 
                                onClick={
                                    checkPrevTipoValue.value === 'centrocusto' ?
                                    checkPrevCompanyValue.exists ? 
                                        () => handleOpenDialogSearch({
                                        id: 'centrosCusto',
                                        title: 'Centro de Custo', 
                                        keySub: 'desccu',
                                        key: 'codccu',
                                        prevDataSearch: {
                                            codemp: checkPrevCompanyValue.value,
                                            codfil: fields.filter((field) => field.id === 'filiais')[0].value?.codfil,
                                        },
                                        })
                                        : () => {}
                                    : () => {}
                                }
                                disabled={
                                    checkPrevTipoValue.value === 'centrocusto' ?
                                    checkPrevCompanyValue.exists ? 
                                        false
                                        : true
                                    : true
                                }
                                >
                                <SearchIcon />
                                </IconButton>
                            ),
                            }}
                        />
                        <FormControl className={classes.selectFieldForm}>
                            <InputLabel shrink htmlFor="age-native-label-placeholder">
                                Tipo Manutenção
                            </InputLabel>
                            <NativeSelect
                                className={classes.selectField}
                                value={fields.filter((field) => field.id === 'tipmnt')[0].value?.tipmnt || ''}
                                onChange={
                                    (event) => {
                                    const update = [...fields]
                                    update[update.findIndex((up) => up.id === 'tipmnt')].value = !event.currentTarget.value.includes('Nenhum item') ? 
                                    {
                                        tipmnt: event.currentTarget.value
                                    } : undefined
                                    setFields(update)
                                    }
                                }
                                inputProps={{
                                    name: 'age',
                                    id: 'age-native-label-placeholder',
                                }}
                            >
                            <option value={undefined}>Nenhum item selecionado</option>
                            <option value={'P'}>Preventiva</option>
                            <option value={'C'}>Corretiva</option>
                            <option value={'E'}>Preditiva</option>
                            </NativeSelect>
                            {/* <FormHelperText>Label + placeholder</FormHelperText> */}
                        </FormControl>

                        <FormControl className={classes.selectFieldForm}>
                            <InputLabel shrink htmlFor="age-native-label-placeholder">
                                Obtenção Manutenção
                            </InputLabel>
                            <NativeSelect
                            className={classes.selectField}
                            value={fields.filter((field) => field.id === 'obtmnt')[0].value?.obtmnt || undefined}
                            onChange={
                                (event) => {
                                    const update = [...fields]
                                    update[update.findIndex((up) => up.id === 'obtmnt')].value = !event.currentTarget.value.includes('Nenhum item') ? 
                                    {
                                        obtmnt: event.currentTarget.value
                                    } : undefined
                                    setFields(update)
                                }
                            }
                            inputProps={{
                                name: 'age',
                                id: 'age-native-label-placeholder',
                            }}
                            >
                            <option value={undefined}>Nenhum item selecionado</option>
                            <option value={'P'}>Produzido</option>
                            <option value={'C'}>Comprado</option>
                            </NativeSelect>
                        </FormControl>
                        <TextField
                            label="Serviço"
                            onClick={
                            checkPrevCompanyValue.exists ? 
                                () => handleOpenDialogSearch({
                                id: 'servico', 
                                title: 'Serviço',
                                keySub: 'desser',
                                key: 'codser',
                                prevDataSearch: {
                                    codemp: checkPrevCompanyValue.value,
                                    codfil: fields.filter((field) => field.id === 'filiais')[0].value?.codfil,
                                }
                                })
                            : () => {}  
                            }
                            value={
                            checkPrevCompanyValue.exists ?  
                                handleReturnValue('servico', fields)
                            : 'Selecione uma empresa primeiro'
                            }
                            disabled={
                            checkPrevCompanyValue.exists ? 
                                false
                                : true
                            }
                            fullWidth
                            className={classes.textField}
                            InputProps={{
                            endAdornment: (
                                <IconButton 
                                className={classes.searchIcon} 
                                onClick={
                                    checkPrevCompanyValue.exists ? 
                                    () => handleOpenDialogSearch({
                                        id: 'servico', 
                                        title: 'Serviço',
                                        keySub: 'desser',
                                        key: 'codser',
                                        prevDataSearch: {
                                        codemp: checkPrevCompanyValue.value,
                                        codfil: fields.filter((field) => field.id === 'filiais')[0].value?.codfil,
                                        }
                                    })
                                    : () => {}
                                }
                                disabled={
                                    checkPrevCompanyValue.exists ? 
                                    false
                                    : true
                                }
                                >
                                <SearchIcon />
                                </IconButton>
                            ),
                            }}
                        />
                        <TextField
                            label="Categoria"
                            onClick={
                            checkPrevCompanyValue.exists ? 
                                () => handleOpenDialogSearch({
                                id: 'categoria',
                                title: 'Categoria',
                                keySub: 'descat',
                                key: 'codcat',
                                prevDataSearch: {
                                    codemp: checkPrevCompanyValue.value,
                                    codfil: fields.filter((field) => field.id === 'filiais')[0].value?.codfil,
                                }
                                })
                                : () => {}
                            }
                            value={
                            checkPrevCompanyValue.exists ? 
                                handleReturnValue('categoria', fields)
                            : 'Selecione uma empresa primeiro'
                            }
                            disabled={
                            checkPrevCompanyValue.exists ? 
                                false
                                : true
                            }
                            fullWidth
                            className={classes.textField}
                            InputProps={{
                            endAdornment: (
                                <IconButton 
                                className={classes.searchIcon} 
                                onClick={
                                    checkPrevCompanyValue.exists ? 
                                    () => handleOpenDialogSearch({
                                        id: 'categoria',
                                        title: 'Categoria',
                                        keySub: 'descat',
                                        key: 'codcat',
                                        prevDataSearch: {
                                        codemp: checkPrevCompanyValue.value,
                                        codfil: fields.filter((field) => field.id === 'filiais')[0].value?.codfil,
                                        }
                                    })
                                    : () => {}
                                }
                                disabled={
                                    checkPrevCompanyValue.exists ? 
                                    false
                                    : true
                                }
                                >
                                <SearchIcon />
                                </IconButton>
                            ),
                            }}
                        />
                        <TextField
                            label="Prioridade"
                            onClick={
                            checkPrevCompanyValue.exists ? 
                                () => handleOpenDialogSearch({
                                id: 'prioridade',
                                title: 'Prioridade',
                                keySub: 'despri',
                                key: 'codpri',
                                prevDataSearch: {
                                    codemp: checkPrevCompanyValue.value,
                                    codfil: fields.filter((field) => field.id === 'filiais')[0].value?.codfil,
                                }
                                })
                                : () => {}
                            }
                            value={
                            checkPrevCompanyValue.exists ? 
                                handleReturnValue('prioridade', fields)
                            : 'Selecione uma empresa primeiro'
                            }
                            disabled={
                            checkPrevCompanyValue.exists ? 
                                false
                                : true
                            }
                            fullWidth
                            className={classes.textField}
                            InputProps={{
                            endAdornment: (
                                <IconButton 
                                className={classes.searchIcon} 
                                onClick={
                                    checkPrevCompanyValue.exists ? 
                                    () => handleOpenDialogSearch({
                                        id: 'prioridade',
                                        title: 'Prioridade',
                                        keySub: 'despri',
                                        key: 'codpri',
                                        prevDataSearch: {
                                        codemp: checkPrevCompanyValue.value,
                                        codfil: fields.filter((field) => field.id === 'filiais')[0].value?.codfil,
                                        }
                                    })
                                    : () => {}
                                }
                                disabled={
                                    checkPrevCompanyValue.exists ? 
                                    false
                                    : true
                                }
                                >
                                <SearchIcon />
                                </IconButton>
                            ),
                            }}
                        />
                        <TextField
                            id="date"
                            label="Data Previsão Inicio"
                            type="datetime-local"
                            value={fields.filter((field) => field.id === 'datainicial')[0].value?.datpri}
                            onChange={(event) => {
                            const update = [...fields]
                            update[update.findIndex((up) => up.id === 'datainicial')].value = {
                                datpri: event.currentTarget.value
                            }
                            setFields(update)
                            }}
                            className={classes.textField}
                            InputLabelProps={{
                            shrink: true,
                            }}
                        />
                        <TextField
                            id="date"
                            label="Data Previsão Fim"
                            type="datetime-local"
                            value={fields.filter((field) => field.id === 'datafim')[0].value?.datprf}
                            onChange={(event) => {
                            const update = [...fields]
                            update[update.findIndex((up) => up.id === 'datafim')].value = {
                                datprf: event.currentTarget.value
                            }
                            setFields(update)
                            }}
                            className={classes.textField}
                            InputLabelProps={{
                            shrink: true,
                            }}
                        />
                        <TextField
                            label="Observação"
                            value={fields.filter((field) => field.id === 'observacao')[0].value?.desmnt || ''}
                            className={classes.textField}
                            onChange={(event) => {
                            const update = [...fields]
                            update[update.findIndex((up) => up.id === 'observacao')].value = {
                                desmnt: event.target.value
                            }
                            setFields(update)
                            }}
                            fullWidth
                        />
                    </Grid>
                </Grid>
                </div>
                <div className={classes.drawerFooter}>
                <Button
                    onClick={handleCloseDrawer}
                    className={classes.backBackgroundColor}
                    style={{marginRight: 20}}
                >
                    Cancelar
                </Button>
                <Button 
                    onClick={() => {
                    handleCheckFields()
                    }} 
                    className={classes.backgroundColor}
                >
                    {isDrawerOpen.btnTitle}
                </Button>
                </div>
            </Drawer>  
        </>
    )
}

export default MaintenanceRequestDrawer;