import axios, { AxiosResponse } from "axios";

export async function getErp(data: any, token: string): Promise<any> {
  try {
    const response: AxiosResponse = await axios.post(
      process.env.REACT_APP_BASE_URL + "/erp/xml",
      data,
      {
        headers: {
          'Authorization': 'Bearer ' + token,
          'Access-Control-Allow-Headers': 'Authorization',
        }
      }
    )
    return response.data;
  } catch(error) {
    throw new Error('Falha ao buscar dados do erp');
  };
}