import { makeStyles } from '@material-ui/core/styles';
import { VerticalBarChart } from '../../../common/components/VerticalBarChart';
import { useQuery } from '@tanstack/react-query';
import { getRequisitionApprovedDashboard } from '../../../app/services/requisition-appoved/get-requisition-appoved.api';
import { getLastSixMonth } from '../../../common/functions/getLastSixMonth';
import { Box, Card, CardContent, CardHeader } from '@material-ui/core';
import { checkInfoByMonthAndStatus } from '../checkInfoByMonthAndStatus';
import { Skeleton } from '@material-ui/lab';
import { optionsInt } from '../../../common/functions/getOptionsCharts';
import { getRandomColor } from '../../../common/functions/generateRandomColor';
import { useState } from 'react';

const useStyles = makeStyles((theme) => ({
    card: {
        width: '100%',
        maxWidth: 700,
        maxHeight: 500,
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
    },
    itemContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
    },
}));

export const DashboardRequisition = () => {
    const classes = useStyles()
  
    const sixMonthsBefore = getLastSixMonth()
    const [colors] = useState(Array.from({ length: 12 }).map(() => getRandomColor()));

    const { data, isFetching } = useQuery(['dashboardRequistion'], async () => {
        // obtem as requisicao rascunho
        const token = localStorage.getItem('token')
        if(token){
            const res = await getRequisitionApprovedDashboard(token, {
                date: sixMonthsBefore.date,
            })
            if(res){
                return res
            }
        }
        return []
      }, {
        refetchOnWindowFocus: false,
    })

    const data2 = {
        labels: sixMonthsBefore.sixMonthsBefore,
        datasets: [
            {
                label: 'Aprovados',
                data: checkInfoByMonthAndStatus('APROVADO', data, sixMonthsBefore),
                backgroundColor: colors[0],
            },
            {
                label: 'Reprovados',
                data: checkInfoByMonthAndStatus('REPROVADO', data, sixMonthsBefore),
                backgroundColor: colors[1],
            },
        ],
    };
  
    return (
        <Card className={classes.card}>
            <CardHeader
                title="Número de requisições"
            />
            <CardContent className={classes.content}>
                <Box className={classes.itemContainer}>
                    {
                        isFetching ? (
                            <Skeleton animation="wave" height="100%" variant='rect' width="100%" style={{ marginBottom: 6 }} />
                            ) : (
                                <VerticalBarChart
                                    options={optionsInt}
                                    data={data2}
                                />
                            )
                    }
                </Box>
            </CardContent>
        </Card>
    );
};
