import { Button, Drawer, FormControl, FormControlLabel, FormLabel, Grid, IconButton, InputLabel, NativeSelect, Radio, RadioGroup, TextField, Typography, makeStyles } from "@material-ui/core";
import { handleReturnValue } from '../../../common/functions/returnValue';
import SearchIcon from '@material-ui/icons/Search';
import { customtheme } from "../../../app/theme/theme";

const useStyles = makeStyles((theme) => ({
    drawer: {
        width: 400,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: theme.spacing(2),
        borderBottom: `1px solid ${theme.palette.divider}`,
        background: customtheme.gray[200],
    },
    drawerContent: {
        overflowY: 'auto',
        padding: theme.spacing(2), 
        height: '100%',
    },
    drawerFooter: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(2),
        borderTop: `1px solid ${theme.palette.divider}`,
        background: customtheme.gray[200],
    },
    textField: {
        minWidth: 140,
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        '& .MuiInputBase-input': {
          color: customtheme.black,
        },
        '& .MuiInput-underline:hover:before': {
          borderBottomColor:customtheme.blue[500],
        },
    },
    searchIcon: {
        marginRight: theme.spacing(1),
        color:customtheme.blue[500],
    },
    loadingIcon: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        marginTop: theme.spacing(2),
    },
    backgroundColor: {
      background: customtheme.blue[500],
      color: customtheme.white,
      '&:hover': {
        background: customtheme.blue[50],
        color: customtheme.blue[500],
      },
    },
    backBackgroundColor: {
        background: customtheme.gray[200],
        color: customtheme.blue[500],
        '&:hover': {
        background: customtheme.blue[50],
        color: customtheme.blue[500],
        },
    },
    selectFieldForm: {
      width: '100%',
      // minWidth: 160,
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    selectField: {
      '& .MuiNativeSelect-select': {
        marginTop: theme.spacing(1),
      },
    },
}))

interface DialogSearchData {
    id: string;
    title: string;
    key: string;
    keySub: string;
    isPrevSearch?: boolean;
    prevSearch?: any;
    prevDataSearch: any;
}

interface MaintenancePointDrawerProps {
    handleCloseDrawer: () => void;
    handleOpenDialogSearch: (data: DialogSearchData) => void;
    handleCheckFields: () => void;
    handleCloseSnackbar: ( event?: React.SyntheticEvent, reason?: string ) => void;
    isDrawerOpen: { open: boolean, btnTitle: string, btnType: string };
    fields: any[];
    setFields: ( array: any[] ) => void;
    checkPrevCompanyValue: { exists: boolean, value: any};
    checkPrevMaintenanceValue: { exists: boolean, value: any};
    checkPrevIntershipValue: { exists: boolean, value: any};
}

const MaintenancePointDrawer = (props: MaintenancePointDrawerProps) => {
    const classes = useStyles()
    const { 
        isDrawerOpen, fields, checkPrevCompanyValue, 
        checkPrevMaintenanceValue, checkPrevIntershipValue,
        setFields, handleCheckFields, handleCloseDrawer, handleOpenDialogSearch
    } = props;

    return (
        <>
            <Drawer
                anchor="right"
                open={isDrawerOpen.open}
                onClose={handleCloseDrawer}
                classes={{ paper: classes.drawer }}
            >
                <div className={classes.drawerHeader}>
                <Typography variant="h6">Adicionar Item</Typography>
                </div>
                <div className={classes.drawerContent}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            label="Empresa"
                            onClick={() => handleOpenDialogSearch({
                            id: 'dados',
                            title: 'Empresa',
                            keySub: 'nomemp',
                            key: 'codemp',
                            prevDataSearch: {},
                            })}
                            value={
                            handleReturnValue('dados', fields)                 
                            }
                            fullWidth
                            className={classes.textField}
                            InputProps={{
                            endAdornment: (
                                <IconButton
                                className={classes.searchIcon}
                                onClick={() => handleOpenDialogSearch({
                                    id: 'dados',
                                    title: 'Empresa',
                                    keySub: 'nomemp',
                                    key: 'codemp',
                                    prevDataSearch: {},
                                    })}>
                                <SearchIcon />
                                </IconButton>
                            ),
                            }}
                        />
                        <TextField
                            label="Equipamento"
                            onClick={
                            () => checkPrevCompanyValue.exists ? 
                            handleOpenDialogSearch({
                                id: 'equip',
                                title: 'Equipamentos',
                                keySub: 'deseqp',
                                key: 'codeqp',
                                prevDataSearch: {
                                codemp: checkPrevCompanyValue.value,
                                }
                            })
                            : () => {}
                            }
                            value={
                            checkPrevCompanyValue.exists ?
                                handleReturnValue('equip', fields)
                            : 'Selecione uma empresa primeiro'
                            }
                            fullWidth
                            className={classes.textField}
                            disabled={
                            checkPrevCompanyValue.exists ? false : true
                            }
                            InputProps={{
                            endAdornment: (
                                <IconButton
                                className={classes.searchIcon}
                                onClick={() => checkPrevCompanyValue.exists ? 
                                    handleOpenDialogSearch({
                                    id: 'produto',
                                    title: 'Produtos',
                                    keySub: 'despro',
                                    key: 'codpro',
                                    prevDataSearch: {
                                        codemp: checkPrevCompanyValue.value,
                                    }
                                    }) :
                                    () => {}
                                }
                                disabled={
                                    checkPrevCompanyValue.exists ? false : true
                                }
                                >
                                <SearchIcon />
                                </IconButton>
                            ),
                            }}
                        />
                        <FormControl className={classes.selectFieldForm}>
                            <InputLabel shrink htmlFor="age-native-label-placeholder">
                                Turno de Trabalho
                            </InputLabel>
                            <NativeSelect
                            className={classes.selectField}
                            value={fields.filter((field) => field.id === 'turtrb')[0].value?.turtrb || ''}
                            onChange={
                                (event) => {
                                const update = [...fields]
                                update[update.findIndex((up) => up.id === 'turtrb')].value = {
                                    turtrb: event.currentTarget.value
                                }
                                setFields(update)
                                }
                            }
                            inputProps={{
                                name: 'age',
                                id: 'age-native-label-placeholder',
                            }}
                            >
                            <option value={undefined}>Nenhum item selecionado</option>
                            <option value={'T1'}>T1 - 1° Turno</option>
                            <option value={'T2'}>T2 - 2° Turno</option>
                            <option value={'T3'}>T3 - 3° Turno</option>
                            <option value={'T4'}>T4 - 4° Turno</option>
                            <option value={'T8'}>T8 - Misto</option>
                            <option value={'T9'}>T9 - Geral</option>
                            </NativeSelect>
                        </FormControl>
                        <TextField
                            label="Manutenção"
                            onClick={checkPrevCompanyValue.exists ? () => 
                            handleOpenDialogSearch({
                                id: 'manutencao', 
                                title: 'Manutenção', 
                                keySub: 'desmnt',
                                key: 'nummnt',
                                prevDataSearch: {
                                codemp: checkPrevCompanyValue.value,
                                },
                            }) :
                            () => {}
                            }
                            value={
                            checkPrevCompanyValue.exists ?
                                handleReturnValue('manutencao', fields)
                            : 'Selecione uma empresa primeiro'
                            }
                            fullWidth
                            className={classes.textField}
                            disabled={
                            checkPrevCompanyValue.exists ? false : true
                            }
                            InputProps={{
                            endAdornment: (
                                <IconButton
                                className={classes.searchIcon} 
                                onClick={checkPrevCompanyValue.exists ? () => 
                                    handleOpenDialogSearch({
                                    id: 'manutencao', 
                                    title: 'Manutenção', 
                                    keySub: 'desmnt',
                                    key: 'nummnt',
                                    prevDataSearch: {
                                        codemp: checkPrevCompanyValue.value,
                                    },
                                    }) :
                                    () => {}
                                }
                                disabled={
                                    checkPrevCompanyValue.exists ? false : true
                                }
                                >
                                <SearchIcon />
                                </IconButton>
                            ),
                            }}
                        />
                        <TextField
                            label="Estágio"
                            onClick={checkPrevCompanyValue.exists ? 
                            checkPrevMaintenanceValue.exists ? () => 
                            handleOpenDialogSearch({
                                id: 'estagio',
                                title: 'Estágio',
                                keySub: 'desetg',
                                key: 'codetg',
                                prevDataSearch: {
                                codemp: checkPrevCompanyValue.value,
                                nummnt: checkPrevMaintenanceValue.value,
                                codetg: checkPrevIntershipValue.value,
                                },
                            }) : () => {}
                            : () => {}
                            }
                            value={
                            checkPrevCompanyValue.exists ?
                                checkPrevMaintenanceValue.exists ?
                                handleReturnValue('estagio', fields)
                                : 'Selecione uma manutenção primeiro'
                            : 'Selecione uma empresa primeiro'
                            }
                            fullWidth
                            className={classes.textField}
                            disabled={
                            checkPrevCompanyValue.exists ? 
                                checkPrevMaintenanceValue.exists ?
                                false
                                : true 
                            : true
                            }
                            InputProps={{
                            endAdornment: (
                                <IconButton 
                                className={classes.searchIcon} 
                                onClick={checkPrevCompanyValue.exists ? 
                                    checkPrevMaintenanceValue.exists ? () => 
                                    handleOpenDialogSearch({
                                        id: 'estagio',
                                        title: 'Estágio',
                                        keySub: 'desetg',
                                        key: 'codetg',
                                        prevDataSearch: {
                                        codemp: checkPrevCompanyValue.value,
                                        nummnt: checkPrevMaintenanceValue.value,
                                        },
                                    }) : () => {}
                                    : () => {}
                                }
                                disabled={
                                    checkPrevCompanyValue.exists ? 
                                    checkPrevMaintenanceValue.exists ?
                                        false
                                        : true 
                                    : true
                                }
                                >
                                <SearchIcon />
                                </IconButton>
                            ),
                            }}
                        />
                        <TextField
                            label="Sequência"
                            onClick={
                            checkPrevCompanyValue.exists ?
                                checkPrevMaintenanceValue.exists ? 
                                checkPrevIntershipValue.exists ? () => 
                                    handleOpenDialogSearch({
                                    id: 'sequencia',
                                    title: 'Sequência',
                                    keySub: 'desrot',
                                    key: 'seqrot',
                                    prevDataSearch: {
                                        codemp: checkPrevCompanyValue.value,
                                        nummnt: checkPrevMaintenanceValue.value,
                                        codetg: checkPrevIntershipValue.value,
                                    },
                                    }) 
                                : () => {}
                                : () => {}
                            : () => {}
                            }
                            value={
                            checkPrevCompanyValue.exists ?
                                checkPrevMaintenanceValue.exists ? 
                                checkPrevIntershipValue.exists ? 
                                    handleReturnValue('sequencia', fields)
                                : 'Selecione um estágio primeiro'
                                : 'Selecione uma manutenção primeiro'
                            : 'Selecione uma empresa primeiro'
                            }
                            fullWidth
                            className={classes.textField}
                            disabled={
                            checkPrevCompanyValue.exists ?
                                checkPrevMaintenanceValue.exists ? 
                                checkPrevIntershipValue.exists ? 
                                false
                                : true
                                : true
                            : true
                            }
                            InputProps={{
                            endAdornment: (
                                <IconButton 
                                className={classes.searchIcon} 
                                onClick={
                                    checkPrevCompanyValue.exists ? 
                                    checkPrevMaintenanceValue.exists ? 
                                        checkPrevIntershipValue.exists ? () => 
                                        handleOpenDialogSearch({
                                            id: 'sequencia',
                                            title: 'Sequência',
                                            keySub: 'desrot',
                                            key: 'seqrot',
                                            prevDataSearch: {
                                            codemp: checkPrevCompanyValue.value,
                                            nummnt: checkPrevMaintenanceValue.value,
                                            },
                                        }) 
                                        : () => {}
                                    :  () => {}
                                    : () => {}
                                }
                                disabled={
                                    checkPrevCompanyValue.exists ?
                                    checkPrevMaintenanceValue.exists ? 
                                        checkPrevIntershipValue.exists ? 
                                        false
                                        : true
                                    : true
                                    : true
                                }
                                >
                                <SearchIcon />
                                </IconButton>
                            ),
                            }}
                        />
                        <TextField
                            label="Operador"
                            onClick={checkPrevCompanyValue.exists ? () => 
                            handleOpenDialogSearch({
                                id: 'operador',
                                title: 'Operador',
                                keySub: 'nomope',
                                key: 'numcad',
                                prevDataSearch: {
                                codemp: checkPrevCompanyValue.value,
                                },
                            }) :
                            () => {}
                            }
                            fullWidth
                            value={
                            checkPrevCompanyValue.exists ?
                                handleReturnValue('operador', fields)
                            : 'Selecione uma empresa primeiro'
                            }
                            className={classes.textField}
                            disabled={
                            checkPrevCompanyValue.exists ? false : true
                            }
                            InputProps={{
                            endAdornment: (
                                <IconButton 
                                className={classes.searchIcon} 
                                onClick={checkPrevCompanyValue.exists ? () => 
                                    handleOpenDialogSearch({
                                    id: 'operador',
                                    title: 'Operador',
                                    keySub: 'nomope',
                                    key: 'numcad',
                                    prevDataSearch: {
                                        codemp: checkPrevCompanyValue.value,
                                    },
                                    }) :
                                    () => {}
                                }
                                disabled={
                                    checkPrevCompanyValue.exists ? false : true
                                }
                                >
                                <SearchIcon />
                                </IconButton>
                            ),
                            }}
                        />
                        <TextField
                            id="datapt"
                            label="Data Apontamento"
                            type="datetime-local"
                            value={fields.filter((field) => field.id === 'datapt')[0].value?.datapt}
                            onChange={(event) => {
                            const update = [...fields]
                            update[update.findIndex((up) => up.id === 'datapt')].value = {
                                datapt: event.currentTarget.value
                            }
                            setFields(update)
                            }}
                            className={classes.textField}
                            InputLabelProps={{
                            shrink: true,
                            }}
                        />
                        {/* <TextField
                            id="datafim"
                            label="Data Fim"
                            type="datetime-local"
                            value={fields.filter((field) => field.id === 'datafim')[0].value?.datfim}
                            onChange={() => {}}
                            className={classes.textField}
                            InputLabelProps={{
                            shrink: true,
                            }}
                        /> */}
                        <>
                            <FormLabel component="legend">Finalizar Manutenção</FormLabel>
                            <RadioGroup
                            aria-label="finalizar"
                            name="finalizar"
                            value={fields.filter((field) => field.id === 'fimoop')[0].value?.fimoop || 'Nenhum item selecionado'} 
                            onChange={(event) => {
                                const update = [...fields]
                                update[update.findIndex((up) => up.id === 'fimoop')].value = {
                                fimoop: event.currentTarget.value
                                }
                                setFields(update)
                            }}
                            >
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <FormControlLabel value="S" control={<Radio style={{color: customtheme.blue[500]}} />} label="Sim" />
                                <FormControlLabel value="N" control={<Radio style={{color: customtheme.blue[500]}} />} label="Não" />
                            </div>
                            </RadioGroup>
                        </>
                        <TextField
                            label="Observação"
                            value={fields.filter((field) => field.id === 'obsapt')[0].value?.obsapt || ''}
                            className={classes.textField}
                            onChange={(event) => {
                            const update = [...fields]
                            update[update.findIndex((up) => up.id === 'obsapt')].value = {
                                obsapt: event.target.value
                            }
                            setFields(update)
                            }}
                            fullWidth
                        />
                    </Grid>
                </Grid>
                </div>
                <div className={classes.drawerFooter}>
                <Button
                    onClick={handleCloseDrawer}
                    className={classes.backBackgroundColor}
                    style={{marginRight: 20}}
                >
                    Cancelar
                </Button>
                <Button 
                    onClick={() => {
                    handleCheckFields()
                    }} 
                    className={classes.backgroundColor}
                >
                    {isDrawerOpen.btnTitle}
                </Button>
                </div>
            </Drawer>  
        </>
    )
}

export default MaintenancePointDrawer;