import { Box, Button, CircularProgress, Container, Grid, IconButton, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FormEvent, useState } from 'react';
import Logo from '../../app/assets/new_layout/logo_vertical_fundo_transaparente2.png';
import { useAuth } from '../../common/hooks/AuthContext';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { customtheme } from '../../app/theme/theme';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: customtheme.gray[200],
    minHeight: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  card: {
    width: '400px',
    padding: theme.spacing(4),
    borderRadius: '8px',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
    backgroundColor: customtheme.white,
    borderTopLeftRadius: '0',
    borderTopRightRadius: '8px',
    borderBottomLeftRadius: '0',
    borderBottomRightRadius: '8px',
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
      borderTopLeftRadius: '0',
      borderTopRightRadius: '0',
      borderBottomLeftRadius: '8px',
      borderBottomRightRadius: '8px',
    },
  },
  leftColumn: {
    backgroundColor: customtheme.blue[500],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: customtheme.white,
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '0',
    borderBottomLeftRadius: '8px',
    borderBottomRightRadius: '0',
    [theme.breakpoints.down('sm')]: {
      padding: 16,
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      borderTopLeftRadius: '8px',
      borderTopRightRadius: '8px',
      borderBottomLeftRadius: '0',
      borderBottomRightRadius: '0',
    },
  },
  logo: {
    width: '400px',
    height: '280px',
    objectFit: 'cover',
  },
  rightColumn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  formField: {
    marginBottom: theme.spacing(2),
  },
  errorMessage: {
    color: theme.palette.error.main,
    textAlign: 'center',
    marginBottom: theme.spacing(2),
  },
  loadingIcon: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(2),
  },
}));

export const LoginPage: React.FC = () => {
  const classes = useStyles();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const {login} = useAuth()

  const handleLogin = async () => { 
    setLoading(true);
    login(username, password, (status:boolean, message: string) => {
      setLoading(false);
      if(!status){
        setError(message)
        setLoading(false);
      }
    })
  };
  const handleFormSubmit = (event: FormEvent) => {
    event.preventDefault(); // Evita o comportamento padrão de atualização da página
  
    // Chama a função de login
    handleLogin();
  };

  return (
    <div className={classes.root}>
      <Container>
        <Grid container spacing={0}>
          <Grid item xs={12} md={6} className={classes.leftColumn}>
            <Box>
              <img src={Logo} alt="Logo" className={classes.logo} />
            </Box>
          </Grid>
          <Grid item xs={12} md={6} className={classes.rightColumn}>
            <Box className={classes.card}>
              <Typography variant="h4" align="center" gutterBottom>
                Faça login
              </Typography>
              <form onSubmit={handleFormSubmit}>
                <TextField
                  label="Nome de usuário"
                  variant="outlined"
                  fullWidth
                  className={classes.formField}
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
                <TextField
                  label="Senha"
                  variant="outlined"
                  fullWidth
                  className={classes.formField}
                  type={showPassword ? 'text' : 'password'}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <IconButton onClick={() => setShowPassword(!showPassword)}>
                        {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                      </IconButton>
                    ),
                  }}
                />
                {
                  error && (
                    <Typography variant="body1" className={classes.errorMessage}>
                      {error}
                    </Typography>
                  )
                }
                {
                  loading ? (
                    <div className={classes.loadingIcon}>
                      <CircularProgress style={{color: customtheme.blue[500]}} />
                    </div>
                  ) : (
                    <Button
                      variant="contained"
                      fullWidth
                      style={{
                        background:  customtheme.blue[500],
                        color: customtheme.white,
                      }}
                      onClick={handleLogin}
                      type='submit'
                    >
                      Entrar
                    </Button>
                  )
                }
              </form>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};
