import {
  Box,
  Button,
  Card,
  CardHeader,
  Dialog,
  DialogTitle,
  Avatar,
  DialogProps,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { COLORS } from '../../../enum/colors.enum';
import { customtheme } from '../../../../app/theme/theme';

const useStyles = makeStyles((theme) => ({
  modalContent: {
    maxHeight: 380,
  }
}));

export interface ModalTeamMatesAccessProps {
  values: any[];
}

export default function ModalTeamMatesAccess(props: ModalTeamMatesAccessProps) {
  const [open, setOpen] = useState(false);
  const classes = useStyles()
  const [scroll] = useState<DialogProps['scroll']>('paper');
  const {values } = props;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef<HTMLElement>(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <Box style={{ marginTop: 16 }}>
        <Button variant='contained' style={{ color:customtheme.white, background: customtheme.blue[500],}} onClick={handleClickOpen}>Ver todos</Button>
        <Dialog
          onClose={handleClose}
          aria-labelledby="simple-dialog-title" 
          open={open}  
          maxWidth={'xs'}
          fullWidth
          scroll={scroll}
        >
          <DialogTitle id="simple-dialog-title">
            <Typography variant='h6'>Quantidade de acessos</Typography>
            <Typography variant='subtitle1'>Total: {values.length} usuários / {values.reduce((accumulator, current) => accumulator + current.totalAcesso, 0)} acessos</Typography>
          </DialogTitle>
          <DialogContent
            dividers={scroll === 'paper'} 
            className={classes.modalContent}
          >
            <DialogContentText
              id="scroll-dialog-description"
              ref={descriptionElementRef}
              tabIndex={-1}
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: 16,
              }}
            >
              {values.map((value, index) => (
                <Card key={index} style={{width: '100%' }}>
                  <CardHeader
                    avatar={
                      <Avatar
                        aria-label="recipe"
                        style={{ 
                          backgroundColor: 
                            value.nomrep ? 
                              COLORS[value.nomrep[0].toUpperCase() as keyof typeof COLORS]
                            : 
                              'gray'
                        }}
                      >
                        {value.nomrep ? value.nomrep[0].toUpperCase() : ''}
                      </Avatar>
                    }
                    title={value.nomrep}
                    subheader={`${value.totalAcesso} acessos`}
                  />
                </Card>
              ))}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Voltar
            </Button>
          </DialogActions>
        </Dialog>
    </Box>
  );
}
