import axios from "axios";

export async function getUsersModuleByCompany(companyId: string, token:string, params:any): Promise<any> {
    try {
      const response = await axios.get(process.env.REACT_APP_BASE_URL+`/modulos/empresa/${companyId}/linked`, {
        params,
        headers: {
            Authorization: 'Bearer ' + token
        }
        });
  
      return response.data; // Supondo que os usuarios sejam retornados pelo backend
    } catch (error) {
      throw new Error('Falha na autenticação');
    }
}

export async function getUsersModuleWithoutCompany(companyId: string, token:string, params:any): Promise<any> {
  try {
    const response = await axios.get(process.env.REACT_APP_BASE_URL+`/modulos/empresa/${companyId}/detached`, {
      params,
      headers: {
          Authorization: 'Bearer ' + token
      }
    });

    return response.data; // Supondo que os usuarios sejam retornados pelo backend
  } catch (error) {
    throw new Error('Falha na autenticação');
  }
}

export async function getUsersModuleByCompanyParams(companyId: string, token:string, data:any): Promise<any> {
  try {
    const response = await axios.post(process.env.REACT_APP_BASE_URL+`/modulos/empresa/${companyId}/params`, data,{
        headers: {
            Authorization: 'Bearer ' + token
        }
      });

    return response.data; // Supondo que os usuarios sejam retornados pelo backend
  } catch (error) {
    throw new Error('Falha na autenticação');
  }
}


export async function getUsersModule(usuarioId: string, token:string): Promise<any> {
  try {
    const response = await axios.get(process.env.REACT_APP_BASE_URL+`/modulos/usuario/${usuarioId}`, {
      headers: {
          Authorization: 'Bearer ' + token
      }
      });

    return response.data; // Supondo que os usuarios sejam retornados pelo backend
  } catch (error) {
    throw new Error('Falha na autenticação');
  }
}