export const mapperToMaintenanceCollection = (data: any) => {
    return [
        {
          id: 'dados',
          value: data.codemp ? {
            codemp: data.codemp,
            nomemp: data.nomemp,
          } : null,
          required: true,
        },
        {
          id: 'equip',
          value: data.codeqp ? {
            codeqp: data.codeqp,
            deseqp: data.deseqp,
          } : null,
          required: true,
        }, 
        {
          id: 'coleta',
          value: data.codccm ? {
            codccm: data.codccm,
          } : null,
          required: true,
        },
        {
          id: 'valorcoleta',
          value: {
            vlrcol: data.vlrcol ? data.vlrcol :  '1',
          },
          required: true,
        },
        {
          id: 'datacoleta',
          value: {
            datcol: data.datcol ? `${data.datcol}T${data.horcol}` : new Date().toISOString().slice(0, -8),
          },
          required: true,
        },
    ];
}