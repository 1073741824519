import axios from "axios";

export async function profile(token: string): Promise<any> {
    try {
      const response = await axios.get(process.env.REACT_APP_BASE_URL+'/auth/profile', {
        headers: {
          Authorization: 'Bearer ' + token
        }
      });
  
      const data = response.data; // Supondo que os dados do usuario seja retornado pelo backend
      return data;
    } catch (error:any) {
      if(error.response.status === 401)
        throw new Error('Credencias inválidas');
      if(error.response.status === 403 || error.response.status === 503 )
        throw new Error(error.response.data.message);
      throw new Error('Falha na autenticação');
    }
}