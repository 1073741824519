import { Button, Container, Typography, makeStyles } from "@material-ui/core";
import { customtheme } from "../../../app/theme/theme";

const useStyles = makeStyles((theme) => ({
    topBar: {
        backgroundColor: customtheme.gray[200],
        padding: theme.spacing(2),
        paddingTop: theme.spacing(4),
    },  
    topBarContent: {   
        flex: 1,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    topBarButtons: {
        '& > *': {
          marginLeft: theme.spacing(1),
        },
    },
    backgroundColor: {
      background: customtheme.blue[500],
      color: customtheme.white,
      '&:hover': {
        background: customtheme.blue[50],
        color: customtheme.blue[500],
      },
    },
}))

interface MaintenancePointHeaderProps {
    onClick: () => void;
}

const MaintenancePointHeader = (props: MaintenancePointHeaderProps) => {
    const classes = useStyles()
    const { onClick } = props;

    return (
        <div className={classes.topBar}>
            <Container style={{maxWidth: '100%'}}>
                <div className={classes.topBarContent}>
                    <Typography variant="h4">Manutenções</Typography>
                    <div className={classes.topBarButtons}>
                        <Button variant="contained" className={classes.backgroundColor} onClick={onClick}>
                            Apontar manutenção
                        </Button>
                    </div>
                </div>
            </Container>
        </div>
    )
}

export default MaintenancePointHeader;