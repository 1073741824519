export const handleCheckPrevValue = (id: string, fields: Array<any>, key: string) => {
    const item = fields.filter((field) => field.id === id)[0].value
    if(item && item[key])
        return {
            exists: true,
            value: item[key]
        }
    return {
        exists: false,
        value: '',
    }
}