export const customtheme = {
    primary: '#66f8ed',
    secondary: '#263547',
    white: '#ffffff',
    black: '#000000',
    gray: {
        400: '#dcdcdc',
        200: '#f5f5f5',
        100: '#f8f8f8',
    },
    blue: {
        500: '#263547',// '#1e88e5',
        100: '#43c7bd',// '#87ceeb',
        50: '#66F8ED',//'#add8e6',
    } 
}