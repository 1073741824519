import { Avatar, IconButton, Typography } from "@material-ui/core";
import MenuIcon from '@material-ui/icons/Menu';
import { useAuth } from "../../hooks/AuthContext";
import { useLocation } from "react-router-dom";
import { COLORS } from "../../enum/colors.enum";
import { Skeleton } from "@material-ui/lab";

interface HeaderProps {
    isMobile: boolean;
    isLoading: boolean;
    onClick: () => void;
}

export const Header: React.FC<HeaderProps> = ({ isMobile, isLoading, onClick }:HeaderProps) => {
  // buscar as informações do usuário
  const { auth } = useAuth()
  const loaction = useLocation()

  const formatUserInfo = () => {
    return {
      name: auth?.nomrep || 'Sem nome',
      role: auth?.perfil || '',
    }
  }
  const userInfo = formatUserInfo()
  
  const useCaseTitle = {
    dashboard: 'Dashboard',
    licencas: 'Licenças',
    licencascheckout: 'Licenças Checkout',
    requisicaoemissao: 'Emissão Requisição Eletrônica',
    requisicaoemissaoconsulta: 'Consulta Emissão Requisição Eletrônica',
    requisicaoaprovacao: 'Aprovação Requisição Eletrônica',
    requisicaoaprovacaoconsulta: 'Consulta Aprovação Requisição Eletrônica',
    ordemcompra: 'Ordem de Compra',
    ordemcompraconsulta: 'Consulta Ordem de Compra',
    manutencaosolicitar: 'Solicitar Manutenção',
    manutencaoapontar: 'Apontar Manutenção',
    manutencaocoleta: 'Apontar Coleta',
    manutencaosolicitarconsulta: 'Consulta Solicitar Manutenção',
    manutencaoapontarconsulta: 'Consulta Apontamento Manutenção',
    manutencaocoletaconsulta: 'Consulta Apontamento Coleta',
    manutencaopainel: 'Painel Manutenção',
    manutencaodashboard: 'Dashboard Manutenção',
  } as any

  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: 16 }}>
      {isMobile && (
        <IconButton onClick={onClick}>
          <MenuIcon />
        </IconButton>
      )}
       <Typography variant="h4" style={{ marginLeft: 16 }}>
          {useCaseTitle[loaction.pathname.split('/').join("")]}
        </Typography>
      {/* Adicione qualquer outro conteúdo do cabeçalho aqui */}
      <div style={{ display: 'flex', alignItems: 'center', marginLeft: 'auto' }}>
        {
          !isMobile && 
            <>
              {
                isLoading ? 
                  <Skeleton animation="wave" height={20} width="160px" style={{ marginRight: 20 }} />
                :
                  <Typography variant="subtitle1" style={{ marginRight: 16 }}>
                    {userInfo.name}
                  </Typography> 
              }
            </>
        }
        {
          isLoading ? 
            <Skeleton animation="wave" variant="circle" width={40} height={40} />
          :
            <Avatar 
              alt={userInfo.name}
              style={{ 
                backgroundColor: 
                  userInfo.name ? 
                    COLORS[userInfo.name[0].toUpperCase() as keyof typeof COLORS]
                  : 
                    'gray',
              //   textAlign: 'center', 
              //   display: 'flex',
              //   justifyContent: 'center',
              //   alignItems: 'center',
              }}
            >
              {userInfo.name[0].toLocaleUpperCase()}
            </Avatar>
        }
      </div>
 </div>
  );
};