import React, { ReactNode, createContext, useContext, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { authenticate } from '../../../app/services/auth/auth.api';
import { useQuery } from '@tanstack/react-query';
import { profile } from '../../../app/services/auth/profile.api';
import { CircularProgress, makeStyles } from '@material-ui/core';
import { getUsersModule } from '../../../app/services/modules/get-users-module.api';
import { customtheme } from '../../../app/theme/theme';

interface User {
  EmpresaFilial?: any;
  atualizado_em: Date;
  cgccpf: string
  codrep: number;
  criado_em: Date;
  empresa_filial_id?: any;
  id: string;
  intnet: string;
  nomrep: string;
  perfil: string;
  status: string;
  termo_uso: false;
  username: string;
  modulos: Array<string>;
}

interface AuthContextData {
  isAuthenticated: boolean;
  isFetching: boolean;
  auth: User | null;
  login: (username: string, password: string, callback: (status:boolean, message: string) => void) => void;
  logout: () => void;
}

interface AuthProviderProps {
    children: ReactNode;
}

const AuthContext = createContext<AuthContextData | undefined>(undefined);

const useAuth = (): AuthContextData => {
  const authContext = useContext(AuthContext);

  if (!authContext) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return authContext;
};

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const classes = useStyles();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [auth, setUser] = useState<User | null>(null);
  const navigate = useNavigate();
  const location = useLocation()

  const { isFetching } = useQuery({ queryKey: ['authentication'], queryFn: async ()  => {
    const token = localStorage.getItem('token');
    if(token){
      try {
        const data = await profile(token)
        if (data && !isAuthenticated) {
          const modules = await getUsersModule(data.id, token)
          setIsAuthenticated(true);
          setUser({...data, modulos: modules} as User)
          if(location.pathname !== '/')
            navigate(location.pathname)
          else
            navigate('/dashboard')
          return data
        } 
        if(!data) {
          localStorage.removeItem('token')
        }
      } catch (error) {
        localStorage.removeItem('token')
      }
     
    }
    return []
  },
    refetchOnWindowFocus:false
  })

  const login = async (username: string, password: string, callback: (status:boolean, message: string) => void) => {
    try {
      const token = await authenticate(username, password)
      // Se a autenticação for bem-sucedida, atualize o estado de autenticação e redirecione para a página do painel
      if (token) {
        localStorage.setItem('token', token);  // Armazena o token
        try {
          const data = await profile(token)
          setIsAuthenticated(true);
          if(data) {
            const modules = await getUsersModule(data.id, token)
            setUser({...data, modulos: modules} as User)
            navigate('/dashboard');
            callback(true, '');
          }
        } catch (error: any) {
          callback(false, error.message as string);
        } 
      }
    } catch (error:any) {
      callback(false, error.message as string);
    }
  };

  const logout = () => {
    // Realize a lógica de logout, como limpar o token de autenticação
    setIsAuthenticated(false);
    setUser(null)
    localStorage.removeItem('token')
    navigate('/login', {replace:true});
  };

  const authContextValue: AuthContextData = {
    isAuthenticated,
    isFetching,
    auth,
    login,
    logout,
  };

  return <AuthContext.Provider value={authContextValue}>
      {
        isFetching ?
          <div className={classes.root}>
            <CircularProgress style={{color: customtheme.blue[500]}} />
          </div>
        :
          children
      }
  </AuthContext.Provider>;
};

export { AuthProvider, useAuth };