import { makeStyles } from '@material-ui/core/styles';
import { VerticalBarChart } from '../../../common/components/VerticalBarChart';
import { useQuery } from '@tanstack/react-query';
import { Box, Card, CardContent, CardHeader, FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { getErp } from '../../../app/services/erp/get-erp.api';
import { useState } from 'react';
import { getRandomColor } from '../../../common/functions/generateRandomColor';
import { allMonths } from '../../../common/functions/getLastSixMonth';
import { options, optionsHorizontalInt, optionsInt } from '../../../common/functions/getOptionsCharts';
import { HorizontalBarChart } from '../../../common/components/HorizontalBarChart';

const useStyles = makeStyles((theme) => ({
    cardRoot: {
        // width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    card: {
        width: '100%',
        maxWidth: 600,
        maxHeight: 500,
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        gap: 40,
        flexWrap: 'wrap'
    },
    itemContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
    },
}));

export const DashboardMaintenance = () => {
    const classes = useStyles()
    const [firstDash, setFirstDash] = useState<any>({})
    const [secondDash, setSecondDash] = useState<Array<any>>()
    const [thirdDash, setThirdDash] = useState<Array<any>>()
    const [fourtDash, setFourtDash] = useState<Array<any>>()
    const [years, setYears] = useState<Array<string>>([])
    const [actualYear, setActualYear] = useState<string>('')
    const [open, setOpen] = useState(false);
    const [colors] = useState(Array.from({ length: 12 }).map(() => getRandomColor()));

    const { isFetching } = useQuery(['dashboardMaintenance'], async () => {
        // obtem as requisicao rascunho
        const token = localStorage.getItem('token')
        if(token){
            const res = await getErp({route: 'dashMaintenance', idSystem: 'PORTAL', page: 1, numitem: 1000}, token)
            if(res){
                setSecondDash(JSON.parse(res[0]['dash2'])['retorno']);
                setThirdDash(JSON.parse(res[0]['dash3'])['retorno']);
                setFourtDash(JSON.parse(res[0]['dash4'])['retorno']);

                const dataDash1 = JSON.parse(res[0]['dash1'])['retorno'] as Array<any>
                const dataDash1Agrouped = dataDash1.reduce((acumulador, item) => {
                    const { Ano } = item;
                    if (!acumulador[Ano]) {
                      acumulador[Ano] = [];
                    }
                    acumulador[Ano].push(item);
                    return acumulador;
                  }, {});
                setActualYear(Object.keys(dataDash1Agrouped)[0])
                setYears(Object.keys(dataDash1Agrouped))
                setFirstDash(dataDash1Agrouped);
            }
        }
        return []
      }, {
        refetchOnWindowFocus: false,
    })

    const createYearWithQtd = (type: string) => {
        let array = Array.from({ length: 12 })
        firstDash[actualYear]?.filter((dash:any) => dash['TipMnt'] === type)
            .forEach((dash:any) => {
                array[parseInt(dash['Mes'])-1] = parseFloat(dash['Quantidade'])
            })
        return array
    }

    const dataDash1 = {
        labels: allMonths,
        datasets: [
        {
            label: "C - Corretiva",
            data: createYearWithQtd("C"),
            backgroundColor: colors[0],
        },
        {
            label: "P - Preventiva",
            data: createYearWithQtd("P"),
            backgroundColor: colors[1],
        },
        {
            label: "E - Preditiva",
            data: createYearWithQtd("E"),
            backgroundColor: colors[2],
        },
        ],
    };

    const dataDash2 = {
      labels: ["Equipamentos"],
      datasets: secondDash?.map((dash, index) => ({
        label: dash['Equipamento'],
        data: [ parseFloat(dash["Total_Manutencao"])],
        backgroundColor: colors[index],
      })) || []
    };

    const dataDash3 = {
        labels: ["Situação"],
        datasets: thirdDash?.map((dash, index) => ({
        label: dash['SitEmt'],
        data: [ parseFloat(dash["Total_Manutencao"])],
        backgroundColor: colors[index],
        })) || []
    };

    const dataDash4 = {
        labels: ["Centros de Custos"],
        datasets: fourtDash?.map((dash, index) => ({
        label: dash['CodCcu'],
        data: [ parseFloat(dash["Total_Manutencao"])],
        backgroundColor: colors[index],
        })) || []
    };

    const handleChangeYear = (event: any) => {
        setActualYear(event.target.value as string)
    }

    const handleClose = () => {
      setOpen(false);
    };
  
    const handleOpen = () => {
      setOpen(true);
    };
  
    return (
        <Card className={classes.cardRoot}>
            <CardHeader
                title="Manutenções"
            />
            <CardContent className={classes.content}>
                <Card className={classes.card}>
                    <CardHeader
                        title="Manutenção Finalizadas"
                        action={
                            <FormControl fullWidth size='medium'>
                                <InputLabel id="demo-simple-select-label">Ano</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={actualYear}
                                    label="Ano"
                                    onChange={handleChangeYear}
                                    open={open}
                                    onClose={handleClose}
                                    onOpen={handleOpen}
                                >
                                    {
                                        years.map((year, index) => (<MenuItem key={`year${index}`} value={year}>{year}</MenuItem>))
                                    }
                                </Select>
                                </FormControl>
                        }
                    />
                    <Box className={classes.itemContainer}>
                        {
                            isFetching ? (
                                <Skeleton animation="wave" height="200px" variant='rect' width="100%" style={{ marginBottom: 6 }} />
                                ) : (
                                    <VerticalBarChart
                                        options={optionsInt}
                                        data={dataDash1}
                                    />
                                )
                        }
                    </Box>
                </Card>
                <Card className={classes.card}>
                    <CardHeader
                        title="Ranking Top 10"
                    />
                    <Box className={classes.itemContainer}>
                        {
                            isFetching ? (
                                <Skeleton animation="wave" height="200px" variant='rect' width="100%" style={{ marginBottom: 6 }} />
                                ) : (
                                    <HorizontalBarChart
                                        options={optionsHorizontalInt}
                                        data={dataDash2}
                                    />
                                )
                        }
                    </Box>
                </Card>
                <Card className={classes.card}>
                    <CardHeader
                        title="Manutenção por Status"
                    />
                    <Box className={classes.itemContainer}>
                        {
                            isFetching ? (
                                <Skeleton animation="wave" height="200px" variant='rect' width="100%" style={{ marginBottom: 6 }} />
                                ) : (
                                    <VerticalBarChart
                                        options={options}
                                        data={dataDash3}
                                    />
                                )
                        }
                    </Box>
                </Card>
                <Card className={classes.card}>
                    <CardHeader
                        title="Manutenções Finalizadas por Centros de Custos"
                    />
                    <Box className={classes.itemContainer}>
                        {
                            isFetching ? (
                                <Skeleton animation="wave" height="200px" variant='rect' width="100%" style={{ marginBottom: 6 }} />
                                ) : (
                                    <VerticalBarChart
                                        options={optionsInt}
                                        data={dataDash4}
                                    />
                                )
                        }
                    </Box>
                </Card>
            </CardContent>
        </Card>
    );
};
