import axios from "axios";
import { StatusMessageResponse } from "../../../common/interfaces/status-message-response";

export async function deleteModuleByLicenceAndUser(data: JSON, token: string): Promise<StatusMessageResponse> {
    try {
      const response = await axios.delete(process.env.REACT_APP_BASE_URL+'/modulos/licenca/usuario', 
        {
            data,
            headers: {
                Authorization: 'Bearer ' + token
            }
        }
      );
  
      const res = response.data; // Supondo que a resposta de criação seja retornado pelo backend
      return res;
    } catch (error) {
      throw new Error('Falha na criação de um modulo');
    }
}