export enum COLORS {
    A = 'red',
    B = 'blue',
    C = 'green',
    D = 'orange',
    E = 'purple',
    F = 'teal',
    G = 'pink',
    H = 'indigo',
    I = 'maroon',
    J = 'brown',
    K = 'lime',
    L = 'navy',
    M = 'olive',
    N = 'cyan',
    O = 'violet',
    P = 'magenta',
    Q = 'darkorange',
    R = 'darkblue',
    S = 'darkgreen',
    T = 'darkred',
    U = 'darkslategray',
    V = 'darkturquoise',
    W = 'sienna',
    X = 'peru',
    Y = 'tomato',
    Z = 'darkviolet',
};
