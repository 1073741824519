import {
  Container
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { getProcessRequisitionByUser } from '../../app/services/requisition/get-requisiton-module.api';
import EnhancedTable, { HeadCell } from '../../common/components/EnhancedTable';
import { customtheme } from '../../app/theme/theme';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: customtheme.gray[200],
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  topBar: {
    backgroundColor: customtheme.gray[200],
    padding: theme.spacing(2),
    paddingTop: theme.spacing(4),
  },  
  topBarContent: {   
    flex: 1,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  topBarButtons: {
    '& > *': {
      marginLeft: theme.spacing(1),
    },
  },
  content: {
    // position: 'relative',
    // flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  contentBackground: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: '50%',
    backgroundColor: customtheme.white,
  },
  card: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  tableSection: {
    padding: theme.spacing(4),
    flex: 1,
    display: 'flex',
  },
  tableSectionContainer: {
    padding: 0, 
    flex: 1,
    display: 'flex', 
    flexDirection: 'column',
    maxWidth: '100%',
  },
  appbar: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    background: customtheme.white,
    color: customtheme.black,
    padding: theme.spacing(2),
    margin: 1,
  },  
  scrollableTabs: {
    overflowX: 'auto',
  }, drawer: {
    width: 400,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.divider}`,
    background: customtheme.gray[200],
  },
  drawerContent: {
    overflowY: 'auto',
    padding: theme.spacing(2), 
    height: '100%',
  },
  drawerFooter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(2),
    borderTop: `1px solid ${theme.palette.divider}`,
    background: customtheme.gray[200],
  },
  textField: {
    minWidth: 140,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    '& .MuiInputBase-input': {
      color: customtheme.black,
    },
    '& .MuiInput-underline:hover:before': {
      borderBottomColor: customtheme.blue[500],
    },
  },
  searchIcon: {
    marginRight: theme.spacing(1),
    color: customtheme.blue[500],
  },
}));

const RequisitionConsult = () => {
  const classes = useStyles();

  const windowHeight = window.innerHeight;
  const headerHeight = 500; // Altura do cabeçalho em pixels
  const footerHeight = 48; // Altura do rodapé em pixels
  const itemHeight = 80; // Altura média de cada item em pixels
  const availableHeight = windowHeight - headerHeight - footerHeight;
  const itemsPerPage = Math.floor((availableHeight) / itemHeight)

  const [selectedData, setSelectedData] = useState<any>([]);
  const [orderBy, setOrderBy] = useState<string>('nomrep');
  const [selected, setSelected] = useState<string[]>([]);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(itemsPerPage > 5 ? itemsPerPage : 5);

  const { isFetching, refetch } = useQuery(['requisitionConsult'], async () => {
    // obtem as requisicao rascunho
    const token = localStorage.getItem('token')
    if(token){
      const res = await getProcessRequisitionByUser(token, {
        take: rowsPerPage,
        skip: page * rowsPerPage,
      })
      if(res){
        setSelectedData(res.results)
        setCount(res.total)
      }
    }
    return []
  }, {
    refetchOnWindowFocus: false,
  })

  useEffect(() => {
    refetch();
  }, [page, refetch]);
  
  const headCells: HeadCell[] = [
    { id: 'numeme', numeric: true, disablePadding: false, label: 'Número Emissão' },
    { id: 'codpro', numeric: false, disablePadding: true, label: 'Código Produto' },
    { id: 'despro', numeric: false, disablePadding: true, label: 'Produto' },
    { id: 'codder', numeric: false, disablePadding: true, label: 'Código Derivação' },
    { id: 'desder', numeric: false, disablePadding: true, label: 'Derivação' },
    { id: 'qtdeme', numeric: true, disablePadding: false, label: 'Quantidade' },
    { id: 'dateme', numeric: false, disablePadding: true, label: 'Data Emissão' },
    { id: 'datprv', numeric: false, disablePadding: true, label: 'Data Previsão' },
    { id: 'coddep', numeric: false, disablePadding: true, label: 'Código Depósito' },
    { id: 'desdep', numeric: false, disablePadding: true, label: 'Depósito' },
    { id: 'codccu', numeric: false, disablePadding: true, label: 'Código Centro Custo' },
    { id: 'desccu', numeric: false, disablePadding: true, label: 'Centro de Custo' },
  ];

  const createData = (props:any) => {
    const {id, numeme, codpro, despro, codccu, desccu, codder, desder, coddep, desdep, qtdeme, dateme, datprv} = props
    return { id, numeme, codpro, despro, codder, desder, qtdeme, dateme, datprv, coddep, desdep, codccu, desccu, ...props };
  }

  return (
    <div className={classes.root}>
      <div className={classes.topBar}>
      </div>
      <div className={classes.content}>
      </div>
      <div className={classes.tableSection}>
        <Container className={classes.tableSectionContainer}>
          <EnhancedTable 
            title='Requisições'
            rowsPerPageOptions={[]}
            isClickable={false}
            data={selectedData?.map((data:any) => createData(data))}
            count={count}
            orderBy={orderBy}
            setOrderBy={setOrderBy}
            headCells={headCells}
            isLoading={isFetching}
            selected={selected}
            setSelected={setSelected}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            buttonsSelected={[]}
            buttonsActions={[]}
          />
        </Container>
      </div>
    </div>
  );
};

export default RequisitionConsult;