import {
    BarElement,
    CategoryScale,
    ChartData,
    Chart as ChartJS,
    ChartOptions,
    Legend,
    LinearScale,
    Title,
    Tooltip,
} from 'chart.js';
import { Bar } from "react-chartjs-2";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

interface VerticalBarChartProps {
    options: ChartOptions<any>;
    data: ChartData<any>;
}

export function VerticalBarChart(props: VerticalBarChartProps) {
    const {  options, data } = props;
   
    return (
        <Bar options={options} data={data} />
    )
}
