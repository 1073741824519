import {
  Button,
  Container, Drawer, Grid, IconButton, Tooltip, Typography
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import ErrorIcon from '@material-ui/icons/Error';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { getPurchaseOrder } from '../../app/services/purchase-order/get-purchase-order.api';
import EnhancedTable, { HeadCell } from '../../common/components/EnhancedTable';
import ItemsDrawer from '../../common/components/ItemsDrawer';
import { customtheme } from '../../app/theme/theme';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: customtheme.gray[200],
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  topBar: {
    backgroundColor: customtheme.gray[200],
    padding: theme.spacing(2),
    paddingTop: theme.spacing(4),
  },  
  topBarContent: {   
    flex: 1,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  topBarButtons: {
    '& > *': {
      marginLeft: theme.spacing(1),
    },
  },
  content: {
    // position: 'relative',
    // flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  contentBackground: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: '50%',
    backgroundColor: customtheme.white,
  },
  card: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  tableSection: {
    padding: theme.spacing(4),
    flex: 1,
    display: 'flex',
  },
  tableSectionContainer: {
    padding: 0, 
    flex: 1,
    display: 'flex', 
    flexDirection: 'column',
    maxWidth: '100%',
  },
  appbar: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    background: customtheme.white,
    color: customtheme.black,
    padding: theme.spacing(2),
    margin: 1,
  },  
  scrollableTabs: {
    overflowX: 'auto',
  }, drawer: {
    width: 400,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.divider}`,
    background: customtheme.gray[200],
  },
  drawerContent: {
    overflowY: 'auto',
    padding: theme.spacing(2), 
    height: '100%',
  },
  drawerFooter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(2),
    borderTop: `1px solid ${theme.palette.divider}`,
    background: customtheme.gray[200],
  },
  textField: {
    minWidth: 140,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    '& .MuiInputBase-input': {
      color: customtheme.black,
    },
    '& .MuiInput-underline:hover:before': {
      borderBottomColor: customtheme.blue[500],
    },
  },
  searchIcon: {
    marginRight: theme.spacing(1),
    color: customtheme.blue[500],
  },
  backBackgroundColor: {
    background: customtheme.gray[200],
    color: customtheme.blue[500],
    '&:hover': {
      background: customtheme.blue[50],
      color: customtheme.blue[500],
    },
  },
}));

interface BodyCell {
  id: string;
  numero: number;
  nomefornecedor: string;
  valoraproximado: number;
  dataemissao: string;
}

const PurchaseOrderConsult = () => {
  const classes = useStyles();

  const windowHeight = window.innerHeight;
  const headerHeight = 500; // Altura do cabeçalho em pixels
  const footerHeight = 48; // Altura do rodapé em pixels
  const itemHeight = 80; // Altura média de cada item em pixels
  const availableHeight = windowHeight - headerHeight - footerHeight;
  const itemsPerPage = Math.floor((availableHeight) / itemHeight)

  const [selectedData, setSelectedData] = useState<any>([]);
  const [orderBy, setOrderBy] = useState<string>('numero');
  const [selected, setSelected] = useState<string[]>([]);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(itemsPerPage > 5 ? itemsPerPage : 5);
  const [fields, setFields] = useState<any[]>([])
  const [isDrawerOpen, setIsDrawerOpen] = useState({open: false, btnTitle: 'Voltar', btnType: 'back'});

  const fieldsItems = ['descricao', 'observacao', 'quantidadesolicitada', 
    'valorliquido', 'valorliquidomoeda', 'valorunitario', 'vlrbru', 'vlrlpr', 
  ];

  const handleCloseDrawer = () => {
    setSelected([])
    setFields([]);
    setIsDrawerOpen({open: false, btnTitle: 'Salvar', btnType: 'save'});
  };

  const { isFetching, refetch } = useQuery(['rurchaseOrderConsult'], async () => {
    // obtem as requisicao rascunho
    const token = localStorage.getItem('token')
    if(token){
      const res = await getPurchaseOrder(token, {
        take: rowsPerPage,
        skip: page * rowsPerPage,
      })
      if(res){
        setSelectedData(res.results.map((result:any) => {
          const { ItemOrdemCompra, ...p } = result
          return  {
            ...p, 
            item: ItemOrdemCompra,
          }
        }));
        setCount(res.total)
      }
    }
    return []
  }, {
    refetchOnWindowFocus: false,
  })

  useEffect(() => {
    refetch();
  }, [page, refetch]);
  
  const headCells: HeadCell[] = [
    { id: 'numero', numeric: true, disablePadding: false, label: 'Número' },
    { id: 'nomefornecedor', numeric: false, disablePadding: true, label: 'Nome Fornecedor' },
    { id: 'valoraproximado', numeric: true, disablePadding: false, label: 'Valor Aproximado' },
    { id: 'dataemissao', numeric: false, disablePadding: true, label: 'Data Emissão' },
    { id: 'status', numeric: false, disablePadding: true, label: 'Status' },
    { id: 'reason', numeric: false, disablePadding: true, label: 'Motivo' },
    { id: 'actions', numeric: true, disablePadding: false, label: 'Ações' },
  ];

  const createData = (props:any): BodyCell => {
    const { id, numero, nomefornecedor, valoraproximado, dataemissao, status, reason } = props
    return { id, numero, nomefornecedor, valoraproximado, dataemissao, status, reason, ...props };
  }

  const handleInfo = (id:any) => {
    const item = selectedData.filter((data:any) => data.id === id )
    if(item.length > 0){
      setFields(item[0].item);
      setIsDrawerOpen({open: true, btnTitle: 'Info', btnType: 'info'});
    }
  }

  const handleCheckItems = () => {
    const itemSelected = selectedData.filter((data:any) => data.id === selected[0])
    if(itemSelected){
      const items = Object.entries(itemSelected[0]).find(([key, val]:any) => key === 'item' && val.length > 0)
      if(items && items[1] !== null)
        return true
      else
        return false
    }
    return false
  }

  return (
    <div className={classes.root}>
      <div className={classes.topBar}>
      </div>
      <div className={classes.content}>
      </div>
      <div className={classes.tableSection}>
      <Container className={classes.tableSectionContainer}>
          <EnhancedTable 
            title='Ordens'
            rowsPerPageOptions={[]}
            isClickable={true}
            data={selectedData?.map((data:any) => createData(data) )}
            count={count}
            orderBy={orderBy}
            setOrderBy={setOrderBy}
            headCells={headCells}
            isLoading={isFetching}
            selected={selected}
            setSelected={setSelected}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            buttonsSelected={[ 
              (selected.length === 1 && handleCheckItems()) && 
                <Tooltip
                  title="Info"
                  onClick={() => handleInfo(`${selected[0]}`)}
                  key='info'
                >
                <IconButton aria-label="info">
                  <ErrorIcon style={{color: customtheme.white}}/>
                </IconButton>
              </Tooltip>,
            ]}
            buttonsActions={['info']}
            handleInfo={handleInfo}
          />
        </Container>
      </div>
      <Drawer
        anchor="right"
        open={isDrawerOpen.open}
        onClose={handleCloseDrawer}
        classes={{ paper: classes.drawer }}
      >
        <div className={classes.drawerHeader}>
          <Typography variant="h6">Itens</Typography>
        </div>
        <div className={classes.drawerContent}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {
                <ItemsDrawer
                  fields={fields} 
                  fieldsItems={fieldsItems}
                  keyTitle='descricao'
                  keySubTitle=''
                  labelsFields={
                    [
                      {
                        key: 'observacao',
                        value: 'Observação',
                      },
                      {
                        key: 'quantidadesolicitada',
                        value: 'Quantidade',
                      },
                      {
                        key: 'valorliquido',
                        value: 'Valor Liquido',
                      },
                      {
                        key: 'valorliquidomoeda',
                        value: 'Valor Liquido Moeda',
                      },
                      {
                        key: 'valorunitario',
                        value: 'Valor Unitário',
                      },
                      {
                        key: 'vlrbru',
                        value: 'Valor Bruto',
                      },
                      {
                        key: 'vlrlpr',
                        value: 'Valor Liquido',
                      },
                    ]
                  }
                />
              }
            </Grid>
          </Grid>
        </div>
        <div className={classes.drawerFooter}>
          <Button
            onClick={handleCloseDrawer}
            className={classes.backBackgroundColor}
          >
            Voltar
          </Button>
        </div>
      </Drawer>
    </div>
  );
};

export default PurchaseOrderConsult;