import { Card, CardHeader, Checkbox, Divider, List, ListItem, ListItemIcon, ListItemText, makeStyles } from "@material-ui/core";
import { DataProps } from "..";
import { Skeleton } from "@material-ui/lab";
import { customtheme } from "../../../../app/theme/theme";

const useStyles = makeStyles((theme) => ({
    card: {
        padding: theme.spacing(1, 2),
        minWidth: 280,
        [theme.breakpoints.down('xs')]: {
            minWidth: 180,
        },
    },
    cardHeader: {
        padding: theme.spacing(1, 2),
    },
    list: {
        // width: 200,
        height: 230,
        backgroundColor: theme.palette.background.paper,
        overflow: 'auto',
    },
    loadingIcon: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      marginTop: theme.spacing(2),
    },
    buttonSpacing: {
      marginRight: theme.spacing(2), // Define a margem direita entre os botões
    },
}))

interface CustomListProps {
    title: React.ReactNode,
    items: DataProps[],
    checked: DataProps[],
    setChecked: (array: DataProps[]) => void,
    isLoading: boolean,
    intersection: (checked: DataProps[], items: DataProps[]) => DataProps[]
}

export const CustomList: React.FC<CustomListProps> = (props) => {
    const classes = useStyles();
    const { 
        title, items, checked,  isLoading,
        setChecked, intersection,
    } = props

    const handleToggle = (value: DataProps) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
        newChecked.push(value);
        } else {
        newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const handleToggleAll = (items: any[]) => () => {
        if (numberOfChecked(items) === items.length) {
        setChecked(not(checked, items));
        } else {
        setChecked(union(checked, items));
        }
    };

    function not(a: any[], b: any[]) {
        return a.filter((value) => b.indexOf(value) === -1);
    }
    
    
    function union(a: any[], b: any[]) {
        return [...a, ...not(b, a)];
    }

    const numberOfChecked = (items: any[]) => intersection(checked, items).length;

    
    return (
        <Card
            className={classes.card}
        >
            <CardHeader
                className={classes.cardHeader}
                avatar={
                    <Checkbox
                        style={{color: customtheme.blue[500]}}
                        onClick={handleToggleAll(items)}
                        checked={numberOfChecked(items) === items.length && items.length !== 0}
                        indeterminate={numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0}
                        disabled={items.length === 0}
                        inputProps={{ 'aria-label': 'all items selected' }}
                    />
                }
                title={title}
                subheader={`${numberOfChecked(items)}/${items.length} selecionado`}
            />
            <Divider />
            <List className={classes.list} dense component="div" role="list">
                { 
                    isLoading ? 
                        <div className={classes.loadingIcon}>
                            {                   
                                Array.from({length: 3}).map((_, index) => (
                                    <Skeleton key={index} animation="wave" height={30} width="100%" style={{ marginBottom: 8 }} /> 
                                ))
                            }
                        </div>
                    :
                        items.map((value: any) => {
                            const labelId = `transfer-list-all-item-${value.id}-label`;
            
                            return (
                            <ListItem key={value.id} role="listitem" button onClick={handleToggle(value)}>
                                <ListItemIcon>
                                <Checkbox
                                    style={{color: customtheme.blue[500]}}
                                    checked={checked.indexOf(value) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{ 'aria-labelledby': labelId }}
                                />
                                </ListItemIcon>
                                <ListItemText id={labelId} primary={`${value.name}`} />
                            </ListItem>
                            );
                        })
                }
                <ListItem />
            </List>
         </Card>
    )
}